import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Select, Step, StepLabel, Stepper } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from 'clsx';
import FileUpload from '../../FileUpload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const drawerWidth = 240;

const customStyles = makeStyles((theme) => ({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    cardDanger: {
        borderColor: '#f44336'
    },

    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    flexDisplay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 10
    },
    flexDisplaySpaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    flexDisplayLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        paddingBottom: 10
    },
    hide: {
        display: 'none',
    },
    show: {
        display: "block",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    }
}));

const ArticleGlobalUploadForm = ({ }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    // Stepper
    function getSteps() {
        return ['Upload csv', 'Confirm Articles'];
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    //   End Stepper

    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const container = React.useRef(null);

    return (
        <div>
            <div className={classes.root}>
                <div className={classes.flexDisplay} style={{ padding: "8px" }}>
                    <Typography variant="h4" className="padding-10">Batch Upload</Typography>
                </div>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <Divider />
            <div className="padding-10">
                <div className={clsx({ [classes.hide]: activeStep != 0, [classes.show]: activeStep == 0 })}>
                    <Grid container item md={12} spacing={5} className={classes.flexDisplay}>
                        <Grid item md={6}>
                            <Box className={classes.flexDisplaySpaceBetween}>
                                <Box>
                                    <Typography variant="h6">Upload CSV</Typography>
                                    <Typography variant="body1" className="grouping-header">Sample size and format requirement</Typography>
                                </Box>
                                <Box>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            displayEmpty
                                            variant="outlined"
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem disabled><em>Download Template</em></MenuItem>
                                            <MenuItem value="serialised">Serialised Template</MenuItem>
                                            <MenuItem value="nonSerialised">Non-serialised Template</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>

                            <div style={{ marginTop: "16px" }}>
                                <FileUpload defaultFields={{}} templateFile={`import-template-merchantUser.csv`} onUpload={(data) => this.onUpload(data)} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={clsx({ [classes.hide]: activeStep != 1, [classes.show]: activeStep == 1 })}>
                    <Grid container item md={12} spacing={5} className={classes.flexDisplay}>
                        <Grid item md={6}>
                            <Typography variant="h6" className="grouping-header">Please verify the articles detail below before confirming.</Typography>
                            <Box className={classes.flexDisplaySpaceBetween} style={{ marginTop: "16px" }}>
                                <Box>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox color="primary" />}
                                        label="Select All"
                                        labelPlacement="end"
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" color="primary">7 selected</Typography>
                                </Box>
                            </Box>
                            <Divider />
                            <List className={classes.root}>
                                {[0, 1, 2, 3].map((value) => {
                                    const labelId = `checkbox-list-label-${value}`;

                                    const handleView = (value) => {
                                        console.log("value", value);
                                        var element = document.getElementById("block-" + value);
                                        if (element.classList.contains("show")) {
                                            element.classList.remove("show");
                                            element.style.display = "none";
                                        } else {
                                            element.classList.add("show");
                                            element.style.display = "block";
                                        }
                                    }
                                    return (
                                        <div>
                                            <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        color="primary"
                                                        edge="start"
                                                        checked={checked.indexOf(value) !== -1}
                                                        tabIndex={-1}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
                                                <ListItemSecondaryAction onClick={(event) => handleView(value)}>
                                                    <IconButton edge="end" aria-label="comments">
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Paper id={"block-" + value} style={{ padding: "16px", display: "none" }} container={container.current}>
                                                <Grid item md={12} className={classes.flexDisplayLeft}>
                                                    <Grid md={3}>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">SKU</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">Article Type</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">Article Description</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">Storage Size</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">Operating System</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">Network Technology</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">HEX Code</Typography>
                                                    </Grid>
                                                    <Grid md={3}>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">200109</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">Handset</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">iPhone 13 Pro Graphite</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">512 GB</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">iOS</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">5G</Typography>
                                                        <Typography variant="body1" color="primary" className="grouping-header padding-7">#FFFFFF</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </div>
                                    );
                                })}
                            </List>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>All steps completed</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div className={classes.flexDisplay}>
                        <Button
                            variant="contained"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.backButton}
                        >
                            Back
                        </Button>
                        <Button style={{ marginLeft: "32px", background: "#E3D900" }} variant="contained" onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                    </div>
                )}
            </div>
        </div >
    )
}

export default ArticleGlobalUploadForm;