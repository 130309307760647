import React, { useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Grid, Table, TableHead, TableRow, TableCell, Typography, TableBody } from '@material-ui/core';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FileReadyToUploadSection = ({t, validFiles, handleSubmit}) => {
    const onClickHanlder = () => {
        handleSubmit();
    }

    return (

         <Card>
            <CardHeader  style={{backgroundColor: "green"}} title={
                <>
                    <Typography style={{ color: 'black' }}> <i className="fa fa-check-circle"></i>
                        {t('messages.fileReadyForUploadList')}
                        <Button style={{ backgroundColor: '#170F4F', float: 'right', color: 'white' }} size='small' onClick={() => onClickHanlder()}>Submit</Button>

                    </Typography>

                </>}/>

            <CardContent>
                <div id="newImage" style={{textAlign: 'center'}}>
                    {
                        validFiles.map((file, index) => {
                            return (
                                <img key={`valid-${index}`} style={{ maxWidth: '70px', margin: '16px'}} src={`data:image/png;base64,${file.data}`} alt="Logo"></img>
                            )
                        })
                    }
                        
                </div>
            </CardContent>

      
           
        </Card>
    )

}

const InvalidFileSection = ({t, invalidFiles}) => {

    return (

         <Card>
            <CardHeader title={
          <Typography style={{ color: 'black' }}> <i className="fa fa-times-circle"></i>
              {t('messages.fileInvalidNotReadyForUploadList')}
          </Typography>
        } style={{backgroundColor: "red"}}/>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('messages.fileName')}</TableCell>
                            <TableCell>{t('messages.fileWidth')}</TableCell>
                            <TableCell>{t('messages.fileHeight')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            invalidFiles?.map((file, index) => {
                                return (
                                    <TableRow key={`invalidFile-${index}`}>
                                        <TableCell style={{color: !(file.name.startsWith("image-device-brand-") || file.name.startsWith("-logo.png") || file.name.startsWith("-logo.jpg")) ?  'red': ''} }>{file.name}</TableCell>
                                        <TableCell style={{color: file.width != 555 || file.width != 400 ? 'red' : ''}}>{file.width}</TableCell>
                                        <TableCell style={{color: file.width != 193 || file.width != 200 ? 'red' : ''}}>{file.height}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody> 
                </Table>
            </CardContent>
        </Card>
    )
}

const BrandImageUploadForm = ({batchUploadBrandImages}) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [invalidFiles, setInvalidFiles] = useState([]);
    const [latestFileName, setlatestFileName] = useState(null);
    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleSubmit = (event) => {
        batchUploadBrandImages(files);
    }

    const validateImageProperTies = (file, image) => {
        if(!(file.name.startsWith("image-device-brand-") && image.width == 555 && image.height == 193) && !((file.name.endsWith("-logo.png") || file.name.endsWith("-logo.jpg")) && image.width == 400 && image.height == 200)  ) {
            setInvalidFiles(prevInvalidFiles => [
                ...prevInvalidFiles,
                {
                    name: file.name,
                    width: image.width,
                    height: image.height,
                }
            ]);
            return false;  
        }
        return true;
    }

    const handleChange = event => {
        var targetData = event.target;
        setlatestFileName(targetData.files.length > 1 ?  `${targetData.files.length} file chosen` :  targetData.files[0].name)

        Object.keys(targetData.files).map((fileKey, fileIndex) =>{
            let img = new Image();
            img.src = window.URL.createObjectURL(targetData.files[fileKey])
            
            img.onload = () => {
                if (validateImageProperTies(targetData.files[fileKey], img)) {
                    if(targetData.files[fileKey] != null && targetData.files[fileKey].type.match('image.*')){
                        var reader = new FileReader();
                        reader.readAsDataURL(targetData.files[fileKey]);

                        reader.onload = () => {
                            setFiles(prevFiles => [
                                ...prevFiles,
                                {
                                    name: targetData.files[fileKey].name,
                                    type: 'image/png',
                                    data: reader.result?.split(',')[1]
                                }
                            ]);
                        };
                    }
                } 
            }
        })
    }

    return (

        <>
            <Box mb={2} mt={2} style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Box style={{
                    height: "80px",
                    width: "30%",
                    background: "#FFFFFF"
                }}>
                    <Box style={{padding:'20px'}}>
                        <Row>
                            <div style={{
                                        textAlign: "center",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <Button  onClick={handleClick} htmlFor="fileUpload" size="small" variant="outlined" style={{
                                            background: "white",
                                            borderColor: "darkblue"
                                        }} >
                                        {'Choose file'}
                                        </Button>
                                        { latestFileName != null ? 
                                            <label style={{
                                                whiteSpace: 'nowrap',
                                                width: '50%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                paddingLeft: '5%'
                                            }}>{latestFileName}</label> :  <label> {t('messages.noFileChoosen')}</label>
                                        }
                            </div>
                        </Row>
                            <input
                                id="fileUpload"
                                type="file"
                                name="file"
                                onChange={handleChange}
                                ref={hiddenFileInput}
                                multiple 
                                accept=".png"
                                style={{ display: "none" }}
                            />
                    </Box>

                </Box>

            </Box>

            <Grid container spacing={2}>
                <Grid item xs={6} sm={invalidFiles.length > 0 ? 6 : 12}>
                <FileReadyToUploadSection t={t} validFiles={files} handleSubmit={() =>handleSubmit()}></FileReadyToUploadSection>
                </Grid>
                {
                    invalidFiles.length > 0 &&
                    <Grid item xs={6} sm={6}>
                        <InvalidFileSection t={t} invalidFiles={invalidFiles} ></InvalidFileSection>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default BrandImageUploadForm;