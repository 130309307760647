export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const TIME_ZONES = JSON.parse(process.env.REACT_APP_TIME_ZONES);
export const TIME_FORMAT_LONG = process.env.REACT_APP_TIME_FORMAT_LONG;
export const TIME_FORMAT_LONG_WITH_INDICATOR = process.env.REACT_APP_TIME_FORMAT_LONG_WITH_INDICATOR;
export const BOLTTECH_DEFAULT_CDN_PATH = process.env.REACT_APP_BOLTTECH_DEFAULT_CDN_PATH;
export const TIME_FORMAT_SHORT = process.env.REACT_APP_TIME_FORMAT_SHORT;
export const FALLBACK_LANGUAGE = process.env.REACT_APP_FALLBACK_LANGUAGE;
export const SUPPORTED_LANGUAGES = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',');
export const AUTHORITY_ADMIN= 'ADMIN';
export const AUTHORITY_SUPPLY_CHAIN_MANAGER = 'SUPPLY_CHAIN_MANAGER';
export const AUTHORITY_SITE_ADMIN = 'SITE_ADMIN';
export const SEARCH_RESULT_KEYS = [
    'article.manufacturer.name.**localeCode**.keyword',
    'article.attributes.series.value.**localeCode**.keyword',
    'article.attributes.color.value.**localeCode**.keyword',
    'article.attributes.storageSize.value.keyword',
    'article.attributes.networkTechnology.value.keyword',
    'article.sku.keyword'
];
export const USER_COUNTRY_CODES = ['HK', 'TH', 'PH', 'MY', 'SG', 'ID', 'IT', 'AT', 'SE', 'TW', 'KR', 'JP', 'IE'];