import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    USER_FILTER,
    USER_GET_DETAILS,
    USER_GET_LIST,
    USER_CLEAR_FILTER,
    USER_SORT,
    SHOW_SUCCESS, HIDE_CONFIRMATION_DIALOG
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getUserList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/account/?${path}`)
        .then(response => {
            console.log("response", response);
            dispatch({
                type: USER_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const updateFiltersForUserList = (filterKey, filterValue) => {
    return({
        type: USER_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const getUserDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/account/${id}`)
        .then(response => {
            dispatch({
                type: USER_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const updateUser = (user, id) => async dispatch => {
    axios.patch(`${BASE_URL}/current/account/${id}`, user)
        .then(response => { 
            console.log(response)
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.updateSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            console.log(error)
            handleError(error, dispatch);
        })
}

export const createUser = (user) => async dispatch => {
    axios.post(`${BASE_URL}/current/account`, user)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.createSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}

export const batchCreateUser = (users) => async dispatch => {
    axios.post(`${BASE_URL}/current/account/batch`, users)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.fileUploaded',
                    entity: 'user',
                    dialogType: 'info',
                    uploadResponse: response,
                    refresh: false
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}

export const clearUserFilters = () => {
    return ({
        type: USER_CLEAR_FILTER
    })
}

export const sortUsers = (orderBy, sort) => {
    return ({
        type: USER_SORT,
        payload: {orderBy, sort}
    })
}

export const resetUserPassword = (id) => async dispatch => {
    axios.post(`${BASE_URL}/current/account/${id}/resetPassword`)
        .then(() => {
            dispatch({
                type: HIDE_CONFIRMATION_DIALOG
            })
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.passwordReset',
                    entity: 'user',
                    dialogType: 'success',
                    refresh: false
                }
            })
        }).catch(error => {
        handleError(error, dispatch)
    })

}