import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { ExpandMore } from '@material-ui/icons';
import { trimData } from "../utils/utils";
import { Row } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {Checkbox, Typography, Box, RadioGroup, FormControl, FormControlLabel ,FormLabel, Radio} from '@material-ui/core';
import { CloudDownloadOutlined } from "@material-ui/icons";
import { USER_COUNTRY_CODES } from "../constant";

const customStyles = makeStyles({
    fileUpload: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        float: 'right',
        padding: 10
    },
    fileLable: {
        whiteSpace: 'nowrap',
        width: '50%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block'
    },
    menu: {
        minWidth: '100px',
    },
    menuTitle: {
        fontSize: '1rem',
        marginLeft: '5%',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '5%',
        marginRight: '5%',
    },
    menuItem: {
        paddingRight: '40px',
    },
    button: {
        minHeight: '20%',
        maxWidth: '8%',
    },
    radioGroup: {
        '& .MuiFormControlLabel-root': {
            marginTop: '0px',
            marginBottom: '0px',
            marginLeft: '6px'        
        },
    },
    radio: {
        '& .MuiSvgIcon-root': {
            width: '10px',
            height: '10px',
        },
    },

    label: {
        fontSize: '15px',
    },
    uploadBoxWithRadioGroup : {
        marginBlockStart: "1%",
        marginBlockEnd: "2%",
        marginRight: "20px",
    },
    uploadBoxWithoutRadioGroup : {
        margin: "20px",
    }
})

const FilesUpload = ({ defaultFields, templateFile, onUpload, changeHeader, addAdditionAttributes, sliceCsv, titleName, processFileType, isAdmin, throwCSVTemplateError, roleBasedCSVHeader, handleServerSideArticleCSVTemplateDownload, onSubmitGlobalArticleCSV }) => {
    const [file, setFile] = React.useState(undefined);
    const [fileName, setFileName] = React.useState(undefined);
    const [uploadActionType, setUploadActionType] = useState(processFileType == 'articleLocalisation' ? 'create' : 'updateAll');

    const { t } = useTranslation();
    const classes = customStyles();

    const handleChange = event => {
        setFile(event.target.files[0])
        let fileNameText = event.target.files[0]?.name;
        fileNameText = fileNameText?.substring(0, fileNameText?.indexOf('_sku'));
        setFileName(fileNameText+"_sku.csv");
        event.target.value = '';
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedAllChecked, setSelectedAllChecked] = useState(false);


    useEffect(() => {
        const allSelected = selectedItems.length === USER_COUNTRY_CODES.length;
        if (!allSelected) {
            setSelectedAllChecked(false);
        } else {
            setSelectedAllChecked(true);
        }
      }, [selectedItems]);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
        event.currentTarget.value = null;
    };

    const handleCheckboxChange = (value) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(value)) {
              return prevSelectedItems.filter((item) => item !== value);
            } else {
              return [...prevSelectedItems, value];
            }
          });
    };

    const handleUploadActionTypeChange = (event) => {
        setUploadActionType(event.target.value);
    }

    const handleSelectAll = () => {
        if (!selectedAllChecked) {
          setSelectedItems(USER_COUNTRY_CODES.map((item) => item));
        } else {
          setSelectedItems([]);
        }
        setSelectedAllChecked((prevSelectedAllChecked) => !prevSelectedAllChecked);
      };
    

    const handleMenuItemClick = (value) => {
        if (value === 'selectAll') {
            handleSelectAll();
        } else {
            handleCheckboxChange(value);
        }
    };
    
    const handleClose = () => {
        setSelectedItems([]);
        setAnchorEl(null);
        setSelectedAllChecked(false);
    };

    const importCSV = async() => {
        if( processFileType === 'articleLocalisation'){
            onSubmitGlobalArticleCSV(file, uploadActionType);
            setFile(undefined);
        } else {
            Papa.parse(file, {
                complete:  results => {
                    var headers = results.data[0];
                    var filteredHeaders = Object.keys(headers).filter(header => {
                        return header.trim() !== "";
                    });

                    var filteredData = results.data.map(row => {
                        var newRow = {};
                        filteredHeaders.forEach(header => {
                            newRow[header] = row[header];
                        });
                        return newRow;
                    });

                    updateData(filteredData, null)
                },
                header: true,
                skipEmptyLines: true,
                transformHeader: function (header) {
                    if (changeHeader != null) {
                            Object.entries(changeHeader).map(([key, value]) => {
                                if (key === header) {
                                    header = value;
                                }
                            })
                    }
                    return header;
                    }
                });
        }
    };

    const updateData = (result, templateName) => {
        const data = sliceCsv != null ? result.slice(sliceCsv) : result;
        trimData(data);
        var convertedToDimensional = data.map(obj => convertToDimensional(obj));
        if (addAdditionAttributes != null) {
            convertedToDimensional = addAdditionAttributes(convertedToDimensional);
        }
        setFile(undefined)
        document.getElementById('fileUpload').value = '';

        if(processFileType != 'articleLikeFor') {
            onUpload(convertedToDimensional,fileName,templateName, uploadActionType);
        } else {
            onUpload(convertedToDimensional, uploadActionType);
        }
    }

    const onGlobalArticleUploadClick = () => {
        if(isAdmin) {
            if(selectedItems.length > 0) {
                handleClose();
                handleServerSideArticleCSVTemplateDownload(selectedItems);
            } else {
                throwCSVTemplateError(t('errorMessage.template.noTemplateSelectedForDownload'))
            }
        } else {
            handleServerSideArticleCSVTemplateDownload(selectedItems);
        }
    }

    const convertToDimensional = (data) => {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (key.indexOf(".") !== -1) {
                    parseDotNotation(key, data[key], data);
                }
            }
        }
        return data;
    }

    const parseDotNotation = (originalKey, val, obj) => {
        var currentObj = obj,
            keys = originalKey.split("."),
            i, l = Math.max(1, keys.length - 1), key;

        for (i = 0; i < l; ++i) {
            key = keys[i];
            currentObj[key] = currentObj[key] || {};
            currentObj = currentObj[key];
        }

        currentObj[keys[i]] = val;
        delete obj[originalKey];
    }

    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return (
        <Box style={{
            height: "170px",
            width: "35%",
            background: "#FFFFFF"
        }}>
            <Box>
                <Box>
                    <label style={{
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        padding: "10px",
                        lineHeight: "9px",
                        textAlign: "center",
                        color: "#170F4F",
                        marginTop: 10,
                        display: "inline-block"
                    }}>{t(titleName)}</label>
                    {
                        (t(titleName) !== 'Localization' && t(titleName) !== 'Like For Similar' && t(titleName) !== 'Like For Upgrade' && t(titleName) !== 'Accessories Mapping') ? <a href={`${process.env.PUBLIC_URL}/assets/resources/${templateFile}`} target='_blank' rel="noreferrer" download title={t('messages.downloadTemplateFile')} className={classes.fileUpload}>{t('messages.downloadTemplateFile')}<ExpandMore /></a>
                            :
                            isAdmin ?
                            <><a href="#" title={t('messages.downloadTemplateFile')} className={classes.fileUpload} onClick={handleClickListItem}>{t('messages.downloadTemplateFile')}<ExpandMore /></a>
                            <Menu
                                id="lock-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                className={classes.menu}
                            >
                                <Typography variant="h5" className={classes.menuTitle}>
                                   {t('messages.downloadCountryBasedTemplateTitle')}
                                </Typography>
                                <MenuItem
                                        key={'SelectAll'}
                                        onClick={() => handleMenuItemClick("selectAll")}
                                        className={classes.menuItem}
                                    >
                                        <Checkbox
                                            checked={selectedAllChecked}
                                            onChange={() => handleSelectAll()}
                                            onClick={(event) => event.stopPropagation()}
                                        />
                                        {"Select All"}
                                </MenuItem>
                                {USER_COUNTRY_CODES.map((menuItem, index) => (
                                    <MenuItem
                                        key={menuItem}
                                        onClick={() => handleMenuItemClick(menuItem)}
                                        className={classes.menuItem}
                                    >
                                        <Checkbox
                                            checked={selectedItems.includes(menuItem)}
                                            onChange={() => handleCheckboxChange(menuItem)}
                                            onClick={(event) => event.stopPropagation()}
                                        />
                                        {t(`values.countryCode.${menuItem}`)}
                                    </MenuItem>
                                ))}
                                <div className={classes.buttonWrapper}>
                                <Button variant="contained" className={classes.button} size="small" color="secondary" style={{background: "#E3D900"}} onClick={ onGlobalArticleUploadClick }>
                                        <CloudDownloadOutlined  />
                                </Button>

                                </div>
                                
                                
                            </Menu></> :
                            <a href="#" title={t('messages.downloadTemplateFile')} className={classes.fileUpload} onClick={onGlobalArticleUploadClick}>{t('messages.downloadTemplateFile')}<ExpandMore /></a>
                        }
                </Box>
                <Box>
                    {
                        processFileType === 'articleLocalisation' && 
                            <Row style={{
                                paddingLeft:'23%'
                                }}
                            >
                                <div>
                                    <FormControl>
                                        <FormLabel>
                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" className={classes.radioGroup} defaultValue="create" onChange={handleUploadActionTypeChange}>
                                                <FormControlLabel value="create" control={<Radio className={classes.radio} />} label="Localize / Create" classes={{ label: classes.label }} />
                                                <FormControlLabel  value="update" control={<Radio className={classes.radio} />} label="Localize / Update" classes={{ label: classes.label }} />
                                            </RadioGroup>
                                        </FormLabel>
                                    </FormControl>

                                </div>
                            </Row>
                    }
                    {
                        processFileType === 'articleLikeFor' && 
                            <Row style={{
                                paddingLeft:'28%'
                                }}
                            >
                                <div>
                                    <FormControl>
                                        <FormLabel>
                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" className={classes.radioGroup} defaultValue="updateAll" onChange={handleUploadActionTypeChange}>
                                                <FormControlLabel value="updateAll" control={<Radio className={classes.radio} />} label="Update All" classes={{ label: classes.label }} />
                                                <FormControlLabel  value="updateSelected" control={<Radio className={classes.radio} />} label="Update Selected" classes={{ label: classes.label }} />
                                            </RadioGroup>
                                        </FormLabel>
                                    </FormControl>
                                </div>
                            </Row>
                    }
                    <Row>
                        <div style={{
                            textAlign: "center",
                            whiteSpace: "nowrap"
                        }}>
                            <Button htmlFor="fileUpload" size="small" variant="outlined" style={{
                                background: "white",
                                width: "30%",
                                marginLeft: "7% !important",
                                borderColor: "darkblue"
                            }} onClick={handleClick}  className={ processFileType === 'articleLocalisation' || processFileType === 'articleLikeFor' ? classes.uploadBoxWithRadioGroup : classes.uploadBoxWithoutRadioGroup}>
                            {t('messages.chooseFile')}
                            </Button>
                            {
                                file != null ? <label  className={classes.fileLable}>{file.name}</label> : <label> {t('messages.noFileChoosen')}</label>
                            }
                        </div>
                    </Row>

                    <Row style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Button style={{
                            width: "40%"
                        }} size="medium" className="margin-left-10" disabled={!file} onClick={ importCSV } variant="contained" color="primary" seleniumselector="fileSubmit">{t('messages.submit')}</Button>
                    </Row>
                    
                    <input
                        id="fileUpload"
                        type="file"
                        name="file"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        seleniumselector="fileUpload"
                        accept=".csv"
                        style={{ display: "none" }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default FilesUpload;