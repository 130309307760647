import React from 'react';
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";

const ArticlePreviewAccordion = ({ entity, entitySize, onSubmit, open, children }) => {
    const { t } = useTranslation();

    const handleSubmit = () => {
        onSubmit(entity);
    }

    const styles = {
        accordion: {
            maxWidth: '97%'
        },
        circledNumber: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: '#180f4f',
            m: 1,
            border: 2,
            width: '40px',
            height: '40px',
            borderRadius: '50%'
        },
        itemCountBoxDiv: {
            display: "flex",
            justifyContent: "flex-end"
        },
        itemCountBox: {
            marginLeft: 'auto'
        },
        submitButton: {
            background: "#E3D900",
            padding: "10px 50px",
            margin: '30px',
            float: 'right'
        }
        
    };
      
    return (

        <Accordion expanded={open} style={styles.accordion}>
            <AccordionSummary>
                <Box sx={{ ...styles.circledNumber}}>
                    <Typography style={{color: "#ffffff"}}>2</Typography>
                </Box>
                <div>
                    <Typography>
                        {t("messages.articleGlobal.reviewArticle")}
                        <br />
                        {t("messages.articleGlobal.reviewArticlesMapped")}
                    </Typography>
                </div>

                <Box style={{ ...styles.itemCountBox, display: !open ? 'none' : '' }}>
                    <div style={styles.itemCountBoxDiv} >
                        <Typography>
                        {entitySize} item(s)
                        </Typography>                        
                    </div>
                </Box>
             
            </AccordionSummary>
            <AccordionDetails>  
                {children}
            </AccordionDetails>

            <Button size="small" variant="contained" color="secondary" 
                onClick={handleSubmit}
                style={styles.submitButton}>
                {t('messages.submit')}
            </Button>
        </Accordion>
    )
}

export default ArticlePreviewAccordion;