export const formatAccessoryMappingRecord = (data) => {
    data.map(record => {
        record['countryCode'] = record['countryCode'].slice(0, 2);
    })

    return data;
};

export const convertToArticleAccessoryMappingObject = (data) => {
    var skuList = [];
    var articleAccessoryMappingObject = [];

    data.map(record => {
        if (skuList.includes(record.sku) === false) skuList.push(record.sku);
    })

    skuList.map(sku => {
        var accessoryMappings = data.filter( data => data["sku"] === sku );

        accessoryMappings.map(accessoryMapping => {
            var articleAccessoryMapping = articleAccessoryMappingObject.filter( data => data["sku"] === sku )[0];
            var articleAccessory = {};
            var existingArticleAccessoryMapping = false;

            if(articleAccessoryMapping != null){
                existingArticleAccessoryMapping = true;
                articleAccessory = articleAccessoryMapping;
            } else {
                articleAccessory["sku"] = accessoryMapping["sku"];
            }

            if(typeof articleAccessory["accessory"] === 'undefined') {
                articleAccessory["accessory"] = {};
            }

            if(accessoryMapping["carrierCode"]  === "") {
                accessoryMapping["carrierCode"] = "default";
            }
     
            if(typeof articleAccessory["accessory"][accessoryMapping["countryCode"]] === 'undefined') {
                articleAccessory["accessory"][accessoryMapping["countryCode"]] = {};
                articleAccessory["accessory"][accessoryMapping["countryCode"]]["value"] = {};
            }
            articleAccessory["accessory"][accessoryMapping["countryCode"]]["type"] = "HashMapArrayListAttribute";
            articleAccessory["accessory"][accessoryMapping["countryCode"]]["value"][accessoryMapping["carrierCode"]] = [];
            for (let i = 1; i < 5; i++) {
                if(accessoryMapping["accessory"][i.toString()]){
                    articleAccessory["accessory"][accessoryMapping["countryCode"]]["value"][accessoryMapping["carrierCode"]].push(accessoryMapping["accessory"][i.toString()]);
                }
            }
            
            if(existingArticleAccessoryMapping === true) {
                var foundIndex = articleAccessoryMappingObject.findIndex(x => x.sku === sku);
                articleAccessoryMappingObject[foundIndex] = articleAccessory;
            } else {
                articleAccessoryMappingObject.push(articleAccessory);
            }
           
        })
    })

    return articleAccessoryMappingObject;
};