import React, {Component} from "react";
import { connect } from 'react-redux';
import ArticleUploadAccordion from "../../components/Article/ArticleUploadAccordion";
import ArticlePreviewAccordion  from "../../components/Article/ArticlePreviewAccordion";
import ArticleGlobalUploadPreviewTable from "../../components/Article/ArticleGlobalUploadPreviewTable";
import {
    createUser
} from "../../actions/userActions";
import {showArticleUploadAccordion, hideArticleUploadAccordion, batchCreateArticle } from "../../actions/articleAction";
import { ARTICLE_GLOBAL_CSV_OBJECT } from "../../utils/CsvObjectModifier/CsvObjectFormat";
import { trimData, convertToDimensional, validateAcceptedFields, validateRequiredFields } from "../../utils/utils";
import { insertGlobalArticleAttributeType, formatGlobalArticleRecord } from "../../utils/CsvObjectModifier/ArticleGlobal/ArticleGlobalMapper";
import Papa from "papaparse";
import { fileUploadAcceptedFields, fileUploadRequiredFields } from "../../utils/fields";
import { showError } from "../../actions/commonActions";

class ArticleGlobalCreate extends Component {
    componentDidMount() {
        this.props.hideArticleUploadAccordion();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            this.props.history.push('/articles/global');
        }
    }

    processCSVFile = (file) => {
        Papa.parse(file, {
            complete:  results => {
                var headers = results.data[0];
                var filteredHeaders = Object.keys(headers).filter(header => {
                    return header.trim() !== "";
                });

                var filteredData = results.data.map(row => {
                    var newRow = {};
                    filteredHeaders.forEach(header => {
                        newRow[header] = row[header];
                    });
                    return newRow;
                });

                this.updateData(filteredData)
            },
            header: true,
            skipEmptyLines: true,
            transformHeader: function (header) {
                Object.entries(ARTICLE_GLOBAL_CSV_OBJECT).map(([key, value]) => {
                    if (key === header) {
                        header = value;
                    }
                })
                return header;
            }
        });

    }

    updateData = (result) => {
        const data = result.slice(1);
        trimData(data);
        var convertedToDimensional = data.map(obj => convertToDimensional(obj));
        convertedToDimensional = insertGlobalArticleAttributeType(convertedToDimensional);
        this.previewUpload(convertedToDimensional)
    }

    validateEntity = (data) => {
        let validatedRecords = {
            valid: true,
            lineNumberWithErrors: [],
            recordLimitExceeded: false,
            emptyRecord: false
        };
        data.forEach((datum, index) => {
            validatedRecords.valid = validateAcceptedFields(fileUploadAcceptedFields['articleGlobal'], Object.keys(datum)) &&
                validateRequiredFields(fileUploadRequiredFields['articleGlobal'], datum);
            if (validatedRecords.valid == false) {
                validatedRecords.lineNumberWithErrors.push(index + 3)
            }
        });

        if(data.length <= 0 ) {
            validatedRecords.emptyRecord = true;
        }

        if (data.length > 5000) {
            validatedRecords.recordLimitExceeded = true;
        }

        if (validatedRecords.lineNumberWithErrors.length === 0 && validatedRecords.recordLimitExceeded == false && validatedRecords.emptyRecord == false) {
            return true
        } else {
            
            if (validatedRecords.emptyRecord == true) {
                this.props.showError({ response: { data: { translationKey: `0 record uploaded. Please fix and try again.` } } });
                return false;
            } 
            
            if (validatedRecords.recordLimitExceeded == true) {
                this.props.showError({ response: { data: { translationKey: `Exceeded 5000 limit of records with number of ${data.length} uploaded records. Please fix and try again.` } } });
                return false;
            }

            if (validatedRecords.lineNumberWithErrors.length > 0){
                this.props.showError({ response: { data: { translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.` } } });
                return false;
            }
        }
    }

    previewUpload = (data) => {
        if( this.validateEntity(data)) {
            this.props.showArticleUploadAccordion(data)
        }
    }

    onSubmit = (data) => {
        let convertedData = formatGlobalArticleRecord(data);
        this.props.batchCreateArticle(convertedData);
        this.props.hideArticleUploadAccordion();

    }

    renderUploadAccordion = () => {
        const styles = {
            main: {
                paddingTop: 30,
                paddingLeft: 30,
                paddingRight: 30,
            }
        }

        return (
            <div style={styles.main}>
                <ArticleUploadAccordion
                    processCSVFile={(data) => this.processCSVFile(data)}
                    templateFile={`template_global_article_sku.csv`}
                    open={this.props.articles.articleUploadAccordion.uploadAccordion}
                ></ArticleUploadAccordion>
            </div>
        )

    }

    renderPreviewAccordion = () => {
        const styles = {
            main: {
                paddingTop: 30,
                paddingLeft: 30,
                paddingRight: 30,
            }
        }

        return (
            <div style={styles.main}>
                <ArticlePreviewAccordion
                    open={this.props.articles.articleUploadAccordion.previewAccordion}
                    entitySize={this.props.articles.articleUploadAccordion.entity ? this.props.articles.articleUploadAccordion.entity.length : null}
                    entity={this.props.articles.articleUploadAccordion.entity}
                    onSubmit={(data) => this.onSubmit(data)}
                >
                    <ArticleGlobalUploadPreviewTable
                        entity={this.props.articles.articleUploadAccordion.entity}
                    >
                    </ArticleGlobalUploadPreviewTable>
                </ArticlePreviewAccordion>
            </div>
        )

    }

    render() {
        const styles = {
            main: {
                paddingTop: 30,
                paddingLeft: 30,
                paddingRight: 30,
            },
            mainPaper: {
                padding: 0,
            },
            mainHeader: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: 10
            },
            mainWrapper: {
                paddingTop: 0
            },

        }
        
        return(
            <>
                {
                    this.renderUploadAccordion()
                }

                {
                    this.renderPreviewAccordion()
                }
            
            </>
        )
    }

}

const mapStateToProps = state => ({
    articles: state.articles,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    createMerchantUser: createUser,
    showArticleUploadAccordion: showArticleUploadAccordion,
    hideArticleUploadAccordion: hideArticleUploadAccordion,
    batchCreateArticle: batchCreateArticle,
    showError: showError
})(ArticleGlobalCreate);