import React from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import ArticleBasicInfo from "./ArticleBasicInfo";
import ArticleAttributesInfo from "./ArticleAttributesInfo";
import ArticleAccesoryTable from "./ArticleAccesoryTable";
import {
  firstArticleInfoRowMapping,
  secondArticleInfoRowMapping,
  thirdArticleInfoRowMapping,
  imageArticleInfoRowMapping,
} from "./mapping/ArticleDetailsMapper";
import ArticleSuggestedRetailPriceTable from "./ArticleSuggestedRetailPriceTable";
import ArticleSystemConfigurationTable from "./ArticleSystemConfigurationTable";
import ArticleTradeInTable from "./ArticleTradeInTable";
import ArticleLikeForTable from "./ArticleLikeForTable";

const attributesColorCircleStyle = (color) => ({
  backgroundColor: color,
  width: `24px`,
  height: `24px`,
  borderRadius: `50%`,
});

const ArticleDetails = ({
  article,
  articleSuggestedRetailPrice,
  articleSystemConfiguration,
  articleTradeInPrice,
  articleAccesoryMapping,
  articleLikeForMapping,
  countryCode
}) => {
  const { t } = useTranslation();

  if (!article) return null;
  return (
    <div className="custom-bootstrap">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-book"></i>
              <span className="badge badge-primary">{article?.type?.code}</span>
              <strong> {article.description}</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <ArticleBasicInfo
                    mapping={firstArticleInfoRowMapping(article, t)}
                    countryCode={countryCode}
                  />
                </div>

                <div className="col-md-2">
                  <ArticleBasicInfo
                    mapping={secondArticleInfoRowMapping(article, t)}
                    countryCode={countryCode}
                  />
                </div>

                <div className="col-md-3">
                  <ArticleBasicInfo
                    mapping={thirdArticleInfoRowMapping(article, t)}
                    countryCode={countryCode}
                  />
                </div>

                {
                  <ArticleBasicInfo
                    mapping={imageArticleInfoRowMapping(article, t)}
                    countryCode={countryCode}
                  />
                }
              </div>

              <div className="row">
                <ArticleSuggestedRetailPriceTable
                  articleSuggestedRetailPrice={articleSuggestedRetailPrice}
                  userCountryCode={countryCode}
                />

                <ArticleSystemConfigurationTable
                  articleSystemConfiguration={articleSystemConfiguration}
                  userCountryCode={countryCode}
                />

                <ArticleTradeInTable
                  articleTradeInPrice={articleTradeInPrice}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-accent-info">
        <div className="card-header display-flex align-items-center">
          <strong>{t(`keys.article.attributesLabel.keyword`)}</strong>
          {article.attributes?.hexCode?.value != null ? (
            <span
              className="ml-2"
              style={attributesColorCircleStyle(
                article.attributes.hexCode.value
              )}
            ></span>
          ) : (
            <span className="ml-2"></span>
          )}
        </div>

        <div className="card-body">
          <div className="row">
            <ArticleAttributesInfo
              article={article}
              userCountryCode={countryCode}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
            <i className="fa fa-refresh"></i>
              <strong>{t(`keys.articleLikeForLikeSimilar`)}</strong>
        </div>

        <div className="card-body">
          <div className="row">
                  <ArticleLikeForTable
                    articleLikeForMapping={articleLikeForMapping}
                  />
            </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
            <i className="fa fa-plug"></i>
              <strong>{t(`keys.article.accessory.keyword`)}</strong>
        </div>

        <div className="card-body">
              <ArticleAccesoryTable
                articleAccesoryMapping={articleAccesoryMapping}
                userCountryCode={countryCode}
              />
        </div>
      </div>
    </div>
  );
};

export default ArticleDetails;
