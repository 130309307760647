import React, {Component} from "react";
import { connect } from 'react-redux';
import {
    batchCreateUser, clearUserFilters,
    getUserList, sortUsers,
    updateFiltersForUserList
} from '../../actions/userActions';
import DataTable from "../../components/DataTable";
import PageTemplate from "../../components/PageTemplate";
import {
    parseObjectToString,
    validateAcceptedFields,
    validateEmail,
    validatePhoneNumber,
    validateRequiredFields
} from "../../utils/utils";
import {showError} from "../../actions/commonActions";
import {fileUploadAcceptedFields, fileUploadRequiredFields} from "../../utils/fields";
import { USER_COUNTRY_CODES } from "../../constant";

class UserList extends Component {
    componentDidMount() {
        this.props.getUserList('orderBy=createdAt&sort=desc');
        this.props.clearUserFilters();
        this.props.sortUsers('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.users.userFilters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFiltersForUserList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearUserFilters();
        this.props.getUserList('orderBy=createdAt&sort=desc');
    }

    handleSorting(orderBy, sort) {
        this.props.sortUsers(orderBy, sort)
        this.props.getUserList(parseObjectToString(this.props.users.userFilters, {orderBy, sort}))
    }

    renderUsers(users, showContentLoader) {
        const fields = [
            {
                name: 'email',
            },
            {
                name: 'name',
            },
            {
                name: 'locked',
                type: 'translatedString'
            },
            {
                name: 'active',
                type: 'translatedString'
            },
            {
                name: 'countryCode',
                type: 'translatedString'
            },
            {
                name: 'authorities',
                type: 'translatedArray',
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterAuthorityList = ['ADMIN','SUPPLY_CHAIN_MANAGER'];

        const filterKeys = [
            {
                name: 'email',
                type: 'textField'
            },
            {
                name: 'name',
                type: 'textField'
            },
            {
                name: 'locked',
                type: 'checkBoxes',
                options: ['true', 'false']
            },
            {
                name: 'active',
                type: 'checkBoxes',
                options: ['true', 'false']
            },
            {
                name: 'countryCode',
                type: 'checkBoxes',
                options: USER_COUNTRY_CODES
            },
            {
                name: 'authorities',
                type: 'checkBoxes',
                options: filterAuthorityList
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ];

        return(
            <DataTable fields={fields}
                       items={users}
                       entity="users"
                       filterKeys={filterKeys}
                       filterValues={this.props.users.userFilters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getUserList(parseObjectToString(this.props.users.userFilters))}
                       sorting={this.props.users.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
                       enableFilter={true}
                       disableCell={false}
            />
        )
    }

    onUpload = (data) => {
        let validatedRecords = {
            valid: true,
            lineNumberWithErrors: []
        };
        data.forEach((datum, index) => {
            validatedRecords.valid = validateAcceptedFields(fileUploadAcceptedFields['user'], Object.keys(datum)) &&
                validateRequiredFields(fileUploadRequiredFields['user'], datum) &&
                validateEmail(datum.email) &&
                validatePhoneNumber(datum.phoneNumber);
            if (validatedRecords.valid) {
                datum.authorities = datum.authorities.split('|');
            } else {
                validatedRecords.lineNumberWithErrors.push(index + 2)
            }
        });
        if (validatedRecords.lineNumberWithErrors.length === 0) {
            this.props.batchCreateUser(data);
        } else {
            this.props.showError({response: {data: {translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.`}}});
        }
    }

    render() {

        return(
            <PageTemplate title="messages.userList" createPath="usersNew" uploadAction={"upload"}>
                {this.renderUsers(this.props.users.users, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    users: state.users,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getUserList: getUserList,
    updateFiltersForUserList: updateFiltersForUserList,
    clearUserFilters: clearUserFilters,
    batchCreateUser: batchCreateUser,
    sortUsers: sortUsers,
    showError
})(UserList);