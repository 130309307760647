import { parseDate } from "../../utils";
import _ from "lodash";

export const formatSeverityRecord = (data) => {
    data.map(record => {
        record['countryCode'] = record['countryCode'].slice(0, 2);
    })

    return data;
};

export const convertToSeverityObject = (data) => {
    data.map((record) => {
        const offsetRegex = /(\+|\-)\d{4}/;
        const updatedOffset = '+0000';
        const [day, month, year] = record["inboundDate"].split('-').map(Number);

        var inboundDate = parseDate(new Date(year, month - 1, day), null);
       
        record["inboundDate"] = _.replace(inboundDate, offsetRegex, updatedOffset);
    })

    return data;
};