import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    ARTICLE_EXCLUSION_FILTER,
    ARTICLE_EXCLUSION_GET_LIST,
    ARTICLE_EXCLUSION_CLEAR_FILTER,
    ARTICLE_EXCLUSION_SORT,
    ARTICLE_EXCLUSION_GET_OPTIONS,
    SHOW_SUCCESS, HIDE_CONFIRMATION_DIALOG
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getArticleExclusionList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/exclusion/?${path}`)
        .then(response => {
            console.log("response", response);
            dispatch({
                type: ARTICLE_EXCLUSION_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const updateFiltersForArticleExclusionsList = (filterKey, filterValue) => {
    return({
        type: ARTICLE_EXCLUSION_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const getArticleExclusionOptions = () => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/tagsForMapping/all`)
        .then(response => {
            dispatch({
                type: ARTICLE_EXCLUSION_GET_OPTIONS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        if(!error.response.data.message.includes("java.lang.IndexOutOfBoundsException")){
            handleError(error, dispatch);
        }
        }
    )
}

export const createArticleExclusion = (data) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/exclusion/add`, data)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.articleExclusion.createSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}

export const removeArticleExclusion = (data) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/exclusion/remove`, data)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.articleExclusion.removedSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}


export const clearArticleExclusionsFilters = () => {
    return ({
        type: ARTICLE_EXCLUSION_CLEAR_FILTER
    })
}

export const sortArticleExclusions = (orderBy, sort) => {
    return ({
        type: ARTICLE_EXCLUSION_SORT,
        payload: {orderBy, sort}
    })
}

export const resetUserPassword = (id) => async dispatch => {
    axios.post(`${BASE_URL}/current/account/${id}/resetPassword`)
        .then(() => {
            dispatch({
                type: HIDE_CONFIRMATION_DIALOG
            })
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.passwordReset',
                    entity: 'user',
                    dialogType: 'success',
                    refresh: false
                }
            })
        }).catch(error => {
        handleError(error, dispatch)
    })

}