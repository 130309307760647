import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import { showArticleLikeForUploadDialog, hideArticleLikeForUploadDialog, 
    showArticleLikeUpgradeForUploadDialog, hideArticleLikeForUpgradeUploadDialog,
    batchCreateArticleLikeForMapping, getArticleLikeForTagsForMapping,
    getArticleGroupingBySkus, getAutomatedTagsForMapping } from "../../actions/articleLikeForAction";
import {
    validateAcceptedFields,
    validateRequiredFields,
    validateAuthority
} from "../../utils/utils";
import _ from "lodash";
import { showError } from "../../actions/commonActions";
import ArticleLikeForSimilarUploadDialog from "../../components/dialogs/ArticleLikeFor/ArticleLikeForUploadDialog";
import ArticleLikeForUpgradeUploadDialog from "../../components/dialogs/ArticleLikeFor/ArticleLikeForUpgradeUploadDialog";
import { Box } from "@material-ui/core";
import FilesUpload from "../../components/FilesUpload";
import { fileUploadAcceptedFields, fileUploadRequiredFields, fileUploadRequiredHeaders } from "../../utils/fields";
import { convertToArticleGroupingMappingObject,convertToArticleGroupingRecommendationRequest } from "../../utils/CsvObjectModifier/ArticleLikeForGrouping/ArticleGroupingMapper";
import { LIKEFORSIMILAR_MAPPING_CSV_OBJECT, LIKEFORUPGRADE_MAPPING_CSV_OBJECT } from "../../utils/CsvObjectModifier/CsvObjectFormat";
import ProgressBackdrop from "../ProgressBackdrop";
import { AUTHORITY_ADMIN } from "../../constant";

class ArticleLikeForMapping extends Component {

    constructor(props) {
        super(props)
    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }
    onPromptError = (data) => {
        this.props.showError({ response: { data: { translationKey: `${data}` } } });
    }

    onUpload = (data, prepopulateField, type) => {
        var validatedRecords = this.validateData(data, type);

        if (validatedRecords.lineNumberWithErrors.length === 0 && validatedRecords.recordLimitExceeded == false && validatedRecords.redundantAccessory.length === 0 && validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.length === 0 && validatedRecords.emptyRecord == false && validatedRecords.validHeader == true) {
            var skuList = [];
            var uploadedCountryCodes =[...new Set(data.map(obj => obj.countryCode.slice(0, 2)))];

            data?.map(record => {
                if (skuList.includes(record.sku) === false) skuList.push(record.sku);
            })

            this.props.getArticleGroupingBySkus(skuList);
            this.props.getArticleLikeForTagsForMapping(uploadedCountryCodes);
            if(type === 'likeForSimilar') {
                this.props.getAutomatedTagsForMapping(convertToArticleGroupingRecommendationRequest(data)).then(() => {
                    this.props.showArticleLikeForUploadDialog(data, prepopulateField)
                });
            } else {
                this.props.showArticleLikeUpgradeForUploadDialog(data, prepopulateField)
            }

        } else {
            
            if (validatedRecords.emptyRecord == true) {
                this.props.showError({ response: { data: { translationKey: `0 record uploaded. Please fix and try again.` } } });
            } 
            if (validatedRecords.validHeader == false) {
                this.props.showError({ response: { data: { translationKey: `Invalid CSV header. Please fix and try again.` } } });
            } 

            if (validatedRecords.recordLimitExceeded == true) {
                this.props.showError({ response: { data: { translationKey: `Exceeded 500 limit of records with number of ${data.length} uploaded records. Please fix and try again.` } } });
            } 
            
            if (validatedRecords.lineNumberWithErrors.length > 0){
                this.props.showError({ response: { data: { translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.` } } });
            }

            if(validatedRecords.redundantAccessory.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found record with duplicated sku, countryCode and carrierCode for sku ${validatedRecords.redundantAccessory}. Please fix and try again` } } });
            }

            if(validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found sku with both country specific and carrier specific record for sku ${[... new Set(validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific)]}. Please fix and try again` } } });
            }
        }
    }

    validateData = (data, type) => {
        let validatedRecords = {
            valid: true,
            validHeader: true,
            lineNumberWithErrors: [],
            recordLimitExceeded: false,
            redundantAccessory: [],
            accessoryWithCountrySpecificAndCarrierSpecific: [],
            emptyRecord: false
        };
        
        var currentObjectHeader = Object.keys(data[0]);
        validatedRecords.validHeader = _.isEqual(Object.values(type === 'likeForSimilar' ? fileUploadRequiredHeaders.articleLikeForSimilar : fileUploadRequiredHeaders.articleLikeForUpgrade), currentObjectHeader);

        if(!validatedRecords.validHeader) {
            return validatedRecords;
        }

        let skuWithCarrierCode =[...new Set( data.filter((obj) => obj.carrierCode).map(obj => obj.sku+"-"+ obj.countryCode))];

        data.reduce((count, accessoryObject) => {
            let key = `${accessoryObject.sku}_${accessoryObject.countryCode.slice(0, 2).toLowerCase()}_${accessoryObject.carrierCode.toLowerCase()}`;
            if (!count[key]) {
              count[key] = [accessoryObject];
            } else {
              validatedRecords.redundantAccessory.push(accessoryObject.sku);
              count[key].push(accessoryObject);
            }
            return count;
        }, {});

        data.forEach((datum, index) => {
            validatedRecords.valid = validateAcceptedFields(fileUploadAcceptedFields['articleLikeFor'], Object.keys(datum)) &&
                validateRequiredFields(fileUploadRequiredFields['articleLikeFor'], datum);
            if (validatedRecords.valid == false) {
                validatedRecords.lineNumberWithErrors.push(index + 3)
            }

            if(datum.carrierCode === '' && skuWithCarrierCode.includes(datum.sku+"-"+datum.countryCode)){
                validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.push(datum.sku);
            }
        });

        if(data.length <= 0 ) {
            validatedRecords.emptyRecord = true;
        }

        if (data.length > 500) {
            validatedRecords.recordLimitExceeded = true;
        }

        return validatedRecords;

    }

    uploadArticleLikeForMappings  = (data) => {
        this.props.hideArticleLikeForUploadDialog();
        this.props.hideArticleLikeForUpgradeUploadDialog();
        // data = convertToArticleGroupingMappingObject(data);
        this.props.batchCreateArticleLikeForMapping(data);
    }

    handleServerSideArticleCSVTemplateDownload = (data, type) => {
        var isAdmin  = validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities);
        const link = document.createElement('a');
        if(!isAdmin) {
            var countryCode = this.props.authentication.loggedInUser.countryCode
            link.href = `${process.env.PUBLIC_URL}/assets/resources/template_likefor${type}_mapping_${countryCode}.csv`;
            link.download = `template_likefor${type}_mapping.csv`;

            link.click();
        } else {
            data.map((countryCode) => {
                link.href = `${process.env.PUBLIC_URL}/assets/resources/template_likefor${type}_mapping_${countryCode}.csv`;
                link.download = `template_likefor${type}_mapping_${countryCode}.csv`;

                link.click();
            })
        }

    }

    render() {
        const defaultFields = {
            countryCode: this.props.authentication.loggedInUser.countryCode
        }
        const customeSliceCsvRecord = 1;
        const { t } = this.props;

        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ paddingLeft: 30, paddingTop: 30 }}>{"Like for Like, Similar, Upgrade Mapping"}</h3>
                </Box>
                <Box mb={2} mt={2} style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <FilesUpload defaultFields={defaultFields}
                        templateFile={`template_likefor_mapping.csv`}
                        onUpload={(data, prepopulateField, type) => this.onUpload(data, prepopulateField, 'likeForSimilar')}
                        changeHeader={LIKEFORSIMILAR_MAPPING_CSV_OBJECT}
                        sliceCsv={customeSliceCsvRecord}
                        isAdmin={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities)}
                        handleServerSideArticleCSVTemplateDownload={(data, type) => this.handleServerSideArticleCSVTemplateDownload(data, 'Similar')}
                        titleName={'messages.createLikeForMapping'}
                        processFileType = "articleLikeFor"
                    />
                    <FilesUpload defaultFields={defaultFields}
                        templateFile={`template_likefor_mapping.csv`}
                        onUpload={(data, prepopulateField, type) => this.onUpload(data, prepopulateField, 'likeForUpgrade')}
                        changeHeader={LIKEFORUPGRADE_MAPPING_CSV_OBJECT}
                        sliceCsv={customeSliceCsvRecord}
                        isAdmin={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities)}
                        handleServerSideArticleCSVTemplateDownload={(data, type) => this.handleServerSideArticleCSVTemplateDownload(data, 'Upgrade')}
                        titleName={'messages.createLikeForUpgradeMapping'}
                        processFileType = "articleLikeFor"
                    />

                </Box>

                 <ArticleLikeForSimilarUploadDialog entity={this.props.articleLikeFors.articleLikeForUploadDialog.entity}
                        open={this.props.articleLikeFors.articleLikeForUploadDialog.open}
                        prepopulateField={this.props.articleLikeFors.articleLikeForUploadDialog.prepopulateField}
                        onReject={() => this.props.hideArticleLikeForUploadDialog()}
                        onAccept={(data) => this.uploadArticleLikeForMappings(data)}
                        tagsForMapping={this.props.articleLikeFors.tagsForMapping}
                        recommendationTagsForMapping={this.props.articleLikeFors.recommendationTagsForMapping}
                        groupingBySku={this.props.articleLikeFors.groupingBySku}
                        onPromptError={(data) => this.onPromptError(data)}
                />

                <ArticleLikeForUpgradeUploadDialog entity={this.props.articleLikeFors.articleLikeForUpgradeUploadDialog.entity}
                        open={this.props.articleLikeFors.articleLikeForUpgradeUploadDialog.open}
                        prepopulateField={this.props.articleLikeFors.articleLikeForUpgradeUploadDialog.prepopulateField}
                        onReject={() => this.props.hideArticleLikeForUpgradeUploadDialog()}
                        onAccept={(data) => this.uploadArticleLikeForMappings(data)}
                        tagsForMapping={this.props.articleLikeFors.tagsForMapping}
                        groupingBySku={this.props.articleLikeFors.groupingBySku}
                        onPromptError={(data) => this.onPromptError(data)}
                />

                <ProgressBackdrop open={this.props.common.showBackDropLoader}/>
            </>

        )
    }

}

const mapStateToProps = state => ({
    articleLikeFors: state.articleLikeFors,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    batchCreateArticleLikeForMapping: batchCreateArticleLikeForMapping,
    showArticleLikeForUploadDialog: showArticleLikeForUploadDialog,
    hideArticleLikeForUploadDialog: hideArticleLikeForUploadDialog,
    showArticleLikeUpgradeForUploadDialog: showArticleLikeUpgradeForUploadDialog,
    hideArticleLikeForUpgradeUploadDialog: hideArticleLikeForUpgradeUploadDialog,
    getArticleLikeForTagsForMapping: getArticleLikeForTagsForMapping,
    getAutomatedTagsForMapping: getAutomatedTagsForMapping,
    getArticleGroupingBySkus: getArticleGroupingBySkus,
    showError
})(ArticleLikeForMapping);