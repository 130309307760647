import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {Trans, useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { useHistory, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import logo from "../../assets/images/logo_bolttech.png";
import Tooltip from "@material-ui/core/Tooltip";
import { InfoOutlined } from '@material-ui/icons';

const customStyles = makeStyles({
    success: {
        color: '#4caf50'
    },
    inputFields: {
        marginBottom: 20
    },
    smallIcon: {
        width: 15,
        height: 14,
    },
    toolTipBackground: {
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid black',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        fontSize: '12px'
    }
})

const validationSchema = (t) => {
    return yup.object({
        newPassword: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.password`)})),
        newPasswordConfirmation: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.newPasswordConfirmation`)}))
            .when("newPassword", {
                is: val => (val && val.length > 0),
                then: yup.string().oneOf(
                    [yup.ref("newPassword")],
                    t('messages.passwordsMustMatch')
                )
            })
    });
}

const tooltipContent = (t) => {
    return (
        <div>
        <h3>Passwords must:</h3>
            <ul>
                <li>{t('errorMessage.user.changePassword.newPasswordMustHaveAtLeastEightCharacters')}</li>
                <li>{t('errorMessage.user.changePassword.newPasswordMustContainDifferentCharacterTypes')}</li>
                <li>{t('errorMessage.user.changePassword.newPasswordCannotContainName')}</li>
            </ul> 
        </div>  
    );
} 

const SetNewPassword = ({ onFormSubmit, errorMessage, setNewPasswordToken, requestInProgress, requestSuccess }) => {
    const { t } = useTranslation();
    const classes = customStyles();
    const history = useHistory();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            newPassword: '',
            token: setNewPasswordToken,
            newPasswordConfirmation: ''
        },
        validationSchema: validationSchema(t),
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    const onSubmit = (values) => {
        console.log(values)
        onFormSubmit(values);
    }

    if (requestSuccess) {
        setTimeout(() => { history.push('/login');}, 3000)
    }

    return (
        <div className="authentication-cover">
            <Paper className="authentication-form">
                <img src={logo} alt="bolttech" style={{width: '50%'}} />
                <form onSubmit={formik.handleSubmit}>
                    <p>{t('messages.setNewPassword')} {'  '}
                        <a href="#">
                            <Tooltip classes={{ tooltip: classes.toolTipBackground }} title={tooltipContent(t)} placement="right" arrow>
                                <InfoOutlined className={classes.smallIcon}/>
                            </Tooltip>
                        </a>
                    </p>
                    <TextField
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        label={t('keys.password')}
                        type="password"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={formik.touched.newPassword && formik.errors.newPassword}
                        className={classes.inputFields}
                    />
                    <TextField
                        fullWidth
                        id="newPasswordConfirmation"
                        name="newPasswordConfirmation"
                        label={t('keys.newPasswordConfirmation')}
                        type="password"
                        variant="outlined"
                        value={formik.values.newPasswordConfirmation}
                        onChange={formik.handleChange}
                        error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                        helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
                        className={classes.inputFields}
                    />
                    {errorMessage !== undefined &&
                        <Paper className="authentication-error-message" elevation={3}>{t(errorMessage)}</Paper>
                    }
                    <Button color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={(requestInProgress || requestSuccess)}
                    >
                        {requestInProgress && <CircularProgress />}
                        {requestSuccess && <DoneIcon className={classes.success}/>}
                        {!requestInProgress && !requestSuccess && t('messages.submit')}
                    </Button>
                    {
                        requestSuccess && <div>
                            <p>{t('messages.otpSuccess')}</p>
                            <Trans i18nKey="messages.otpRedirect">
                                If you are not redirected, please click <Link className="font-weight-bold" to="/login">here</Link>
                            </Trans>
                        </div>
                    }
                </form>
                <div className="display-flex justify-right margin-top-10">
                    <a href="/login" >{t('messages.backToLogin')}</a>
                </div>
            </Paper>
        </div>
    )
}

export default SetNewPassword;