import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    ARTICLE_FILTER,
    ARTICLE_GET_DETAILS,
    ARTICLE_GET_SUGGESTED_RETAILS_PRICE,
    ARTICLE_GET_TRADE_IN_PRICE,
    ARTICLE_GET_SYSTEM_CONFIGURATION,
    ARTICLE_GET_ACCESORY_MAPPING,
    ARTICLE_GET_LIKEFOR_MAPPING,
    ARTICLE_GET_LIST,
    ARTICLE_CLEAR_FILTER,
    ARTICLE_SORT,
    SHOW_SUCCESS,
    ARTICLE_RESET_DETAILS,
    ARTICLE_SET_OPERATION_TYPE,
    SHOW_ARTICLE_UPLOAD_ACCORDION,
    HIDE_ARTICLE_UPLOAD_ACCORDION,
    SHOW_LOCALIZE_ARTICLE_UPLOAD_ACCORDION,
    HIDE_LOCALIZE_ARTICLE_UPLOAD_ACCORDION
} from "./types";
import { handleError, showContentLoader, handleMultipleError } from "../utils/utils";
import {showError} from '../actions/commonActions';

export const getArticleList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/?${path}`)
        .then(response => {
            dispatch({
                type: ARTICLE_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const updateFiltersForArticleList = (filterKey, filterValue) => {
    return({
        type: ARTICLE_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const getArticleDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.get(`${BASE_URL}/current/article/${id}`)
        .then(response => {
            dispatch({
                type: ARTICLE_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const getArticleSuggestedRetailPrice = (sku) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/suggestedRetailPrice/${sku}`)
        .then(response => {
            dispatch({
                type: ARTICLE_GET_SUGGESTED_RETAILS_PRICE,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        if(!error.response.data.message.includes("java.lang.IndexOutOfBoundsException")){
            handleError(error, dispatch);
        }
        })
}

export const getArticleTradeInPrice = (sku) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/tradeInPrice/${sku}`)
        .then(response => {
            dispatch({
                type: ARTICLE_GET_TRADE_IN_PRICE,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        if(!error.response.data.message.includes("java.lang.IndexOutOfBoundsException")){
            handleError(error, dispatch);
        }
        })
}

export const getArticleSystemConfiguration = (sku) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/systemConfiguration/${sku}`)
        .then(response => {
            dispatch({
                type: ARTICLE_GET_SYSTEM_CONFIGURATION,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        if(!error.response.data.message.includes("java.lang.IndexOutOfBoundsException")){
            handleError(error, dispatch);
        }
        })
}

export const getArticleAccesoryMapping = (sku) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/accessoryMappingWithArticleDetails/${sku}`)
        .then(response => {
            dispatch({
                type: ARTICLE_GET_ACCESORY_MAPPING,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        if(!error.response.data.message.includes("java.lang.IndexOutOfBoundsException")){
            handleError(error, dispatch);
        }
        })
}

export const getArticleLikeForMapping = (sku) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/grouping/${sku}`)
        .then(response => {
            dispatch({
                type: ARTICLE_GET_LIKEFOR_MAPPING,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        if(!error.response.data.message.includes("java.lang.IndexOutOfBoundsException")){
            handleError(error, dispatch);
        }
    })
}

export const batchCreateArticle = (articles) => async dispatch => {
    console.log("Posting "+ JSON.stringify(articles) );
    axios.post(`${BASE_URL}/current/article/upload`, articles)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.fileUploaded',
                    dialogType: 'success',
                    refresh: true
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}

export const batchLocalizeArticle = (articles) => async dispatch => {
    console.log("Localize Article Posting "+ JSON.stringify(articles) );

    let urls = [
        {urlPath:`${BASE_URL}/current/article/upload`,payload: articles["article"], objectName: "Article"},
        {urlPath:`${BASE_URL}/current/article/tradeInPrice/upload`,payload: articles["articleTradeInPriceObject"], objectName: "TradeInPrice"},
        {urlPath:`${BASE_URL}/current/article/systemConfiguration/upload`,payload: articles["articleSystemConfigObject"], objectName: "SystemConfiguration"},
        {urlPath:`${BASE_URL}/current/article/suggestedRetailPrice/upload`,payload: articles["articleSuggestedRetailPrice"], objectName: "SuggestedRetailPrice"},
      ];
      let failedRequestMessage = [];
      let requests = urls.map(url => axios.post(url.urlPath,url.payload));
      Promise.allSettled(requests)
        .then(responses =>  {
            var getAllErrors = new Promise((resolve, reject) => {
                responses.forEach((result,index) => {
                    if (result.status !== 'fulfilled') {
                        var errorResponse = result.reason?.response.data.message;
                        failedRequestMessage.push(`${urls[index].objectName} failed to upload due to ${errorResponse?.replace(/:/g, '__')} `);
                    } 
                    if (index === responses.length -1) resolve();
                })
            });
            getAllErrors.then(() => {
                if(failedRequestMessage.length > 0 ){
                    var errorMsg = failedRequestMessage.join(', ');
                    handleMultipleError(errorMsg, dispatch)
                } else {
                    dispatch({
                        type: SHOW_SUCCESS,
                        payload: {
                            message: 'messages.user.fileUploaded',
                            dialogType: 'success',
                            refresh: true
                        }
                    })
                }
            })
        });
}

export const clearArticleFilters = () => {
    return ({
        type: ARTICLE_CLEAR_FILTER
    })
}

export const clearArticleDetails = () => {
    return ({
        type: ARTICLE_RESET_DETAILS
    })
}

export const sortArticles = (orderBy, sort) => {
    return ({
        type: ARTICLE_SORT,
        payload: {orderBy, sort}
    })
}

export const batchUploadrticleImages = (payload) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/images/upload`, payload)
        .then(response => {
            if (response.data.invalidRecords.length) {
                let skuList = '';
                response.data.invalidRecords.map(data => skuList = `${skuList} ${data} `)
                dispatch(showError({ response: { data: { translationKey: `SKU {${skuList}} doesn’t exist in the system.` } } }))
                return
            }
            if (response.data.uploadFailureRecords.length) {
                let skuList = '';
                response.data.uploadFailureRecords.map(data => skuList = `${skuList} ${data} `)
                dispatch(showError({ response: { data: { translationKey: `SKU {${skuList}} has failed to upload.` } } }))
            } else {
                dispatch({
                    type: SHOW_SUCCESS,
                    payload: {
                        message: 'Images gets successfully uploaded.',
                        dialogType: 'success'
                    }
                })

            }
        }).catch(error => {
            handleError(error, dispatch)
        })
}

export const batchUploadBrandImages = (payload) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/brandImages/upload`, payload)
        .then(response => {
            if (response.data.invalidRecords != null && response.data.invalidRecords.length > 0) {
                let skuList = '';
                response.data.invalidRecords.map(data => skuList = `${skuList} ${data} `)
                dispatch(showError({ response: { data: { translationKey: `Invalid records {${skuList}} .` } } }))
                return;
            }

            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'Images gets successfully uploaded.',
                    dialogType: 'success',
                    refresh: true
                }
            })

        }).catch(error => {
            handleError(error, dispatch)
        })
}

export const getArticleCSVTemplate = (data, isAdmin) => async dispatch => {
    axios.post(`${BASE_URL}/current/config/articleFile`, data, {
        responseType: 'blob'
    })
    .then(response => {
        dispatch(() => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(response.data);
            if(isAdmin) {
                link.download = 'Template_localise_article_admin.csv';
            } else {
                link.download = 'Template_localise_article_scm.csv';
            }
            
            link.click();
        })
        }).catch(error => {
            handleError(error, dispatch)
        })
}

export const uploadArticleLocalizationCSV = (payload) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/upload/localization`, payload)
        .then(response => {
            if(response.data.numberOfRecordsProcessed == 0) {
                dispatch(showError({ response: { data: { translationKey: `0 record uploaded. Please fix and try again.` } } }))
            } else {
                dispatch({
                    type: SHOW_LOCALIZE_ARTICLE_UPLOAD_ACCORDION,
                    payload: {
                        entity: response.data
                    }
                })
            }
        }).catch(error => {
            handleError(error, dispatch)
        })
}

export const setArticleOperationType = (data) => {
    return({
        type: ARTICLE_SET_OPERATION_TYPE,
        payload: {
            data: data
        }
    })
}

export const showArticleUploadAccordion = (entity) => {
    return({
        type: SHOW_ARTICLE_UPLOAD_ACCORDION,
        payload: {
            entity: entity
        }
    })
}

export const hideArticleUploadAccordion = () => {
    return ({
        type: HIDE_ARTICLE_UPLOAD_ACCORDION
    })
}

export const showLocalizeArticleUploadAccordion = (entity) => {
    return({
        type: SHOW_LOCALIZE_ARTICLE_UPLOAD_ACCORDION,
        payload: {
            entity: entity
        }
    })
}

export const hideLocalizeArticleUploadAccordion = () => {
    return ({
        type: HIDE_LOCALIZE_ARTICLE_UPLOAD_ACCORDION,
        payload: {
            entity: null
        }
    })
}