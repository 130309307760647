import React, { useEffect } from 'react';
import { Dialog, DialogContent, Grid, Typography, Divider, FormControlLabel, FormGroup, Box } from "@material-ui/core";
import { useState } from 'react';
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";

const TemplateDownloadDialog = ({ open, onReject, onSubmit, countryCodes}) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState([]);
    console.log(checked)

    const handleCheckeBoxChange =(e , countryCode) => {
        e.target.checked ? setChecked([...checked, countryCode]) :  setChecked(checked.filter(value => value !== countryCode));

    }

    const handleClose = (event) => {
        onReject();
    };

    const handleDownload = () => {
        onSubmit(checked);
    }


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <div />
            <DialogContent>
                <div>
                    <div className="padding-10">
                        <Grid container item md={12} spacing={5} >
                            <Grid item md={12}>
                                <Typography variant="h6" color="primary">{t('messages.previewDownloadTemplate')}</Typography>
                                <div style={{ padding: "10px 0" }}>
                                    <Typography variant="body1" className="grouping-header">{t('messages.previewDownloadTemplateDescription')}</Typography>
                                </div>
                                <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={checked.length > 0}  onChange={(e) => { e.target.checked ? setChecked(countryCodes) : setChecked([])}}/>} label="All regions" />
                                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                            {
                                                countryCodes.map((countryCode, index) => {
                                                    return (
                                                        <FormControlLabel
                                                        key={countryCode+index}
                                                        label={t(`values.countryCode.${countryCode}`)}
                                                        control={<Checkbox checked={checked.includes(countryCode)} onChange={(e) => {
                                                            handleCheckeBoxChange(e, countryCode)}}/>}
                                                        />
                                                    )
                                                })
                                            }
                                        </Box>
                                </FormGroup>
                                <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                            </Grid>
                        </Grid>
                        <div style={{
                            display: "flex",
                            width: "100%",
                            gap: "10px",
                            justifyContent: "flex-end"
                        }}><Button onClick={handleClose} variant="outlined" style={{
                            borderColor: "black"
                        }}>
                                {"Cancel"}
                            </Button>
                            <Button onClick={handleDownload} size="small" variant="contained" color="secondary" style={{
                                background: "#E3D900",
                                padding: "16px"
                            }} autoFocus>
                                {"Download"}
                            </Button>
                        </div>
                    </div>
                </div >
            </DialogContent>
        </Dialog>
    );
}

export default TemplateDownloadDialog;