import React, {Component} from "react";
import {connect} from "react-redux";
import PageTemplate from "../components/PageTemplate";
import Default from "../components/dashboard/Default";

class Dashboard extends Component {
    componentDidMount() {
    }

    renderDashboard = (productCode) => {
        return <Default />
    }
    render() {
        return(
            <PageTemplate title="messages.welcome" backButton={false}>
                {this.renderDashboard(this.props.authentication.loggedInUser.productCode)}
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
})

export default connect(mapStateToProps, {
    
})(Dashboard);
