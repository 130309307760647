import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import logo from "../../assets/images/logo_bolttech.png";

const customStyles = makeStyles({
    inputFields: {
        marginBottom: 20
    }
})

const validationSchema = (t) => {
    return yup.object({
        email: yup
            .string()
            .email(t('messages.fieldInvalid', {field: t(`keys.email`)}))
            .required(t('messages.fieldRequired', {field: t(`keys.email`)})),
        password: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.password`)}))
    });
}

const LoginPage = ({ onFormSubmit, errorMessage }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema(t),
        onSubmit: (values) => {
            values['email'] = values['email'].toLowerCase();
            onFormSubmit(values);
        },
    });

    return (
        <div className="authentication-cover">
            <Paper className="authentication-form">
                <img src={logo} alt="bolttech" style={{width: '50%'}} />
                <p>Login</p>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label={t('keys.email')}
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        className={classes.inputFields}
                        seleniumselector="loginEmail"
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label={t('keys.password')}
                        type="password"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        className={classes.inputFields}
                        seleniumselector="loginPassword"
                    />
                    {errorMessage !== undefined &&
                    <Paper className="authentication-error-message" elevation={3}>{t(errorMessage)}</Paper>
                    }
                    <Button color="primary" variant="contained" fullWidth type="submit" seleniumselector="loginButton">
                        {t('messages.submit')}
                    </Button>
                    <div className="display-flex justify-space-between margin-top-20">
                        <a href="/forgotPassword">{t('messages.forgotPassword')}</a>
                    </div>
                </form>
            </Paper>
        </div>
    )
}

export default LoginPage;