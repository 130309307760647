import React, {Component} from "react";
import { connect } from 'react-redux';
import { clearArticleLikeForSimilarRecommendationUpdatesFilter,
getArticleLikeForSimilarRecommendationUpdates,
updateFiltersForArticleLikeForSimilarRecommendationUpdatesFilter,
updateArticleLikeForSimilarRecommendations, getArticleLikeForSimilarUpdateSchedule } from "../../actions/articleLikeForAction";
import ArticleLikeForRecommendationUpdatesTable from "../../components/Article/ArticleLikeForRecommendationUpdatesTable";
import { parseObjectToString } from "../../utils/utils";
import { USER_COUNTRY_CODES } from "../../constant";
import { showConfirmationDialog, showError } from "../../actions/commonActions";

class ArticleLikeForRecommendationUpdates extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getArticleLikeForSimilarRecommendationUpdates('orderBy=createdAt&sort=desc&size=100').then(() => {
            this.props.getArticleLikeForSimilarUpdateSchedule('articleRecommendation.createSync.scheduler.lastRunTime')
        })
        this.props.clearArticleLikeForSimilarRecommendationUpdatesFilter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            window.setTimeout(() => {window.location.reload(true);}, 2000 )
        }
    }

    renderLikeForSimilarUpdateLists(likeForSimilarRecommendationUpdatesList, showContentLoader) {
        const fields = [
            {
                name: 'description',
            },
            {
                name: 'current.optionOne',
            },
            {
                name: 'current.optionTwo',
            },
            {
                name: 'current.optionThree',

            },
            {
                name: 'recommendation.optionOne',
            },
            {
                name: 'recommendation.optionTwo',
            },
            {
                name: 'recommendation.optionThree',
            },
            
        ];

        const filterKeys = [
            {
                name: 'countryCode',
                type: 'checkBoxes',
                options: USER_COUNTRY_CODES
            }
        ];

        return(
            <ArticleLikeForRecommendationUpdatesTable fields={fields}
                       items={likeForSimilarRecommendationUpdatesList}
                       onValueSubmit={() => this.props.getArticleLikeForSimilarRecommendationUpdates(parseObjectToString(this.props.articleLikeFors.likeForSimilarRecommendationUpdatesListFilters))}
                       showContentLoader={showContentLoader}
                       onSelectedDataSubmit={(data) => this.onSelectedDataSubmit(data)}
                       onAllDataSubmit={(data) => this.onAllDataSubmit(data)}
                       articleLikeForUpdateSchedule={this.props.articleLikeFors.articleLikeForUpdateSchedule}
            />
        )
    }
    
    onSelectedDataSubmit = (data) => {
       this.props.updateArticleLikeForSimilarRecommendations(data)
    }

    onAllDataSubmit = (data) => {
        this.props.updateArticleLikeForSimilarRecommendations(data)
    }

    render() {

        return(
            <>
                {this.renderLikeForSimilarUpdateLists(this.props.articleLikeFors.likeForSimilarRecommendationUpdatesList, this.props.common.showContentLoader)}
            </>
        )
    }

}

const mapStateToProps = state => ({
    articleLikeFors: state.articleLikeFors,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleLikeForSimilarRecommendationUpdates: getArticleLikeForSimilarRecommendationUpdates,
    updateFiltersForArticleLikeForSimilarRecommendationUpdatesFilter: updateFiltersForArticleLikeForSimilarRecommendationUpdatesFilter,
    clearArticleLikeForSimilarRecommendationUpdatesFilter: clearArticleLikeForSimilarRecommendationUpdatesFilter,
    updateArticleLikeForSimilarRecommendations: updateArticleLikeForSimilarRecommendations,
    getArticleLikeForSimilarUpdateSchedule: getArticleLikeForSimilarUpdateSchedule,
    showConfirmationDialog,
    showError
})(ArticleLikeForRecommendationUpdates);