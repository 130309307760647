import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../../components/PageTemplate";
import {
    getUserDetails, resetUserPassword, updateUser
} from "../../actions/userActions";
import DataTableLoader from "../../components/DataTableLoader";
import UserForm from "../../components/Forms/User/UserForm";
import SectionTemplate from "../../components/SectionTemplate";
import Button from "@material-ui/core/Button";
import {withTranslation} from "react-i18next";
import {showConfirmationDialog} from "../../actions/commonActions";

class UserDetails extends Component {
    componentDidMount() {
        this.props.getUserDetails(this.props.match.params.id);
    }

    updateUser = (values) => {
        this.props.updateUser(values, this.props.match.params.id);
    }

    renderDetails = (user) => {
        console.log("props", user);
        const displayFields = [
            'failedLoginAttempts',
            'lastLoginAt',
            'updatedAt',
            'createdAt'
        ];

        const formFields = [
            'name',
            'displayName',
            'email',
            'active',
            'locked',
            'languageCode',
            'authorities',
            'availableCountryCodes',
            'countryCode'
        ];

        const editableFields = formFields.map(formField => formField)
            .reduce((obj, key) => ({ ...obj, [key]: user[key] }), {});

        const readOnlyFields = displayFields.map(displayField => displayField)
            .reduce((obj, key) => ({ ...obj, [key]: user[key] }), {});
        console.log(this.props)
        return (
            Object.keys(user).length === 0 ? 'Loading...' :
                <UserForm editableFields={editableFields}
                          readOnlyFields={readOnlyFields}
                          loggedInUser={this.props.authentication.loggedInUser}
                          authorities={this.props.authentication.authorityList}
                          onFormSubmit={values => this.updateUser(values)}
                          updateUser={true}
                />
        );
    }

    handlePasswordReset = () => {
        this.props.showConfirmationDialog(
            this.props.match.params.id,
            null,
            null,
            'user',
            'password',
            'resetPassword',
            null,
            'resetUserPassword',
            null
            )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.userDetails">
                {this.props.common.showContentLoader ? <DataTableLoader /> :
                    <React.Fragment>
                        {this.renderDetails(this.props.user.user)}
                        <SectionTemplate title="passwordReset">
                            <Button color="primary"
                                    variant="contained"
                                    onClick={() => this.handlePasswordReset()}
                            >{t('messages.passwordReset')}</Button>
                        </SectionTemplate>
                    </React.Fragment>
                    }
            </PageTemplate>

        )
    }
}

const mapStateToProps = state => ({
    user: state.users,
    state: state,
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    common: state.common
})

export default connect(mapStateToProps, {
    getUserDetails: getUserDetails,
    updateUser: updateUser,
    resetUserPassword: resetUserPassword,
    showConfirmationDialog
})(withTranslation() (UserDetails));