import React from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import { AUTHORITY_ADMIN } from "../../constant";
import { fullDateWithIndicator } from "../../utils/utils";

const ArticleSystemConfigurationTable = (props) => {
  const { articleSystemConfiguration, userCountryCode} = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.endoflife.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.carrierCode.keyword`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleSystemConfiguration?.endOfLife &&
                Object.entries(articleSystemConfiguration.endOfLife)?.map(
                  ([countryCode, countryValue]) => {
                    if (
                      countryCode != null &&
                      countryValue != null 
                    ) {
                      if (countryValue?.type === "BooleanAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td></td>
                            <td>
                              <strong>
                                {t(`keys.boolean.${countryValue.value}`)}
                              </strong>
                            </td>
                          </tr>
                        );
                      }

                      if (countryValue?.type === "HashMapBooleanAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>{carrierCodeKey}</strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>
                                          {t(
                                            `keys.boolean.${carrierCodeValue}`
                                          )}
                                        </strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                          </tr>
                        );
                      }
                    }
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label>
            {t(`keys.article.availableForDirectToCustomer.keyword`)}
          </label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.carrierCode.keyword`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleSystemConfiguration?.availableForDirectToCustomer &&
                Object.entries(
                  articleSystemConfiguration.availableForDirectToCustomer
                )?.map(([countryCode, countryValue]) => {
                  if (
                    countryCode != null &&
                    countryValue != null 
                  ) {
                    if (countryValue?.type === "BooleanAttribute") {
                      return (
                        <tr>
                          <td>{countryCode}</td>
                          <td></td>
                          <td>
                            <strong>
                              {t(`keys.boolean.${countryValue.value}`)}
                            </strong>
                          </td>
                        </tr>
                      );
                    }

                    if (countryValue?.type === "HashMapBooleanAttribute") {
                      return (
                        <tr>
                          <td>{countryCode}</td>
                          <td>
                            {countryValue?.value &&
                              Object.entries(countryValue.value).map(
                                ([carrierCodeKey, carrierCodeValue]) => {
                                  return (
                                    <div>
                                      <strong>{carrierCodeKey}</strong>
                                    </div>
                                  );
                                }
                              )}
                          </td>
                          <td>
                            {countryValue?.value &&
                              Object.entries(countryValue.value).map(
                                ([carrierCodeKey, carrierCodeValue]) => {
                                  return (
                                    <div>
                                      <strong>
                                        {t(`keys.boolean.${carrierCodeValue}`)}
                                      </strong>
                                    </div>
                                  );
                                }
                              )}
                          </td>
                        </tr>
                      );
                    }
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.availableForSwitch.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.carrierCode.keyword`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleSystemConfiguration?.availableForSwitch != null ? (
                Object.entries(
                  articleSystemConfiguration.availableForSwitch
                )?.map(([countryCode, countryValue]) => {
                  if (
                    countryCode != null &&
                    countryValue != null
                  ) {
                    if (countryValue?.type === "BooleanAttribute") {
                      return (
                        <tr>
                          <td>{countryCode}</td>
                          <td></td>
                          <td>
                            <strong>
                              {t(`keys.boolean.${countryValue.value}`)}
                            </strong>
                          </td>
                        </tr>
                      );
                    }

                    if (countryValue?.type === "HashMapBooleanAttribute") {
                      return (
                        <tr>
                          <td>{countryCode}</td>
                          <td>
                            {countryValue?.value &&
                              Object.entries(countryValue.value).map(
                                ([carrierCodeKey, carrierCodeValue]) => {
                                  return (
                                    <div>
                                      <strong>{carrierCodeKey}</strong>
                                    </div>
                                  );
                                }
                              )}
                          </td>
                          <td>
                            {countryValue?.value &&
                              Object.entries(countryValue.value).map(
                                ([carrierCodeKey, carrierCodeValue]) => {
                                  return (
                                    <div>
                                      <strong>
                                        {t(`keys.boolean.${carrierCodeValue}`)}
                                      </strong>
                                    </div>
                                  );
                                }
                              )}
                          </td>
                        </tr>
                      );
                    }
                  }
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.launchDate.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.carrierCode.keyword`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleSystemConfiguration?.launchDate &&
                Object.entries(articleSystemConfiguration.launchDate)?.map(
                  ([countryCode, countryValue]) => {
                    if (
                      countryCode != null &&
                      countryValue != null
                    ) {
                      if (countryValue?.type === "DateAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td></td>
                            <td>
                              <strong>
                                {fullDateWithIndicator(countryValue.value, userCountryCode)}
                              </strong>
                            </td>
                          </tr>
                        );
                      }

                      if (countryValue?.type === "HashMapDateAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>{carrierCodeKey}</strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>
                                          {fullDateWithIndicator(
                                            carrierCodeValue, userCountryCode
                                          )}
                                        </strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                          </tr>
                        );
                      }
                    }
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.goLiveDate.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.carrierCode.keyword`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleSystemConfiguration?.goLiveDate &&
                Object.entries(articleSystemConfiguration.goLiveDate)?.map(
                  ([countryCode, countryValue]) => {
                    if (
                      countryCode != null &&
                      countryValue != null
                    ) {
                      if (countryValue?.type === "DateAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td></td>
                            <td>
                              <strong>
                                {fullDateWithIndicator(countryValue.value, userCountryCode)}
                              </strong>
                            </td>
                          </tr>
                        );
                      }

                      if (countryValue?.type === "HashMapDateAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>{carrierCodeKey}</strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>
                                          {fullDateWithIndicator(
                                            carrierCodeValue, userCountryCode
                                          )}
                                        </strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                          </tr>
                        );
                      }
                    }
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.availableForTradeIn.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.carrierCode.keyword`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleSystemConfiguration?.availableForTradeIn != null ? (
                Object.entries(articleSystemConfiguration.availableForTradeIn)?.map(
                  ([countryCode, countryValue]) => {
                    if (
                      countryCode != null &&
                      countryValue != null
                    ) {
                      if (countryValue?.type === "BooleanAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td></td>
                            <td>
                              <strong>
                                {t(`keys.boolean.${countryValue.value}`)}
                              </strong>
                            </td>
                          </tr>
                        );
                      }
  
                      if (countryValue?.type === "HashMapBooleanAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>{carrierCodeKey}</strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>
                                          {t(`keys.boolean.${carrierCodeValue}`)}
                                        </strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                          </tr>
                        );
                      }
                      
                    }
                  }
                )
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ArticleSystemConfigurationTable;