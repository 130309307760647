export const convertToArticleExclusionMappingObject = (data) => {
    var exclusionRequestArray = [];
    data.exclusionList.map((tagForMapping) => {
        var newExclusion = {};
        newExclusion["tagForMapping"] = tagForMapping;
        newExclusion["countryCodes"] = data.countryCodes;
        newExclusion["carrierCodes"] = data.carrierCodes;

        exclusionRequestArray.push(newExclusion);
    })

    return exclusionRequestArray;
};