import { Typography } from '@material-ui/core';
import React from 'react';

const Default = () => {
    return (
        <div spacing={5} style={{padding: "8px"}}>
            <Typography variant="h6" className="grouping-header">Global Article Master Dashboard</Typography>
        </div>
    )
}

export default Default;