import {
    ARTICLE_FILTER,
    ARTICLE_GET_DETAILS,
    ARTICLE_RESET_DETAILS,
    ARTICLE_GET_LIST,
    ARTICLE_CLEAR_FILTER,
    ARTICLE_SORT,
    SHOW_ARTICLE_UPLOAD_ACCORDION,
    HIDE_ARTICLE_UPLOAD_ACCORDION,
    SHOW_LOCALIZE_ARTICLE_UPLOAD_ACCORDION,
    HIDE_LOCALIZE_ARTICLE_UPLOAD_ACCORDION,
    ARTICLE_GET_SUGGESTED_RETAILS_PRICE,
    ARTICLE_GET_TRADE_IN_PRICE,
    ARTICLE_GET_SYSTEM_CONFIGURATION,
    ARTICLE_GET_ACCESORY_MAPPING,
    ARTICLE_GET_LIKEFOR_MAPPING,
    ARTICLE_SET_OPERATION_TYPE
} from "../actions/types";

const INITIAL_STATE = {
    articles: [],
    article: {},
    articleSuggestedRetailPrice: {},
    articleTradeInPrice: {},
    articleSystemConfiguration: {},
    articleAccesoryMapping: {},
    articleLikeForMapping: {},
    articleOperationType: null,
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    articleFilters: {
        sku: '',
        'manufacturer.code': '',
        'type.code': [],
        'description': '',
        'uploader.name': '',
        createdAt: {
            from: null,
            to: null
        },
        updatedAt: {
            from: null,
            to: null
        }
    },
    articleUploadAccordion: {
        entity: null,
        uploadAccordion: true,
        previewAccordion: false
    },
    articleLocalizeUploadAccordion: {
        entity: null,
        uploadAccordion: true,
        previewAccordion: false
    },
}

const articleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ARTICLE_GET_LIST:
            return {
                ...state,
                articles: action.payload.data
            }
        case ARTICLE_FILTER:
            return {
                ...state,
                articleFilters: {
                    ...state.articleFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case ARTICLE_GET_DETAILS:
            return {
                ...state,
                article: action.payload.data,
            }
        case ARTICLE_RESET_DETAILS:
            return {
                ...state,
                article: {},
                articleSuggestedRetailPrice: {},
                articleTradeInPrice: {},
                articleSystemConfiguration: {},
                articleAccesoryMapping: {},
                articleLikeForMapping: {}
            }
        case ARTICLE_GET_SUGGESTED_RETAILS_PRICE:
            return {
                ...state,
                articleSuggestedRetailPrice: action.payload.data,
            }
        case ARTICLE_GET_TRADE_IN_PRICE:
            return {
                ...state,
                articleTradeInPrice: action.payload.data,
            }
        case ARTICLE_GET_SYSTEM_CONFIGURATION:
            return {
                ...state,
                articleSystemConfiguration: action.payload.data,
            }
        case ARTICLE_GET_ACCESORY_MAPPING:
            return {
                ...state,
                articleAccesoryMapping: action.payload.data,
            }
        case ARTICLE_GET_LIKEFOR_MAPPING:
            return {
                ...state,
                articleLikeForMapping: action.payload.data,
            }
        case ARTICLE_SET_OPERATION_TYPE:
            return {
                ...state,
                articleOperationType: action.payload.data,
            }
        case ARTICLE_CLEAR_FILTER:
            return {
                ...state,
                articleFilters: {
                    sku: '',
                    'manufacturer.code': '',
                    'type.code': [],
                    'description': '',
                    'uploader.name': '',
                    createdAt: {
                        from: null,
                        to: null
                    },
                    updatedAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case ARTICLE_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case SHOW_ARTICLE_UPLOAD_ACCORDION:
            return {
                ...state,
                articleUploadAccordion: {
                    entity: action.payload.entity,
                    uploadAccordion: false,
                    previewAccordion: true
                }
            }
        case HIDE_ARTICLE_UPLOAD_ACCORDION:
            return {
                ...state,
                articleUploadAccordion: {
                    entity: null,
                    uploadAccordion: true,
                    previewAccordion: false
                }
            }
        case SHOW_LOCALIZE_ARTICLE_UPLOAD_ACCORDION:
            return {
                ...state,
                articleLocalizeUploadAccordion: {
                    entity: action.payload.entity,
                    uploadAccordion: false,
                    previewAccordion: true
                }
            }   
        case HIDE_LOCALIZE_ARTICLE_UPLOAD_ACCORDION:
            return {
                ...state,
                articleLocalizeUploadAccordion: {
                    entity: null,
                    uploadAccordion: true,
                    previewAccordion: false
                }
            }      
        default:
            return state;
    }
}

export default articleReducer;