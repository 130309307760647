import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {getIn, useFormik} from "formik";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FormControl, Card, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LOCALISED_CARRIER_SPECIFIC_SELECTION } from '../../../utils/CsvObjectModifier/CsvObjectFormat';
import { USER_COUNTRY_CODES } from '../../../constant';

const customStyles = makeStyles({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    selectField: {
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    customStyleForSelect: {
        color: '#f44336',
        fontSize: '0.75rem',
        marginTop: '3px',
        marginLeft: '14px',
        marginRight: '14px'
    },
    textMargin: {
        marginLeft: '10px'
    }
})

const ArticleExclusionForm = ({ editableFields, validationRules, onFormSubmit, options, isAdmin, userCountryCodes }) => {
    const { t } = useTranslation();
    const classes = customStyles();
    const disabledCountryList = ['HK', 'TH', 'PH', 'MY', 'SG', 'ID','AT', 'SE', 'TW', 'KR', 'JP', 'IE']
    const [carrierCodes, setCarrierCodes] = React.useState([]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: editableFields,
        validationSchema: validationRules,
        onSubmit: (values, { setSubmitting }) => {
            onFormSubmit(values)
            setTimeout(function(){
                setSubmitting(false)
            }, 1000);
        }
    })

    const [numberOfMappings, setNumberOfMappings] = React.useState(formik.values['exclusionList'] === undefined || formik.values['exclusionList'].length === 0 ? 1 : formik.values['exclusionList'].length);

    const removePickUpDay = () => {
        if (numberOfMappings > 1) {
            formik.values['exclusionList'].pop();
            setNumberOfMappings(numberOfMappings - 1);
        }
    }

    return (
        <form onSubmit={formik.handleSubmit} className="padding-bottom-10">
            <Grid container item md={12} spacing={5}>
                
                <Grid item md={3}>
                    <Typography variant="h6" className={`grouping-header ${classes.textMargin}`}>{t(`messages.exclusionListMapping`)}</Typography>

                    {
                        <div className={classes.selectField + ' grouping-content'}>
                            <Typography variant="subtitle2">{t('keys.tagForMapping')}</Typography>
                            {[...Array(numberOfMappings)].map((x, i) => {
                                return (
                                    <Autocomplete
                                        freeSolo
                                        autoSelect
                                        key={`exclusionList[${i}]`} 
                                        name={`exclusionList[${i}]`}
                                        onChange={(event, newValue) => {
                                            const updatedList = [...formik.values.exclusionList];
                                            updatedList[i] = newValue;
                                            formik.setFieldValue(`exclusionList`, updatedList);
                                        }}
                                        value={formik.values['exclusionList'][i] || ''} 
                                        className="select margin-bottom-10"
                                        style={{width: '100%'}}
                                        getOptionLabel={(option) => option}
                                        options={options}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                )
                            })}
                            {
                                formik.touched['exclusionList'] && Boolean(formik.errors['exclusionList']) &&
                                <FormHelperText error>{t(formik.errors['exclusionList'], {field: t('keys.tagForMapping')})}</FormHelperText>
                            }
                            <Button
                                onClick={() => setNumberOfMappings(numberOfMappings < 30 ? numberOfMappings + 1 : numberOfMappings)}>{t('messages.addMore')}</Button>
                            <Button onClick={() => removePickUpDay()}>{t('messages.remove')}</Button>
                        </div>
                        
                    }
                </Grid>

                <Grid item md={3}>
                    <Typography variant="h6" className="grouping-header">{t(`keys.countryCode`)}</Typography>
                    <div className="grouping-content">
                        <FormControl
                            className={classes.inputField}
                            error={formik.touched['countryCodes'] && Boolean(formik.errors['countryCodes'])}
                        >
                            <Card variant="outlined" className={`grouping-content ${formik.touched['countryCodes'] && Boolean(formik.errors['countryCodes']) && classes.cardDanger}`}>
                                {
                                (isAdmin ? USER_COUNTRY_CODES : userCountryCodes).map((countryCode) => {
                                    return (<FormControlLabel
                                        className="MuiTypography-root MuiTypography-subtitle2"
                                        key={`countryCodes.${countryCode}`}
                                        style={{width: '100%'}}
                                        control={
                                            <Checkbox
                                                checked={formik.values['countryCodes'].includes(countryCode)}
                                                value={countryCode}
                                                disabled={disabledCountryList.includes(countryCode)}
                                                onChange={(event, newValue) => {
                                                    formik.handleChange(event)
                                                    newValue ?
                                                    setCarrierCodes(prevCarrierCodes => [...prevCarrierCodes, ...LOCALISED_CARRIER_SPECIFIC_SELECTION[countryCode]]) :
                                                    setCarrierCodes(prevCarrierCodes => prevCarrierCodes.filter(carrierCodes => !LOCALISED_CARRIER_SPECIFIC_SELECTION[countryCode].includes(carrierCodes)));
                                                    const updatedCarrierCodes = [...formik.values.carrierCodes];
                                                    formik.setFieldValue(`carrierCodes`, updatedCarrierCodes.filter(carrierCodes => !LOCALISED_CARRIER_SPECIFIC_SELECTION[countryCode].includes(carrierCodes)));
                                                }}
                                                name="countryCodes"
                                                color="primary"
                                            />
                                        }
                                        label={t(`values.countryCode.${countryCode}`)}
                                    />)
                                })}
                            </Card>
                            {
                                formik.touched['countryCodes'] && Boolean(formik.errors['countryCodes']) &&
                                <FormHelperText>{t(formik.errors['countryCodes'], {field: t('keys.countryCodes')})}</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </Grid>

                <Grid item md={3}>
                    <Typography variant="h6" className="grouping-header">{t(`keys.carrierCode`)}</Typography>
                    <div className="grouping-content">
                        <FormControl
                            className={classes.inputField}
                            error={formik.touched['carrierCodes'] && Boolean(formik.errors['carrierCodes'])}
                        >
                            <Card variant="outlined" className={`grouping-content ${formik.touched['carrierCodes'] && Boolean(formik.errors['carrierCodes']) && classes.cardDanger}`}>
                                {carrierCodes.map(carrierCode => {
                                    return (<FormControlLabel
                                        className="MuiTypography-root MuiTypography-subtitle2"
                                        key={`carrierCodes.${carrierCode}`}
                                        style={{width: '100%'}}
                                        control={
                                            <Checkbox
                                                checked={formik.values['carrierCodes'].includes(carrierCode)}
                                                value={carrierCode}
                                                onChange={formik.handleChange}
                                                name="carrierCodes"
                                                color="primary"
                                            />
                                        }
                                        label={carrierCode}
                                    />)
                                })}
                            </Card>
                            {
                                formik.touched['carrierCodes'] && Boolean(formik.errors['carrierCodes']) &&
                                <FormHelperText>{t(formik.errors['carrierCodes'], {field: t('keys.carrierCodes')})}</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
            <div className="margin-top-10 text-right" style={{ marginRight: '10px'}}>
                <Button disabled={!formik.isValid || formik.isSubmitting} variant="contained" color="primary" type="submit" seleniumselector="businessEntityFormSubmit">
                    {t('messages.submit')}
                </Button>
            </div>
        </form>
    )
}

export default ArticleExclusionForm;