import React from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import ReactJsonViewCompare from 'react-json-view-compare';
import { fullDate } from "../../utils/utils";

const AuditDetails = ({
    articleAudits, userCountryCode
}) => {
  const { t } = useTranslation();

  const oldData =articleAudits.entityBeforeChange != null ?  JSON?.parse(articleAudits.entityBeforeChange) : {}; 
  const newData =articleAudits.entityAfterChange != null ?  JSON?.parse(articleAudits.entityAfterChange) : {};

  if (!articleAudits) return null;
  return (
    <div className="custom-bootstrap">
        <div className="card card-accent-info">
            <div className="card-header display-flex align-items-center">
                <strong>{t(`messages.auditsLabel`)}</strong>
                <span className="ml-2"></span>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group" >
                            <label>
                                {t('keys.audits.system')}
                            </label>
                            <br />
                            <label className="font-weight-bold"> {articleAudits.application}</label>
                        </div>
                        <div className="form-group" >
                            <label>
                                {t('keys.audits.entityName')}
                            </label>
                            <br />
                            <label className="font-weight-bold"> {articleAudits.entityName}</label>
                        </div>
                        <div className="form-group" >
                            <label>
                                {t('keys.sku')}
                            </label>
                            <br />
                            <label className="font-weight-bold"> {articleAudits.entityId}</label>
                        </div>
                        <div className="form-group" >
                            <label>
                                {t('keys.audits.entityId')}
                            </label>
                            <br />
                            <label className="font-weight-bold"> {articleAudits.id}</label>
                        </div>
                        <div className="form-group" >
                            <label>
                                {t('keys.updatedBy')}
                            </label>
                            <br />
                            <label className="font-weight-bold"> {articleAudits.performerName}</label>
                        </div>
                        <div className="form-group" >
                            <label>
                                {t('keys.updatedAt')}
                            </label>
                            <br />
                            <label className="font-weight-bold"> {fullDate(articleAudits.updatedAt, userCountryCode) }</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="card card-accent-info">
            <div className="card-header display-flex align-items-center">
                <strong>{t(`keys.audits.entityDifference`)}</strong>
                    <span className="ml-2"></span>
            </div>

            <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex flex-column">
                      <div className="form-group">
                          Entity before Change
                          <br></br>
                          <div className="card">
                              <div className="card-block">
                                  <pre id="entityBeforeChange">
                                  <ReactJsonViewCompare oldData={oldData} newData={oldData} />

                                  </pre>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="col-12 col-md-6 d-flex flex-column align-self-center">
                      <div className="form-group">
                          Entity After Change
                          <br></br>
                          <div className="card">
                              <div className="card-block">
                                <ReactJsonViewCompare oldData={oldData} newData={newData} />
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>

    </div>
  );
};

export default AuditDetails;
