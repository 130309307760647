import { BOLTTECH_DEFAULT_CDN_PATH } from "../../../constant";

export const firstArticleInfoRowMapping = (article, t) =>[{
    label: t(`keys.article.sku.keyword`),
    type: "string",
    value: article.sku
},
{
    label: t(`keys.article.serialized.keyword`),
    type: "string",
    value: t(`keys.boolean.${article.serialized}`)
},
{
    label: t(`keys.article.gtin.keyword`),
    type: "string",
    value: article.gtin
},
{
    label: t(`keys.article.active.keyword`),
    type: "string",
    value: t(`keys.boolean.${article.active}`)
},
{
    label: t(`keys.article.tac.keyword`),
    type: "tacArray",
    value: article?.tacs
},
{
    label: t(`keys.article.description.keyword`),
    type: "string",
    value: article?.description
},
{
    label: t(`keys.article.foldable.keyword`),
    type: "string",
    value: t(`keys.boolean.${article.foldable}`)
},
] 

export const secondArticleInfoRowMapping = (article, t) => [{
    label: t(`keys.article.manufacturerName.keyword`),
    type: "manufacturerNameArray",
    value: article?.manufacturer
    },
    {
    label: t(`keys.article.uploader.keyword`),
    type: "string",
    value: article.uploader?.name
    },
    {
    label: t(`keys.article.createdAt.keyword`),
    type: "dateString",
    value: article.createdAt
    },
    {
    label: t(`keys.article.uploadedAt.keyword`),
    type: "dateString",
    value: article.updatedAt
    }
]

export const thirdArticleInfoRowMapping = (article, t) => [{

    label: t(`keys.article.tagForMapping.keyword`),
    type: "string",
    value: article?.tagForMapping
    }
]

export const imageArticleInfoRowMapping = (article) => [{
    label: null,
    type: "image",
    value: `${BOLTTECH_DEFAULT_CDN_PATH}/images/articles/${article.sku}-front.png`
    },{
    label: null,
    type: "image",
    value: `${BOLTTECH_DEFAULT_CDN_PATH}/images/articles/${article.sku}-back.png`
}
]