import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    request => {
        request.headers['Authorization'] = `Bearer ${localStorage.getItem('global-article-master-token')}`;
        request.headers['Content-Type'] = 'application/json';
        return request
    },
    error => {
        console.log(error);
    }
)

axiosInstance.interceptors.response.use(function (response) {
    if (response.headers['x-refresh-token']) {
        localStorage.setItem('global-article-master-token', response.headers['x-refresh-token']);
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default axiosInstance;