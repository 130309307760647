import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { AUTHORITY_ADMIN } from "../../constant";
import { fullDateWithIndicator } from "../../utils/utils";

const ArticleAttributesInfo = (props) => {
  const { article, userCountryCode } = props;
  const { t } = useTranslation();
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });
  const customStyleClass = customStyles();

  return (
    <>
      <div className="col-md-4">
        {article.attributes &&
          Object.entries(article.attributes)?.map(([key, value], attributeIndex) => {
            if (value.type === "StringAttribute") {
              return (
                <div className="form-group" key={`${key}-${attributeIndex}`}>
                  <label>
                    {t(`keys.article.attributes.${key}.value.keyword`)}
                  </label>
                  <br />
                  <label className="font-weight-bold">{value.value}</label>
                </div>
              );
            }
          })}
      </div>

      <div className="col-md-4">
        {article.attributes &&
          Object.entries(article.attributes)?.map(([key, value], attributeIndex) => {
            if (value.type === "HashMapStringAttribute") {
              return (
                <div className="form-group" key={`${key}-${attributeIndex}`}>
                  <label>
                    {t(`keys.article.attributes.${key}.value.keyword`)}
                  </label>
                  <ul className="list-group">
                    {value?.global != null ? (
                      <li className="list-unstyled mb-1">
                        <code className={customStyleClass.customBackground}>
                          Default
                        </code>{" "}
                        <strong>{value?.global}</strong>
                      </li>
                    ) : (
                      <></>
                    )}
                    {value.value &&
                      Object.entries(value.value)?.map(
                        ([childKey, childValue], childIndex) => {
                          if (
                            childKey != null &&
                            childValue != null
                          ) {
                            return (
                              <li className="list-unstyled mb-1" key={`${childKey}-${childIndex}`}>
                                <code
                                  className={customStyleClass.customBackground}
                                >
                                  {childKey}
                                </code>{" "}
                                <strong>{childValue}</strong>
                              </li>
                            );
                          }
                        }
                      )}
                  </ul>
                </div>
              );
            }
          })}
      </div>

      <div className="col-md-4">
        {article.attributes &&
          Object.entries(article.attributes)?.map(([key, value], attributeIndex) => {
            if (value.type === "HashMapDateAttribute") {
              return (
                <div className="form-group" key={`${key}-${attributeIndex}`}>
                  <label>
                    {t(`keys.article.attributes.${key}.value.keyword`)}
                  </label>
                  <ul className="list-group">
                    {value.value &&
                      Object.entries(value.value)?.map(
                        ([childKey, childValue], childIndex) => {
                          if (
                            childKey != null &&
                            childValue != null
                          ) {
                            return (
                              <li className="list-unstyled mb-1" key={`${childKey}-${childIndex}`} >
                                <code
                                  className={customStyleClass.customBackground}
                                >
                                  {childKey}
                                </code>{" "}
                                <strong>
                                  {fullDateWithIndicator(childValue, userCountryCode)}
                                </strong>
                              </li>
                            );
                          }
                        }
                      )}
                  </ul>
                </div>
              );
            }
          })}
      </div>
    </>
  );
};

export default ArticleAttributesInfo;
