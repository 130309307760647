import React from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";

const ArticleTradeInTable = (props) => {
  const { articleTradeInPrice} = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.tradeInPrice.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.grade.keyword`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleTradeInPrice?.price != null ? (
                Object.entries(articleTradeInPrice.price)?.map(
                  ([countryCode, countryValue]) => {
                    if (
                      countryCode != null &&
                      countryValue != null
                    ) {
                      if (countryValue?.type === "MoneyObjectArrayAttribute") {
                        return (
                          <>
                            <tr>
                              <td>{countryCode}</td>
                              <td></td>
                              <td>
                                {countryValue?.value?.map(
                                  (tradeInPriceValue) => {
                                    return (
                                      <div>
                                        <strong>{`${tradeInPriceValue?.amount} (${tradeInPriceValue?.currencyCode})`}</strong>
                                      </div>
                                    );
                                  }
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      }

                      
                      if (
                        countryValue?.type === "HashMapMoneyObjectAttribute"
                      ) {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>{carrierCodeKey}</strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>{`${carrierCodeValue?.amount} (${carrierCodeValue?.currencyCode})`}</strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                          </tr>
                        );
                      }
                      
                    }
                  }
                )
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.upToPrice.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleTradeInPrice?.upToPrice != null ? (
                Object.entries(articleTradeInPrice.upToPrice)?.map(
                  ([countryCode, countryValue]) => {
                    if (
                      countryCode != null &&
                      countryValue != null
                    ) {
                      if (
                        countryValue?.type === "MoneyObjectAttribute"
                      ) {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td></td>
                            <td>
                              <strong>{`${countryValue?.value?.amount} (${countryValue?.value?.currencyCode})`}</strong>
                            </td>
                          </tr>
                        );
                      }
                      
                    }
                  }
                )
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ArticleTradeInTable;
