import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import { showSeverityUploadDialog, hideSeverityUploadDialog, batchCreateSeverity } from "../../actions/severityAction";
import {
    validateAcceptedFields,
    validateRequiredFields
} from "../../utils/utils";
import _ from "lodash";
import { showError } from "../../actions/commonActions";
import SeverityUploadDialog from "../../components/dialogs/Severity/SeverityDialog";
import { Box } from "@material-ui/core";
import FilesUpload from "../../components/FilesUpload";
import { fileUploadAcceptedFields, fileUploadRequiredFields } from "../../utils/fields";
import { convertToSeverityObject,formatSeverityRecord } from "../../utils/CsvObjectModifier/Severity/SeverityMapper";
import { SEVERITY_CSV_OBJECT } from "../../utils/CsvObjectModifier/CsvObjectFormat";
class ArticleSeverity extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    validateDateFormat = (datum) => {
        const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    
        if (!datePattern.test(datum)) {
          return false;
        }
    
        const [day, month, year] = datum.split('-').map(Number);
        const maxDaysInMonth = new Date(year, month, 0).getDate();
    
        if (month < 1 || month > 12 || day < 1 || day > maxDaysInMonth) {
          return false;
        }
    
        return true;
    }

    validateRemarksRequired = (datum) => {
        var statusRequireRemarks = ['shipOut', 'cancel'];

        if(statusRequireRemarks.includes(datum?.status) && !datum?.remarks) {
            return false;
        }
        return true;
    }

    onUpload = (data, validateDateFormat, validateRemarksRequired) => {
        let validatedRecords = {
            valid: true,
            lineNumberWithErrors: [],
            recordLimitExceeded: false,
            redundantSeveritySku: [],
            redundantSeverityImei: [],
            severityRequireRemarks: [],
            invalidDateFormat: [],
            emptyRecord: false
        };

        data.reduce((count, severityObject) => {
            let key = `${severityObject.imei}`;
            if (!count[key]) {
              count[key] = [severityObject];
            } else {
              validatedRecords.redundantSeverityImei.push(severityObject.imei);
              count[key].push(severityObject);
            }
            return count;
        }, {});
        
        data.forEach((datum, index) => {
            validatedRecords.valid = validateAcceptedFields(fileUploadAcceptedFields['severity'], Object.keys(datum)) &&
                validateRequiredFields(fileUploadRequiredFields['severity'], datum);
            if (validatedRecords.valid == false) {
                validatedRecords.lineNumberWithErrors.push(index + 3)
            }

            if(!validateDateFormat(datum['inboundDate'])) {
                validatedRecords.invalidDateFormat.push(datum.imei);
            }

            if(!validateRemarksRequired(datum)) {
                validatedRecords.severityRequireRemarks.push(datum.imei)
            }

        });

        if(data.length <= 0 ) {
            validatedRecords.emptyRecord = true;
        }
        if (data.length > 5000) {
            validatedRecords.recordLimitExceeded = true;
        }

        if (validatedRecords.lineNumberWithErrors.length === 0 && validatedRecords.recordLimitExceeded == false && validatedRecords.redundantSeveritySku.length === 0 && validatedRecords.redundantSeverityImei.length === 0 && validatedRecords.emptyRecord == false && validatedRecords.severityRequireRemarks.length === 0  && validatedRecords.invalidDateFormat.length === 0) {
            this.props.showSeverityUploadDialog(data)
        } else {

            if (validatedRecords.emptyRecord == true) {
                this.props.showError({ response: { data: { translationKey: `0 record uploaded. Please fix and try again.` } } });
            } 

            if (validatedRecords.recordLimitExceeded == true) {
                this.props.showError({ response: { data: { translationKey: `Exceeded 5000 limit of records with number of ${data.length} uploaded records. Please fix and try again.` } } });
            } 
            
            if (validatedRecords.lineNumberWithErrors.length > 0){
                this.props.showError({ response: { data: { translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.` } } });
            }

            if(validatedRecords.redundantSeveritySku.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found record with duplicated record for SKU ${validatedRecords.redundantSeveritySku}. Please fix and try again` } } });
            }

            if(validatedRecords.redundantSeverityImei.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found record with duplicated record for IMEI ${validatedRecords.redundantSeverityImei}. Please fix and try again` } } });
            }

            if(validatedRecords.severityRequireRemarks.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found record with status shipOut/Cancel without remarks for IMEI ${validatedRecords.severityRequireRemarks}. Please fix and try again` } } });
            }
            
            if(validatedRecords.invalidDateFormat.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found record with invalid date format for IMEI ${validatedRecords.invalidDateFormat}. Please fix and try again` } } });
            }
        }
    }


    uploadSeverities = (data) => {
        this.props.hideSeverityUploadDialog();
        data = convertToSeverityObject(data);
        this.props.batchCreateSeverity(data);
    }

    render() {
        const defaultFields = {
            countryCode: this.props.authentication.loggedInUser.countryCode
        }
        const customeSliceCsvRecord = 1;
        const { t } = this.props;

        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ paddingLeft: 30, paddingTop: 30 }}>{"Severity Upload"}</h3>
                </Box>
                <Box mb={2} mt={2} style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <FilesUpload defaultFields={defaultFields}
                        templateFile={`template_severity.csv`}
                        onUpload={(data, validateDateFormat, validateRemarksRequired) => this.onUpload(data, this.validateDateFormat, this.validateRemarksRequired)}
                        changeHeader={SEVERITY_CSV_OBJECT}
                        addAdditionAttributes={(data) => formatSeverityRecord(data)}
                        sliceCsv={customeSliceCsvRecord}
                        titleName={'messages.createSeverity'}
                    />

                </Box>

                <SeverityUploadDialog entity={this.props.severities.severityUploadDialog.entity}
                        open={this.props.severities.severityUploadDialog.open}
                        onReject={() => this.props.hideSeverityUploadDialog()}
                        onAccept={(data) => this.uploadSeverities(data)}
                    />
            </>

        )
    }

}

const mapStateToProps = state => ({
    severities: state.severities,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    batchCreateSeverity: batchCreateSeverity,
    showSeverityUploadDialog: showSeverityUploadDialog,
    hideSeverityUploadDialog: hideSeverityUploadDialog,
    showError
})(ArticleSeverity);