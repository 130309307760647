import React, { useEffect } from 'react';
import { parseDateToYearMonth } from '../../../utils/utils';

const customStyles ={
    tableHeaderDiv: {
      width: '730px',
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: '25px'
    },
    option: {
      width: '800px',
      display: 'flex',
      justifyContent: 'center',

    },
    table: {
      width: '500%',
      borderCollapse: 'collapse',
      tableLayout: 'fixed', 
    },
    cellWithoutBorder: {
      border: '1px',
      padding: '5px',
      width: '15px',
      wordWrap: 'break-word',
    },
    cell: {
      border: '1px solid black',
      padding: '5px',
      wordWrap: 'break-word',
      textAlign: 'center',
      fontSize: 13
    },
    cellWithSingleValue: {
      border: '1px solid black',
      padding: '5px',
      wordWrap: 'break-word',
      fontSize: 13
    },
    headerCell: {
      fontWeight: 'bold',
      fontSize: '11px',
      position: 'sticky',
      top: '0',
      backgroundColor: 'white',
      zIndex: '1',
      width: '100%',
      textAlign: 'center',
    },
};

const ArticleLikeForDropDown = ({option}) => {

    return (
        <div style={customStyles.option}>
            <table style={customStyles.table}>
                {(option?.group == 'Recommended' || option?.group == 'Recommended By AI' || option?.group == 'Recommended By Rules') && (
                <>
                    <tbody>
                        <tr>
                        <td style={customStyles.cell}>{option.iteration}</td>
                        <td style={customStyles.cell}>{option.description}</td>
                        <td style={customStyles.cell}>{parseDateToYearMonth(option.launchDate)}</td>
                        <td style={customStyles.cell}>{option.retailPriceDifference}</td>
                        <td style={customStyles.cell}>{option.refurbished}</td>
                        <td style={customStyles.cell}>{option.preOwned}</td>
                        <td style={customStyles.cell}>{option.new}</td>
                        </tr>
                    </tbody>
                </>
                )
                }

                {(option?.group == 'All' || option?.group == 'Existing') && (
                <>
                    <tbody>
                        <tr>
                        <td style={customStyles.cellWithSingleValue}>{option.description}</td>
                        <th style={customStyles.cellWithoutBorder}></th>
                        <th style={customStyles.cellWithoutBorder}></th>
                        <th style={customStyles.cellWithoutBorder}></th>
                        <th style={customStyles.cellWithoutBorder}></th>
                        <th style={customStyles.cellWithoutBorder}></th>
                        <th style={customStyles.cellWithoutBorder}></th>
                        </tr>
                    </tbody>
                </>
                )
                }
            </table>
        </div>
    )
}

export const ArticleLikeForDropDownTableHeader = ({params}) => {

  return (<li>
    <div className="Custom-option-category">
      {params.group}
      <div style={customStyles.tableHeaderDiv}>
        {(params.group === 'Recommended' || params.group === 'Recommended By AI' || params.group === 'Recommended By Rules') && (
           <table style={customStyles.table}>
           <thead>
             <tr>
             <th style={customStyles.headerCell}>Iteration</th>
             <th style={customStyles.headerCell}>Description</th>
             <th style={customStyles.headerCell}>Launch Date</th>
             <th style={customStyles.headerCell}>RRP%</th>
             <th style={customStyles.headerCell}>Refurbished</th>
             <th style={customStyles.headerCell}>Pre owned</th>
             <th style={customStyles.headerCell}>New </th>
             </tr>
           </thead>
         </table>

        )
        }

        {(params.group === 'All' || params.group === 'Existing') && (
           <table style={customStyles.table}>
           <thead>
             <tr>
             <th style={customStyles.headerCell}>Value</th>
             <th style={customStyles.headerCell}></th>
             <th style={customStyles.headerCell}></th>
             <th style={customStyles.headerCell}></th>
             <th style={customStyles.headerCell}></th>
             <th style={customStyles.headerCell}></th>
             <th style={customStyles.headerCell}></th>
             </tr>
           </thead>
         </table>

        )
        }

    </div>
    </div>

    <ul className="MuiAutocomplete-groupUI">{params.children}</ul>
  </li>)
}

export default ArticleLikeForDropDown;