import React from 'react';
import { CircularProgress, Backdrop, Dialog } from '@material-ui/core';

const ProgressBackdrop = ({open}) => {
    console.log("SHow "+ open)
    return (
        <Backdrop open={open} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" />
        </Backdrop>

    );
}

export default ProgressBackdrop;