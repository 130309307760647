import {
    USER_FILTER,
    USER_GET_DETAILS,
    USER_GET_LIST,
    USER_UPDATE,
    USER_CREATE, USER_CLEAR_FILTER, USER_SORT
} from "../actions/types";

const INITIAL_STATE = {
    users: [],
    user: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    userFilters: {
        email: '',
        name: '',
        businessEntityCode: '',
        locked: [],
        active: [],
        countryCode: [],
        authorities: [],
        createdAt: {
            from: null,
            to: null
        }
    },
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_GET_LIST:
            return {
                ...state,
                users: action.payload.data
            }
        case USER_FILTER:
            return {
                ...state,
                userFilters: {
                    ...state.userFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case USER_GET_DETAILS:
            return {
                ...state,
                user: action.payload.data,
            }
        case USER_CLEAR_FILTER:
            return {
                ...state,
                userFilters: {
                    email: '',
                    name: '',
                    businessEntityCode: '',
                    locked: [],
                    active: [],
                    countryCode: [],
                    authorities: [],
                    createdAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case USER_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case USER_UPDATE:
        case USER_CREATE:
            return {
                ...state
            }
        default:
            return state;
    }
}

export default userReducer;