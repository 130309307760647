import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../../components/PageTemplate";
import {
    getArticleDetails,
    getArticleSuggestedRetailPrice,getArticleTradeInPrice,
    getArticleSystemConfiguration,getArticleAccesoryMapping,
    getArticleLikeForMapping,clearArticleDetails
} from "../../actions/articleAction";
import DataTableLoader from "../../components/DataTableLoader";
import ArticleDetails from "../../components/Article/ArticleDetails"
import {withTranslation} from "react-i18next";

class ArticleGlobalDetails extends Component {
    componentDidMount() {
        this.props.clearArticleDetails();
        this.props.getArticleDetails(this.props.match.params.id).then(() => {
            this.props.getArticleSuggestedRetailPrice(this.props.article.article.sku);
            this.props.getArticleTradeInPrice(this.props.article.article.sku);
            this.props.getArticleSystemConfiguration(this.props.article.article.sku);
            this.props.getArticleAccesoryMapping(this.props.article.article.sku);
            this.props.getArticleLikeForMapping(this.props.article.article.sku);
        })
    }

    renderDetails = (article, articleSuggestedRetailPrice, articleSystemConfiguration, articleTradeInPrice, articleAccesoryMapping, articleLikeForMapping) => {
        return (
            <ArticleDetails article={article} articleSuggestedRetailPrice={articleSuggestedRetailPrice} articleTradeInPrice={articleTradeInPrice} articleSystemConfiguration={articleSystemConfiguration} articleAccesoryMapping={articleAccesoryMapping} articleLikeForMapping={articleLikeForMapping} countryCode={this.props.authentication.loggedInUser.countryCode}>

            </ArticleDetails>
        );
    }
    
    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.articleDetails">
                {this.props.common.showContentLoader ? <DataTableLoader /> :
                    <React.Fragment>
                        {this.renderDetails(this.props.article.article, this.props.article.articleSuggestedRetailPrice, this.props.article.articleSystemConfiguration, this.props.article.articleTradeInPrice, this.props.article.articleAccesoryMapping, this.props.article.articleLikeForMapping)}
                    </React.Fragment>
                    }
            </PageTemplate>

        )
    }
}

const mapStateToProps = state => ({
    article: state.articles,
    articleSuggestedRetailPrice: state.articleSuggestedRetailPrices,
    articleSystemConfiguration: state.articleSystemConfigurations,
    articleTradeInPrice: state.articleTradeInPrices,
    articleAccesoryMapping: state.articleAccesoryMapping,
    articleLikeForMapping: state.articleLikeForMapping,
    state: state,
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleDetails: getArticleDetails,
    getArticleSuggestedRetailPrice: getArticleSuggestedRetailPrice,
    getArticleTradeInPrice: getArticleTradeInPrice,
    getArticleSystemConfiguration: getArticleSystemConfiguration,
    getArticleAccesoryMapping: getArticleAccesoryMapping,
    getArticleLikeForMapping: getArticleLikeForMapping,
    clearArticleDetails: clearArticleDetails
})(withTranslation() (ArticleGlobalDetails));