import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {shortDate} from "../../utils/utils";

const customStyles = makeStyles({
    danger: {
        background: '#f44336',
        width: '100%',
        height: 10
    }
})

const ConfirmationDialog = ({entity, field, action, open, previousValue, newValue, valueType, countryCode, onReject, onAccept, dynamicContent}) => {
    const { t } = useTranslation();
    const [disabled, setDisabled] = React.useState(false);
    const classes = customStyles();

    const handleClose = () => {
        onReject();
    };

    const handleAccept = () => {
        setDisabled(true);
        onAccept();
        window.setTimeout(() => {setDisabled(false);}, 2000 )
    }

    const renderValue = (value, valueType, field, t) => {
        switch (valueType) {
            case 'shortDate':
                return shortDate(value, countryCode)
            case 'translatedString':
                return t(`messages.values.${value}`)
            case 'nestedTranslatedString':
                return t(`values.${field}.${value}`)
            default:
                return (value)
        }
    }

    const renderDynamicValue = (content, dynamicValue) => {
        Object.entries(dynamicValue).map(([key, value]) => {
            content = content.replace(key, value);
        })
        return content;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            transitionDuration={{
                enter: 1,
                exit: 1
            }}
        >
            <div className={classes.danger}/>
            <DialogTitle id="alert-dialog-title">{t('confirmationMessages.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dynamicContent == null ? t(`confirmationMessages.${entity}.${field}.${action}`) : renderDynamicValue(t(`confirmationMessages.${entity}.${field}.${action}`), dynamicContent)}
                </DialogContentText>
                {
                    previousValue && newValue &&
                        <React.Fragment>
                            <DialogContentText>
                                {t(`keys.${field}`)}
                            </DialogContentText>
                            <DialogContentText>
                                {previousValue ? renderValue(previousValue, valueType, field, t) : 'N/A'} ➡ {renderValue(newValue, valueType, field, t)}
                            </DialogContentText>
                        </React.Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    {t('confirmationMessages.cancel')}
                </Button>
                <Button onClick={handleAccept} variant="outlined" color="primary" disabled={disabled}>
                    {t('confirmationMessages.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;