import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import * as yup from "yup";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Card, FormHelperText, IconButton, InputAdornment} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {fullDate} from "../../../utils/utils";
import { AUTHORITY_ADMIN, AUTHORITY_SITE_ADMIN, AUTHORITY_SUPPLY_CHAIN_MANAGER, USER_COUNTRY_CODES } from '../../../constant';

const customStyles = makeStyles({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    cardDanger: {
        borderColor: '#f44336'
    },
    textMargin: {
        marginLeft: '10px'
    }
})

const UserForm = ({ editableFields, readOnlyFields, authorities, onFormSubmit, onBusinessEntityCheck, businessEntityExists, updateUser, loggedInUser }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    const validationRules = yup.object({
        name: yup.string().required('messages.fieldRequired'),
        email: yup.string().email('messages.fieldInvalid').required('messages.fieldRequired'),
        authorities: yup.array().nullable().min(1, 'messages.fieldRequired'),
        availableCountryCodes: yup.array().nullable().min(1, 'messages.fieldRequired'),
        countryCode: yup.string().required('messages.fieldRequired')
    });
    console.log(authorities)
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: editableFields,
        validationSchema: validationRules,
        onSubmit: (values, { setSubmitting }) => {
            values.email = values.email.toLowerCase();
            values.authorities = Array.isArray(values.authorities) ? values.authorities : [values.authorities];
            onFormSubmit(values)
            setTimeout(function(){
                setSubmitting(false)
            }, 1000);
        }
    })

    return (
        <form onSubmit={formik.handleSubmit} className="padding-bottom-10">
            <Grid container item md={12} spacing={5}>
                <Grid item md={3}>
                    <Typography variant="h6" className={`grouping-header ${classes.textMargin}`}>{t(`messages.generalInformation`)}</Typography>
                    <div className="grouping-content">
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.name')}
                            name="name"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['name']}
                            error={formik.touched['name'] && Boolean(formik.errors['name'])}
                            helperText={formik.touched['name'] && t(formik.errors['name'], {field: t('keys.name')})}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.email')}
                            name="email"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['email']}
                            error={formik.touched['email'] && Boolean(formik.errors['email'])}
                            helperText={formik.touched['email'] && t(formik.errors['email'], {field: t('keys.email')})}
                        />
                    </div>
                </Grid>
                <Grid item md={2}>
                    <Typography variant="h6" className="grouping-header">{t(`keys.authorities`)}</Typography>
                    <div className="grouping-content">
                        <FormControl
                            className={classes.inputField}
                            error={formik.touched['authorities'] && Boolean(formik.errors['authorities'])}
                        >
                            <Card variant="outlined" className={`grouping-content ${formik.touched['authorities'] && Boolean(formik.errors['authorities']) && classes.cardDanger}`}>
                                {authorities.map(authority => {
                                    return (<FormControlLabel
                                        className="MuiTypography-root MuiTypography-subtitle2"
                                        key={`authorities.${authority}`}
                                        control={
                                            <Checkbox
                                                checked={formik.values['authorities'].includes(authority)}
                                                value={authority}
                                                onChange={formik.handleChange}
                                                name="authorities"
                                                color="primary"
                                            />
                                        }
                                        label={t(`values.authorities.${authority}`)}
                                        style={{
                                            display: (formik.values['authorities'].includes(AUTHORITY_SUPPLY_CHAIN_MANAGER) && 
                                            authority !== AUTHORITY_SUPPLY_CHAIN_MANAGER) || 
                                            ((formik.values['authorities'].includes(AUTHORITY_ADMIN) || formik.values['authorities'].includes(AUTHORITY_SITE_ADMIN)) && 
                                            authority == AUTHORITY_SUPPLY_CHAIN_MANAGER) ? 
                                            'none' : ''
                                        }}

                                    />)
                                })}
                            </Card>
                            {
                                formik.touched['authorities'] && Boolean(formik.errors['authorities']) &&
                                <FormHelperText>{t(formik.errors['authorities'], {field: t('keys.authorities')})}</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </Grid>
                <Grid item md={2}>
                    <Typography variant="h6" className="grouping-header">{t(`keys.countryCode`)}</Typography>
                    <div className="grouping-content">
                        <FormControl
                            className={classes.inputField}
                            error={formik.touched['countryCode'] && Boolean(formik.errors['countryCode'])}
                        >
                            <Card variant="outlined" className={`grouping-content ${formik.touched['countryCode'] && Boolean(formik.errors['countryCode']) && classes.cardDanger}`}>
                               <RadioGroup name="countryCode" value={formik.values['countryCode']}>
                                {USER_COUNTRY_CODES.map((countryCode, index) => {
                                        return (
                                      
                                            <FormControlLabel
                                                value={countryCode}
                                                control={<Radio />}
                                                key= {countryCode+ index}
                                                checked={countryCode === formik.values['countryCode']}
                                                label={t(`values.countryCode.${countryCode}`)}
                                                onChange={formik.handleChange}
                                            />
                                        )
                                    })}
                                
                               </RadioGroup>
                                
                            </Card>
                            {
                                formik.touched['countryCode'] && Boolean(formik.errors['countryCode']) &&
                                <FormHelperText>{t(formik.errors['countryCode'], {field: t('keys.countryCode')})}</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </Grid>
                 <Grid item md={2}>
                    <Typography variant="h6" className="grouping-header">{t(`keys.availableCountryCodes`)}</Typography>
                    <div className="grouping-content">
                        <FormControl
                            className={classes.inputField}
                            error={formik.touched['availableCountryCodes'] && Boolean(formik.errors['availableCountryCodes'])}
                        >
                            <Card variant="outlined" className={`grouping-content ${formik.touched['availableCountryCodes'] && Boolean(formik.errors['availableCountryCodes']) && classes.cardDanger}`}>
                                {USER_COUNTRY_CODES.map(countryCode => {
                                    return (<FormControlLabel
                                        className="MuiTypography-root MuiTypography-subtitle2"
                                        key={`authorities.${countryCode}`}
                                        control={
                                            <Checkbox
                                                checked={formik.values['availableCountryCodes'].includes(countryCode)}
                                                value={countryCode}
                                                onChange={formik.handleChange}
                                                name="availableCountryCodes"
                                                color="primary"
                                            />
                                        }
                                        label={t(`values.countryCode.${countryCode}`)}
                                    />)
                                })}
                            </Card>
                            {
                                formik.touched['availableCountryCodes'] && Boolean(formik.errors['availableCountryCodes']) &&
                                <FormHelperText>{t(formik.errors['availableCountryCodes'], {field: t('keys.availableCountryCodes')})}</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </Grid>
                <Grid item md={2}>
                    {updateUser && <><Typography variant="h6" className="grouping-header">{t(`keys.status`)}</Typography><div className="grouping-content"> 
                        <React.Fragment>
                            <FormControl
                                key="locked"
                                component="fieldset"
                                className={`${classes.inputField} ${classes.card}`}
                                error={formik.touched['locked'] && Boolean(formik.errors['locked'])}>
                                <Typography variant="subtitle2">{t('keys.locked')}</Typography>
                                <Card variant="outlined" className="grouping-content">
                                    <RadioGroup name="locked" value={formik.values['locked']}>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio />}
                                            checked={'true' === formik.values['locked'].toString()}
                                            label={t(`values.locked.true`)}
                                            onChange={formik.handleChange}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio />}
                                            checked={'false' === formik.values['locked'].toString()}
                                            label={t(`values.locked.false`)}
                                            onChange={formik.handleChange}
                                        />
                                    </RadioGroup>
                                </Card>
                                <FormHelperText>{formik.touched['locked'] && formik.errors['locked']}</FormHelperText>
                            </FormControl>
                            <FormControl
                                key="active"
                                component="fieldset"
                                className={`${classes.inputField} ${classes.card}`}
                                error={formik.touched['active'] && Boolean(formik.errors['active'])}
                            >
                                <Typography variant="subtitle2">{t('keys.active')}</Typography>
                                <Card variant="outlined" className="grouping-content">
                                    <RadioGroup name="active" value={formik.values['active']}>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio />}
                                            checked={'true' === formik.values['active'].toString()}
                                            label={t(`values.active.true`)}
                                            onChange={formik.handleChange}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio />}
                                            checked={'false' === formik.values['active'].toString()}
                                            label={t(`values.active.false`)}
                                            onChange={formik.handleChange}
                                        />
                                    </RadioGroup>
                                </Card>
                                <FormHelperText>{formik.touched['active'] && formik.errors['active']}</FormHelperText>
                            </FormControl>
                        </React.Fragment>
                        
                    </div></>}   
                </Grid>
                
            </Grid>

            <Grid container item md={12} spacing={5}>
                <Grid item md={3} style={{ marginLeft: '10px'}}> 
                        {
                            updateUser && <React.Fragment>
                                <Typography variant="h6" className="grouping-header">{t(`messages.additionalInformation`)}</Typography>
                                <div className="grouping-content">
                                    <Box mb={2}>
                                        <Typography variant="subtitle2">{t('keys.failedLoginAttempts')}</Typography>
                                        <p className="display-value">{readOnlyFields['failedLoginAttempts']}</p>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="subtitle2">{t('keys.lastLoginAt')}</Typography>
                                        <p className="display-value">{fullDate(readOnlyFields['lastLoginAt'], loggedInUser.countryCode)}</p>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="subtitle2">{t('keys.createdBy')}</Typography>
                                        <p className="display-value">{readOnlyFields['createdBy']}</p>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="subtitle2">{t('keys.createdAt')}</Typography>
                                        <p className="display-value">{fullDate(readOnlyFields['createdAt'], loggedInUser.countryCode)}</p>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="subtitle2">{t('keys.updatedAt')}</Typography>
                                        <p className="display-value">{fullDate(readOnlyFields['updatedAt'], loggedInUser.countryCode)}</p>
                                    </Box>

                                </div>
                            </React.Fragment>
                        }
                </Grid>

            </Grid>
            <div className="margin-top-10 text-right" style={{ marginRight: '10px'}}>
                <Button disabled={formik.isSubmitting} variant="contained" color="primary" type="submit">
                    {t('messages.submit')}
                </Button>
            </div>
        </form>

    )
}

export default UserForm;