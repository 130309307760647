import _ from "lodash";

export const convertToArticleGroupingMappingObject = (data, entity) => {
  var formSku = [];
  var uploadedSku = entity.map(function(item) {
    return item['sku'];
  })
  var resultObject = {
    articleGroupingList: [],
    articleGroupingRedundantList: []
  }
  const articleGroupingList = Object.entries(data).map(([skuKey, skuValue]) => {
    formSku.push(skuKey)
    const groupingObject = {
      sku: skuKey,
      likeForLike: {},
      likeForSimilar: {},
      likeForUpgrade: {},
    };
    Object.entries(skuValue).map(([countryKey, countryValue]) => {
      Object.entries(countryValue).map(([carrierKey, carrierValue]) => {
        if(!carrierValue.hasOwnProperty("likeForLike1")) {
          resultObject.articleGroupingRedundantList.push(`L4L value for SKU ${skuKey} is empty`)
        }
        Object.entries(carrierValue).map(([groupingType, groupingValue]) => {
          if (groupingType.includes("likeForLike")) {
            const countryCode = countryKey.slice(0, 2);
            groupingObject.likeForLike[countryCode] =
              groupingObject.likeForLike[countryCode] || {};
            groupingObject.likeForLike[countryCode][carrierKey] =
              groupingObject.likeForLike[countryCode][carrierKey] || [];
            if (groupingValue != null) {
              groupingObject.likeForLike[countryCode][carrierKey].push(
                groupingValue
              );
            }
          }
          if (groupingType.includes("likeForSimilar") && !groupingType.includes("ExistingValue")) {
            const countryCode = countryKey.slice(0, 2);
            groupingObject.likeForSimilar[countryCode] =
              groupingObject.likeForSimilar[countryCode] || {};
            groupingObject.likeForSimilar[countryCode][carrierKey] =
              groupingObject.likeForSimilar[countryCode][carrierKey] || [];
            if (groupingValue != null) {
              if(groupingObject.likeForSimilar[countryCode][carrierKey].includes(groupingValue)) {
                resultObject.articleGroupingRedundantList.push(`SKU ${skuKey} in ${countryKey} (${carrierKey}) has duplicated L4S values. Please remove duplicate values.`)
              } 
              groupingObject.likeForSimilar[countryCode][carrierKey].push(groupingValue); 
            }
          }
          if (groupingType.includes("likeForUpgrade") && !groupingType.includes("ExistingValue")) {
            const countryCode = countryKey.slice(0, 2);
            groupingObject.likeForUpgrade[countryCode] =
              groupingObject.likeForUpgrade[countryCode] || {};
            groupingObject.likeForUpgrade[countryCode][carrierKey] =
              groupingObject.likeForUpgrade[countryCode][carrierKey] || [];
            if (groupingValue != null) {
              if(groupingObject.likeForUpgrade[countryCode][carrierKey].includes(groupingValue)) {
                resultObject.articleGroupingRedundantList.push(`SKU ${skuKey} in ${countryKey} (${carrierKey}) has duplicated L4U values. Please remove duplicate values.`)
              } 
              groupingObject.likeForUpgrade[countryCode][carrierKey].push(groupingValue);
            }
          }
        });
      });
    });
    return groupingObject;
  });
  var missingSku = uploadedSku.filter((sku) => !formSku.includes(sku));
  missingSku.map((sku) => {
    resultObject.articleGroupingRedundantList.push(`L4U value for SKU ${sku} is empty`)
  })

  resultObject.articleGroupingList = articleGroupingList;
  return resultObject;
};

export const convertToArticleGroupingRecommendationRequest = (data) => {
  var skuList = [];
  var articleMappingObject = [];

  data.map(record => {
      if (skuList.includes(record.sku) === false) skuList.push(record.sku);
  })

  skuList.map(sku => {
      var articleGroupingRecommendationRequests = data.filter( data => data["sku"] === sku );

      articleGroupingRecommendationRequests.map(articleMapping => {
          var articleObjectMapping = articleMappingObject.filter( data => data["sku"] === sku )[0];
          var article = {};
          var existingArticleMapping = false;
          var countryCode = articleMapping["countryCode"].slice(0, 2);

          if(articleObjectMapping != null){
              existingArticleMapping = true;
              article = articleObjectMapping;
          } else {
              article["sku"] = articleMapping["sku"];
          }

          if(typeof article["carrierCountryCodeMapping"] === 'undefined') {
              article["carrierCountryCodeMapping"] = {};
          }
          if(typeof article["carrierCountryCodeMapping"][countryCode] === 'undefined') {
              article["carrierCountryCodeMapping"][countryCode] = [];
          }
          article["carrierCountryCodeMapping"][countryCode].push(articleMapping["carrierCode"]);

          if(existingArticleMapping === true) {
              var foundIndex = articleMappingObject.findIndex(x => x.sku === sku);
              articleMappingObject[foundIndex] = article;
          } else {
              articleMappingObject.push(article);
          }
         
      })
  })
  return articleMappingObject;
};

export const convertToArticleUpdateRecommendtionObject = (inputValue, checkedValue) => {
  const newObject = {};

  for (const [countryCode, countryValue] of Object.entries(checkedValue)) {
    newObject[countryCode] = {};
    
    for (const [carrierCode, carrierValue] of Object.entries(countryValue)) {
      newObject[countryCode][carrierCode] = {};

      for (const [descriptionKey, descriptionValue] of Object.entries(carrierValue)) {
        if (descriptionValue) {
            newObject[countryCode][carrierCode][descriptionKey] = 
            inputValue[countryCode][carrierCode][descriptionKey];
        }
      }

      if (Object.keys(newObject[countryCode][carrierCode]).length === 0) {
        delete newObject[countryCode][carrierCode];
      }
    }

    if (Object.keys(newObject[countryCode]).length === 0) {
      delete newObject[countryCode];
    }
  }

  return newObject;
}
