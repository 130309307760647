import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import {BrowserRouter, Route} from "react-router-dom";
import configureStore from "./store";
import 'font-awesome/css/font-awesome.min.css';
import './i18n';
import Main from "./pages/Main";

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Route path="/" component={Main} />
            </Suspense>
        </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);
