import React from 'react';
import {Checkbox } from "@material-ui/core";
export default function RecommendationCheckBox ({checked, onChange, countryCode, carrierCode, category, description, numberOfChanges}){
    const handleChange = (event) => {
        if(category === 'country') {
            onChange(countryCode, event.target.checked);
        }

        if(category === 'carrier') {
            onChange(countryCode, carrierCode, event.target.checked);
        }

        if(category === 'description') {
            onChange(countryCode, carrierCode, description, event.target.checked);
        }
    };
    return React.useMemo(() => {
        return (
                    <Checkbox
                        checked={
                           checked
                        }
                        onChange={handleChange}
                    
                    ></Checkbox>
        )
            
    }, [checked, numberOfChanges]);
}