import {
    SHOW_SEVERITY_UPLOAD_DIALOG,
    HIDE_SEVERITY_UPLOAD_DIALOG,
} from "../actions/types";

const INITIAL_STATE = {
    severityUploadDialog: {
        entity: null,
        open: false
    }
}

const severityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_SEVERITY_UPLOAD_DIALOG:
            return {
                ...state,
                severityUploadDialog: {
                    entity: action.payload.entity,
                    open: true
                }
            }
            case HIDE_SEVERITY_UPLOAD_DIALOG:
                return {
                    ...state,
                    severityUploadDialog: {
                        entity: null,
                        open: false,
                    }
                }
        default:
            return state;
    }
}

export default severityReducer;