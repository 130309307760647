import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../../components/PageTemplate";
import {
    createUser
} from "../../actions/userActions";
import UserForm from "../../components/Forms/User/UserForm";
import { validateAuthority } from "../../utils/utils";
import { AUTHORITY_ADMIN, AUTHORITY_SUPPLY_CHAIN_MANAGER } from "../../constant";

class UserCreate extends Component {
    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            this.props.history.push('/users');
        }
    }

    createUser = (user) => {
        this.props.createUser(user);
    }

    renderForm = () => {
        const user = {
            name: '',
            email: '',
            countryCode: '',
            availableCountryCodes: [],
            authorities: [],
            locked:false,
            active:false,
        }

        return (
        <UserForm editableFields={user}
                  loggedInUser={this.props.authentication.loggedInUser}
                  authorities={this.props.authentication.authorityList}
                  onFormSubmit={values => this.createUser(values)} />
        )
    }

    render() {
        return(
            <PageTemplate title="messages.userCreate">
                {this.renderForm()}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    createUser: createUser
})(UserCreate);