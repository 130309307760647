import React from 'react';
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const SectionTemplate = ({ title, children }) => {
    const { t } = useTranslation();

    return (
        <Box mt={2} mb={2}>
            <div className="padding-10">
                <Typography variant="h6">{t(`messages.${title}`)}</Typography>
                <Divider light />
                <Box mt={2}>
                    {children}
                </Box>
            </div>
        </Box>
    )
}

export default SectionTemplate;