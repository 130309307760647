import React, {Component} from "react";
import { connect } from 'react-redux';
import {
    batchCreateUser, clearUserFilters,
    getUserList, sortUsers,
    updateFiltersForUserList
} from '../../actions/userActions';
import DataTable from "../../components/DataTable";
import PageTemplate from "../../components/PageTemplate";
import {
    parseObjectToString,
    validateAcceptedFields,
    validateEmail,
    validatePhoneNumber,
    validateRequiredFields
} from "../../utils/utils";
import {showError} from "../../actions/commonActions";
import {fileUploadAcceptedFields, fileUploadRequiredFields} from "../../utils/fields";

class ArticleLocalizedList extends Component {
    componentDidMount() {
        this.props.getMerchantUserList('orderBy=createdAt&sort=desc');
        this.props.clearMerchantUserFilters();
        this.props.sortMerchantUsers('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let merchantFilterState = this.props.merchantUsers.merchantFilters[filterKey];
            merchantFilterState.includes(filterValue) ? merchantFilterState = merchantFilterState.filter(item => item !== filterValue) : merchantFilterState.push(filterValue);
            filterValue = merchantFilterState;
        }
        this.props.updateFiltersForUserMerchantList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearMerchantUserFilters();
        this.props.getMerchantUserList(this.props.merchantUsers.sorting);
    }

    handleSorting(orderBy, sort) {
        this.props.sortMerchantUsers(orderBy, sort)
        this.props.getMerchantUserList(parseObjectToString(this.props.merchantUsers.filters, {orderBy, sort}))
    }

    renderMerchantUsers(merchantUsers, showContentLoader) {
        const fields = [
            {
                name: 'email',
            },
            {
                name: 'displayName',
            },
            {
                name: 'businessEntityCode'
            },
            {
                name: 'locked',
                type: 'translatedString'
            },
            {
                name: 'active',
                type: 'translatedString'
            },
            {
                name: 'authorities',
                type: 'translatedArray',
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: 'email',
                type: 'textField'
            },
            {
                name: 'displayName',
                type: 'textField'
            },
            {
                name: 'businessEntityCode',
                type: 'textField'
            },
            {
                name: 'locked',
                type: 'checkBoxes',
                options: ['true', 'false']
            },
            {
                name: 'active',
                type: 'checkBoxes',
                options: ['true', 'false']
            },
            {
                name: 'authorities',
                type: 'checkBoxes',
                options: this.props.authentication.merchantProduct.authorities
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ];

        return(
            <DataTable fields={fields}
                       items={merchantUsers}
                       entity="globalArticles"
                       filterKeys={filterKeys}
                       filterValues={this.props.merchantUsers.merchantFilters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getMerchantUserList(parseObjectToString(this.props.merchantUsers.merchantFilters))}
                       sorting={this.props.merchantUsers.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    onUpload = (data) => {
        let validatedRecords = {
            valid: true,
            lineNumberWithErrors: []
        };
        data.forEach((datum, index) => {
            validatedRecords.valid = validateAcceptedFields(fileUploadAcceptedFields['merchantUser'], Object.keys(datum)) &&
                validateRequiredFields(fileUploadRequiredFields['merchantUser'], datum) &&
                validateEmail(datum.email) &&
                validatePhoneNumber(datum.phoneNumber);
            if (validatedRecords.valid) {
                datum.authorities = datum.authorities.split('|');
            } else {
                validatedRecords.lineNumberWithErrors.push(index + 2)
            }
        });
        if (validatedRecords.lineNumberWithErrors.length === 0) {
            this.props.batchCreateMerchantUser(data);
        } else {
            this.props.showError({response: {data: {translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.`}}});
        }
    }

    render() {

        return(
            <PageTemplate title="messages.articleList" downloadAction={"userNew"} uploadPath="articles/uploadLocalized" createPath="articles/createLocalized" filters={
                [
                    {
                        key: "Market",
                        type: "select",
                        values: [
                            "Hong Kong",
                            "Italy",
                            "Austria",
                            "Ireland",
                            "Korea",
                            "Japan",
                            "Taiwan",
                            "Philippenes",
                            "Thailand"
                        ]
                    },
                    {
                        key: "Filters",
                        type: "multi-select",
                        values: [
                            "Description",
                            "Imei"
                        ]
                    }
                ]
            }>
                {this.renderMerchantUsers(this.props.merchantUsers.merchantUsers, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    merchantUsers: state.users,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getMerchantUserList: getUserList,
    updateFiltersForUserMerchantList: updateFiltersForUserList,
    clearMerchantUserFilters: clearUserFilters,
    batchCreateMerchantUser: batchCreateUser,
    sortMerchantUsers: sortUsers,
    showError
})(ArticleLocalizedList);