import React, { Component } from "react";
import { connect } from 'react-redux';
import PageTemplate from "../../components/PageTemplate";
import {
    createUser
} from "../../actions/userActions";
import ArticleGlobalUploadForm from "../../components/Forms/ArticleGlobal/ArticleGlobalUploadForm.";

class ArticleLocalizedUpload extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            this.props.history.push('/users');
        }
    }

    createUser = (merchantUser) => {
        this.props.createMerchantUser(merchantUser);
    }

    renderForm = () => {
        const user = {
            displayName: '',
            email: '',
            secondaryEmail: '',
            phoneNumber: '',
            secondaryPhoneNumber: '',
            languageCode: '',
            authorities: [],
            businessEntityCode: ''
        }

        return (
            <ArticleGlobalUploadForm />
        )
    }

    render() {
        return (
            <PageTemplate>
                {this.renderForm()}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    common: state.common
})

export default connect(mapStateToProps, {
    createMerchantUser: createUser
})(ArticleLocalizedUpload);