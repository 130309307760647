import React, { useRef } from "react";
import _ from 'lodash';
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FilterDrawer from "../FilterDrawer";
import DataTableLoader from "../DataTableLoader";
import {withStyles} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ConfirmationDialogSimple from "../dialogs/ConfirmationDialogSimple";
import { convertToArticleUpdateRecommendtionObject } from "../../utils/CsvObjectModifier/ArticleLikeForGrouping/ArticleGroupingMapper";
import RecommendationCheckBox from "./RecommendationCheckBox";
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const customStyles = makeStyles({
    table: {
        width: '100%'

    },
    countryCell: {
        border: 0,
        whiteSpace: 'nowrap',

    },
    aiRecommendedTableCell: {
        color: 'blue'
    },
    keyArrowIcon : {
        width: '15px',
        height: '15px'
    },    
    flexDisplaySpaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    descriptionColorSampleBox: {
        width: "15px", 
        height: "15px", 
        fontSize: 'initial', 
        display: 'inline-block'
    }
});

const TableCell = withStyles({
    root: {
       fontSize: '12px',
      borderBottom: "none"
    }
  })(MuiTableCell);

const styles = {
    description: {
        padding: 10,
        paddingTop: 0,
        fontSize: 15,
        fontWeight: 500,
        marginBottom: 0,
    },
    main: {
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30
    },
    mainPaper: {
        padding: 0,
    },
    mainWrapper: {
        paddingTop: 0
    },

}

const ArticleLikeForRecommendationUpdatesTable = ({ fields, items, filterKeys, filterValues, onInputChange, onValueSubmit, onCancel, showContentLoader, onSelectedDataSubmit, onAllDataSubmit, articleLikeForUpdateSchedule}) => {
    const { t } = useTranslation();
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [filter] = React.useState(false);
    const classes = customStyles();
    const [expandedCountryCarrierGroups, setExpandedCountryCarrierGroups] = useState({});
    const inputs = useRef({});
    const [checked, setChecked] = useState({});
    const [numberOfModelChanges, setNumberOfModelChanges] = useState(0);

    const [confirmationDialog, setConfirmationDialog] = useState({
        entity: null,
        open: false,
        field: null,
        action: null,
        dynamicContent: null
    })

    useEffect(() => {
        populateInput()
    }, [items]);

    const populateInput = () => {
        var generateInputs = {};
        var checkedValue = {};

        Object.entries(items).map(([countryCode, countryValue]) => {
            generateInputs[countryCode] = {};
            checkedValue[countryCode] ={};

            Object.entries(items[countryCode]).map(([carrierCode, carrierValue]) => {
                generateInputs[countryCode][carrierCode] = {};
                checkedValue[countryCode][carrierCode] = {};

                carrierValue.map((data) => {
                    generateInputs[countryCode][carrierCode][data.description] = {};
                    var recommendationData = data.recommendation;
                    delete recommendationData.option;
                    generateInputs[countryCode][carrierCode][data.description] = recommendationData;
                    checkedValue[countryCode][carrierCode][data.description] = false;
                })
            })
        })
        inputs.current  = generateInputs;
        setChecked(checkedValue)
    }

    const handleValueCheckBoxChange = (countryCode, carrierCode, description, isValueChecked) => {

        if(checked[countryCode]?.[carrierCode]?.[description] != null) {

            if(checked[countryCode][carrierCode][description] !== isValueChecked && isValueChecked) {
                setNumberOfModelChanges(prevNumberOfModelChanges => ++prevNumberOfModelChanges );
            } 
            if(checked[countryCode][carrierCode][description] !== isValueChecked && !isValueChecked) {
                setNumberOfModelChanges(prevNumberOfModelChanges => --prevNumberOfModelChanges );
            }

            setChecked(prevChecked => ({
                ...prevChecked,
                [countryCode]: {
                  ...prevChecked[countryCode],
                  [carrierCode]:  {
                      ...prevChecked[countryCode]?.[carrierCode],
                      [description]: isValueChecked
                  }
                }
              }));
        }

    }

    const handleCarrierValueCheckBoxChange =  (countryCode, carrierCode, isValueChecked) => {
        if(checked[countryCode]?.[carrierCode] != null) {
            Object.entries(checked[countryCode][carrierCode]).map(([descriptionKey, descriptionValue]) => {
                handleValueCheckBoxChange(countryCode, carrierCode, descriptionKey, isValueChecked);
            })

        }
       
    }

    const handleCountryValueCheckBoxChange =  (countryCode,isValueChecked) => {
        if(checked[countryCode] != null) {
            Object.entries(checked[countryCode])?.map(([carrierCode, carrierCodeValue]) => {
                Object.entries(checked[countryCode][carrierCode]).map(([descriptionKey, descriptionValue]) => {
                    handleValueCheckBoxChange(countryCode, carrierCode, descriptionKey, isValueChecked);
                })
            })

        }
       
    }

    const handleCountryCarrierGroupCollapse = (countryCarrierGroup, manualFlag) => {
        setExpandedCountryCarrierGroups(prevExpanded => ({
          ...prevExpanded,
          [countryCarrierGroup]: manualFlag
        }));
    };

    const updateSelectedArticleLikeForSimilarRecommendations = () => {
        setConfirmationDialog(prevState => ({
            ...prevState,
            entity: 'articleLikeFor',
            open: true,
            field: 'likeForSimilar',
            action: 'updateSelected',
            dynamicContent:  {'[numberOfChanges]': numberOfModelChanges}
          }));

    }

    const updateAllArticleLikeForSimilarRecommendations = () => {
        Object.entries(items).map(([countryCode, countryValue]) => {
            handleCountryValueCheckBoxChange(countryCode, true)
        })
        setConfirmationDialog(prevState => ({
            ...prevState,
            entity: 'articleLikeFor',
            open: true,
            field: 'likeForSimilar',
            action: 'updateAll',
            dynamicContent: null
        }));

    }

    const onCloseDialog = () => {
        setConfirmationDialog(prevState => ({
            ...prevState,
            entity: null,
            open: false,
            field: null,
            action: null,
            dynamicContent:  null
        }));

    }
    const onAllUpdateConfirm = () => {
        setConfirmationDialog( {
            entity: null,
            open: false,
            field: null,
            action: null,
            dynamicContent:  null
        })
        onAllDataSubmit(convertToArticleUpdateRecommendtionObject(inputs.current, checked));
    }

    const onManualUpdateConfirm = () => {
        setConfirmationDialog( {
            entity: null,
            open: false,
            field: null,
            action: null,
            dynamicContent:  null
        })
        onSelectedDataSubmit(convertToArticleUpdateRecommendtionObject(inputs.current, checked));
    }

    const determineL4SColorCode = (inventoryType, isRecommendationLatest) => {
        switch (inventoryType) {
            case "new":
              return "red"
            case "refurbished":
                return "green"
            case "preOwned":
               return "orange";
            case null:
                return isRecommendationLatest ? "blue" : "";
            default:
                return "";
          }
    }

    return (
        <>
            <ConfirmationDialogSimple
                        entity={confirmationDialog.entity}
                        field={confirmationDialog.field}
                        open={confirmationDialog.open}
                        action={confirmationDialog.action}
                        dynamicContent={confirmationDialog.dynamicContent}
                        onReject={() => onCloseDialog()}
                        onAccept={ confirmationDialog?.action == 'updateSelected' ? onManualUpdateConfirm : onAllUpdateConfirm}
            />
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ paddingLeft: 30, paddingTop: 30 }}>{"L4S Recommendation Updates"}</h3>
                    <div style={{
                        paddingTop: 30,
                        paddingLeft: 30,
                        paddingRight: 30,
                        display: "flex",
                        justifyContent: "flex-end"
                    }} >
                        <Button size="small" variant="contained" color="secondary" style={{
                            background: "#E3D900",
                            padding: "16px",
                            marginRight: "16px"
                        }}
                            onClick={ updateAllArticleLikeForSimilarRecommendations}
                        >
                            {"Update All"}
                        </Button>
                        <Button size="small" variant="contained" color="secondary" disabled={numberOfModelChanges == 0} style={{
                            background: "#E3D900",
                            padding: "16px"
                        }}
                            onClick={updateSelectedArticleLikeForSimilarRecommendations}
                        >
                            {`Update (${numberOfModelChanges})`}
                        </Button>
                        
                    </div>
                </Box>


                <div style={styles.main}>
                <Box>
                    <div>
                        <p style={styles.description}>{articleLikeForUpdateSchedule ? `Last Updated: ${format(utcToZonedTime(parseISO(articleLikeForUpdateSchedule), 'Asia/Hong_Kong'), "MMM d, ha")} HKT` : ''}</p>
                        <p style={styles.description}>
                            <span>{`${t('messages.articleLikeFor.recommendationUpdate.InventoryIndicatorDescription')} `}</span>
                            <span><b>{`Empty inventory: `}</b></span>
                            <div className={classes.descriptionColorSampleBox} style={{backgroundColor: "blue"}}></div>
                            <span><b>{` | New inventory: `}</b></span>
                            <div className={classes.descriptionColorSampleBox} style={{backgroundColor: "red"}}></div>
                            <span><b>{` | Refurbished inventory: `}</b></span>
                            <div className={classes.descriptionColorSampleBox} style={{backgroundColor: "green"}}></div>
                            <span><b>{` | Preowned inventory: `}</b></span>
                            <div className={classes.descriptionColorSampleBox} style={{backgroundColor: "orange"}}></div>
                        </p>
                    </div>
                </Box>
                <Divider />
                <Divider />
                <Box className={classes.flexDisplaySpaceBetween}>
                </Box>

                <Paper style={styles.mainPaper}>
                    <Box style={styles.mainWrapper}>
                    <div style={{overflow: 'auto', maxWidth: '100'}}>
                <Table size="small"  className={classes.table}>
                    <TableBody>
                        {
                            filterOpen &&
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <FilterDrawer
                                        filterKeys={filterKeys}
                                        filterValues={filterValues}
                                        onInputChange={(filterKey, filterValue, multipleSelection) => onInputChange(filterKey, filterValue.target.value, multipleSelection)}
                                        onValueSubmit={() => onValueSubmit()}
                                        onCancel={() => onCancel()}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                        {showContentLoader ? <TableRow>
                            <TableCell colSpan={8}>
                                <DataTableLoader />
                            </TableCell>
                        </TableRow> : items != null && Object.keys(items).length !== 0 ? (
                            <>
                                {
                                    Object.entries(items).map(
                                        ([countryCode, countryCodeValue]) => {

                                        return (
                                            <>
                                                {   items != null && !_.isEmpty(items) &&
                                                    Object.entries(items[countryCode]).map(
                                                        ([carrierCode, carrierValue], carrierIndex) => {

                                                        return (
                                                            <>
                                                            <TableRow>
                                                                <TableCell >
                                                                    <RecommendationCheckBox
                                                                        checked={
                                                                            checked[countryCode]?.[carrierCode] != null ? 
                                                                            Object.values(checked[countryCode]?.[carrierCode])?.some(value => value === true) :
                                                                            false

                                                                        }
                                                                        countryCode={countryCode}
                                                                        carrierCode={carrierCode}
                                                                        category={'carrier'}
                                                                        onChange={(countryCode, carrierCode,isChecked ) => handleCarrierValueCheckBoxChange(countryCode, carrierCode, isChecked)}
                                                                        numberOfChanges={numberOfModelChanges}                                                                >

                                                                    </RecommendationCheckBox>
                                                                    <strong>{`${countryCode} ${carrierCode}`}</strong>
                                                                    {
                                                                        expandedCountryCarrierGroups[countryCode + carrierCode] ? 
                                                                        <a href="#"><KeyboardArrowUp className={classes.keyArrowIcon} onClick={() => handleCountryCarrierGroupCollapse(countryCode + carrierCode, false) }></KeyboardArrowUp></a> :
                                                                        <a href="#"><KeyboardArrowDown className={classes.keyArrowIcon}onClick={() => handleCountryCarrierGroupCollapse(countryCode + carrierCode, true) }></KeyboardArrowDown></a>
                                                                    } 
                                                                </TableCell>
                                                                
                                                            </TableRow>

                                                            <TableRow style={{ display: expandedCountryCarrierGroups[countryCode + carrierCode] ? '' : 'none'}}>
                                                                    <TableCell align={'center'}> <strong>Cover Model</strong></TableCell>
                                                                    <TableCell align={'center'}><strong>Current L4S option 1</strong></TableCell>
                                                                    <TableCell align={'center'}><strong>Current L4S option 2</strong></TableCell>
                                                                    <TableCell align={'center'}><strong>Current L4S option 3</strong></TableCell>
                                                                    <TableCell align={'center'}><strong>Recommended L4S option 1</strong></TableCell>
                                                                    <TableCell align={'center'}><strong>Recommended L4S option 2</strong></TableCell>
                                                                    <TableCell align={'center'}><strong>Recommended L4S option 3</strong></TableCell>
                                                            </TableRow>
                                                        {
                                                            
                                                            carrierValue.map((recommendation) => {
                                                                return (
                                                                   
                                                                    <TableRow style={{ display: expandedCountryCarrierGroups[countryCode + carrierCode] ? '' : 'none'}}>
                                                                        <TableCell style={{whiteSpace:'nowrap'}}>
                                                                            <RecommendationCheckBox
                                                                                checked={checked[countryCode]?.[carrierCode]?.[recommendation.description] ? checked[countryCode][carrierCode][recommendation.description] : false}
                                                                                countryCode={countryCode}
                                                                                carrierCode={carrierCode}
                                                                                description={recommendation.description}
                                                                                category={'description'}
                                                                                onChange={(countryCode, carrierCode, description, isChecked ) => handleValueCheckBoxChange(countryCode, carrierCode, description, isChecked)}
                                                                                numberOfChanges={numberOfModelChanges}                                                                        >

                                                                            </RecommendationCheckBox>
                                                                                <strong align={'center'}>
                                                                                    {recommendation.description}
                                                                                </strong>
                                                                        </TableCell>
                                                                        <TableCell align={'center'} >{recommendation.current?.optionOne}</TableCell>
                                                                        <TableCell align={'center'}>{recommendation.current?.optionTwo}</TableCell>
                                                                        <TableCell align={'center'}>{recommendation.current?.optionThree}</TableCell>
                                                                        <TableCell align={'center'} style={{color: determineL4SColorCode(recommendation.recommendation?.optionOneInventory, recommendation.current?.optionOne !== recommendation.recommendation?.optionOne)}}>{recommendation.recommendation?.optionOne}</TableCell>
                                                                        <TableCell align={'center'} style={{color: determineL4SColorCode(recommendation.recommendation?.optionTwoInventory, recommendation.current?.optionTwo !== recommendation.recommendation?.optionTwo)}}>{recommendation.recommendation?.optionTwo}</TableCell>
                                                                        <TableCell align={'center'} style={{color: determineL4SColorCode(recommendation.recommendation?.optionThreeInventory, recommendation.current?.optionThree !== recommendation.recommendation?.optionThree)}}>{recommendation.recommendation?.optionThree}</TableCell>
                                                                    </TableRow>
                                                                )

                                                                
                                                            })
                                                        }
                                                    </>

                                                        )   
                                                        }
                                                    )

                                                }
                                            
                                            </>
                                        );
                                        }
                                    )
                                }

                            </>
                            
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <p style={{ textAlign: "center" }}> {t('messages.articleLikeFor.listPageBlank')}</p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
                    </Box>
                </Paper>



            </div>
        </>
    )

}

export default ArticleLikeForRecommendationUpdatesTable;