import React, {Component} from "react";
import { connect } from 'react-redux';
import ArticleUploadAccordion from "../../components/Article/ArticleUploadAccordion";
import ArticlePreviewAccordion  from "../../components/Article/ArticlePreviewAccordion";
import {hideLocalizeArticleUploadAccordion, getArticleCSVTemplate, batchCreateArticle, uploadArticleLocalizationCSV, batchLocalizeArticle  } from "../../actions/articleAction";
import { showDownloadDialog, hideDownloadDialog } from "../../actions/commonActions";
import { validateAcceptedFields, validateRequiredFields, validateAuthority } from "../../utils/utils";
import { fileUploadAcceptedFields, fileUploadRequiredFields } from "../../utils/fields";
import { showError } from "../../actions/commonActions";
import TemplateDownloadDialog from "../../components/dialogs/TemplateDownloadDialog";
import { USER_COUNTRY_CODES, AUTHORITY_ADMIN } from "../../constant";
import ArticleLocalizeUploadPreviewTable from "../../components/Article/ArticleLocalizeUploadPreviewTable";
import { convertToLocalisedArticleObject } from "../../utils/CsvObjectModifier/ArticleGlobal/ArticleGlobalMapper";

class ArticleLocalizedCreate extends Component {

    componentDidMount() {
        this.props.hideLocalizeArticleUploadAccordion();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            this.props.history.push('/articles/global');
        }
    }

    validateEntity = (data) => {
        let validatedRecords = {
            valid: true,
            lineNumberWithErrors: [],
            recordLimitExceeded: false,
            emptyRecord: false
        };
        data.forEach((datum, index) => {
            validatedRecords.valid = validateAcceptedFields(fileUploadAcceptedFields['articleGlobal'], Object.keys(datum)) &&
                validateRequiredFields(fileUploadRequiredFields['articleGlobal'], datum);
            if (validatedRecords.valid == false) {
                validatedRecords.lineNumberWithErrors.push(index + 3)
            }
        });

        if(data.length <= 0 ) {
            validatedRecords.emptyRecord = true;
        }

        if (data.length > 5000) {
            validatedRecords.recordLimitExceeded = true;
        }

        if (validatedRecords.lineNumberWithErrors.length === 0 && validatedRecords.recordLimitExceeded == false && validatedRecords.emptyRecord == false) {
            return true
        } else {
            
            if (validatedRecords.emptyRecord == true) {
                this.props.showError({ response: { data: { translationKey: `0 record uploaded. Please fix and try again.` } } });
                return false;
            } 
            
            if (validatedRecords.recordLimitExceeded == true) {
                this.props.showError({ response: { data: { translationKey: `Exceeded 5000 limit of records with number of ${data.length} uploaded records. Please fix and try again.` } } });
                return false;
            }

            if (validatedRecords.lineNumberWithErrors.length > 0){
                this.props.showError({ response: { data: { translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.` } } });
                return false;
            }
        }
    }

    previewUpload = (file, operationType) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.props.uploadArticleLocalizationCSV({"uploadType": operationType ,"data": reader.result?.split(',')[1]})
        } 

    }

    onSubmit = (data) => {
        let convertedData = convertToLocalisedArticleObject(data, null, null);
        this.props.batchLocalizeArticle(convertedData)
        this.props.hideLocalizeArticleUploadAccordion();
    }

    handleServerSideArticleCSVTemplateDownload = (data) => {
        var isAdmin  = validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities);
        this.props.getArticleCSVTemplate(data, isAdmin);
    }

    onDownloadDialogOpen = () => {
        this.props.showDownloadDialog();
    }

    onDownloadDialogClose = () => {
        this.props.hideDownloadDialog();
    }

    renderUploadAccordion = () => {
        const styles = {
            main: {
                paddingTop: 30,
                paddingLeft: 30,
                paddingRight: 30,
            }
        }

        const forms =[{
                "id": "operationType",
                "label": "Please select a localisation type",
                "type": "checkBox",
                "options": [{"label": "messages.articleGlobal.localise.create","value": "create"}, {"label": "messages.articleGlobal.localise.update","value": "update"}],
                "defaultValue": "create"
            }
        ]; 
        return (
            <div style={styles.main}>
                <ArticleUploadAccordion
                    processCSVFile={(file, operationType) => this.previewUpload(file, operationType)}
                    templateFile={null}
                    open={this.props.articles.articleLocalizeUploadAccordion.uploadAccordion}
                    forms={forms}
                    multiTemplateDownload={true}
                    showDownloadTemplateDialog={() => this.onDownloadDialogOpen()}
                ></ArticleUploadAccordion>
            </div>
        )

    }

    renderPreviewAccordion = () => {
        const styles = {
            main: {
                paddingTop: 30,
                paddingLeft: 30,
                paddingRight: 30,
            }
        }

        return (
            <div style={styles.main}>
                <ArticlePreviewAccordion
                    open={this.props.articles.articleLocalizeUploadAccordion.previewAccordion}
                    entitySize={this.props.articles.articleLocalizeUploadAccordion.entity ? this.props.articles.articleLocalizeUploadAccordion.entity.numberOfRecordsProcessed : null}
                    entity={this.props.articles.articleLocalizeUploadAccordion.entity}
                    onSubmit={(data) => this.onSubmit(data)}
                >
                    <ArticleLocalizeUploadPreviewTable
                        entity={this.props.articles.articleLocalizeUploadAccordion.entity}
                    >
                    </ArticleLocalizeUploadPreviewTable>
                </ArticlePreviewAccordion>
            </div>
        )

    }

    renderDownloadTemplateDialog = () => {
        return (
            <TemplateDownloadDialog
                open={this.props.common.downloadDialog.open}
                countryCodes={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities) ? USER_COUNTRY_CODES : this.props.authentication.loggedInUser.availableCountryCodes} 
                onSubmit={(data) => this.handleServerSideArticleCSVTemplateDownload(data)}
                onReject={() => this.onDownloadDialogClose()}
            ></TemplateDownloadDialog>
        )
    }

    render() {
        
        return(
            <>
                {
                    this.renderUploadAccordion()
                }

                {
                    this.renderPreviewAccordion()
                }
                {
                    this.renderDownloadTemplateDialog()
                }
            </>
        )
    }

}

const mapStateToProps = state => ({
    articles: state.articles,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    hideLocalizeArticleUploadAccordion: hideLocalizeArticleUploadAccordion,
    batchCreateArticle: batchCreateArticle,
    getArticleCSVTemplate: getArticleCSVTemplate,
    uploadArticleLocalizationCSV: uploadArticleLocalizationCSV,
    batchLocalizeArticle : batchLocalizeArticle,
    showDownloadDialog: showDownloadDialog,
    hideDownloadDialog: hideDownloadDialog,
    showError: showError
})(ArticleLocalizedCreate);