import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../../components/PageTemplate";
import * as yup from "yup";
import { createArticleExclusion, removeArticleExclusion, getArticleExclusionOptions } from "../../actions/articleExclusionAction";
import ArticleExclusionForm from "../../components/Forms/ArticleExclusion/ArticleExclusionForm";
import { convertToArticleExclusionMappingObject } from "../../utils/CsvObjectModifier/ArticleExclusion/ArticleExclusionMapper";
import { validateAuthority } from "../../utils/utils";
import { AUTHORITY_ADMIN } from "../../constant";

class ArticleExclusionCreate extends Component {
    componentDidMount() {
        this.props.getArticleExclusionOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            this.props.history.push('/articles/exclusion');
        }
    }

    createArticleExclusion = (exclusionList) => {
        this.sanitiseData(exclusionList)
        var pathName = this.props.location.pathname
        if(pathName.endsWith("exclusionCreate")) {
            this.props.createArticleExclusion(convertToArticleExclusionMappingObject(exclusionList));

        } else {
            this.props.removeArticleExclusion(convertToArticleExclusionMappingObject(exclusionList));
        }

    }

    sanitiseData = (data) => {
        let sanitiseExclusionList =data.exclusionList.filter(element => element);
        data.exclusionList = sanitiseExclusionList;   
    }
    renderForm = (options) => {
        const editableFields = {
            exclusionList: [],
            countryCodes: [],
            carrierCodes: []
        }

        const validationRules = yup.object({
            exclusionList: yup.array(yup.string().required()).min(1, 'messages.fieldRequired'),
            countryCodes: yup.array().min(1, 'messages.fieldRequired'),
            carrierCodes: yup.array().min(1, 'messages.fieldRequired'),
        });

            return (
            <ArticleExclusionForm editableFields={editableFields}
                                validationRules={validationRules}
                                onFormSubmit={values => this.createArticleExclusion(values)}
                                loggedInUser={this.props.authentication.loggedInUser}
                                isAdmin={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities)}
                                userCountryCodes={this.props.authentication.loggedInUser.availableCountryCodes}
                                options={options}
            />
        )
    }

    render() {
        return(
            <PageTemplate title={this.props.location.pathname.endsWith("exclusionCreate") ? "messages.exclusionListCreate" : "messages.exclusionListRemove"}>
                {this.renderForm(this.props.articleExclusions.articleExclusionOptions)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    articleExclusions: state.articleExclusions,
    common: state.common
})

export default connect(mapStateToProps, {
    createArticleExclusion,
    removeArticleExclusion,
    getArticleExclusionOptions
})(ArticleExclusionCreate);