import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Divider, Table, TableBody, TableRow, withStyles, TableContainer } from "@material-ui/core";
import MuiTableCell from '@material-ui/core/TableCell';
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {shortDate} from "../../../utils/utils";
import makeStyles from "@material-ui/core/styles/makeStyles";

const AccessoryMappingUploadDialog = ({entity, open, onReject, onAccept}) => {
    const { t } = useTranslation();
    const [disabled, setDisabled] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
        return;
        onReject();
    };

    const handleAccept = () => {
        setDisabled(true);
        onAccept(entity);
        window.setTimeout(() => {setDisabled(false);}, 2000 )
    }

    const TableCell = withStyles({
    })(MuiTableCell);

    const customStyles = makeStyles({
        sticky: {
            position: "sticky",
            left: 0,
            background: "white",
          },
          headers: {
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 2
        }
      });
    const customStyleClass = customStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
            transitionDuration={{
                enter: 1,
                exit: 1
            }}
            fullWidth={true}
            maxWidth="md"
        >
            <div/>
            <DialogContent>
                <DialogContentText>
                <div>
            <div className="padding-10">
                <Grid container item md={12} spacing={5} >
                    <Grid item md={12}>
                        <Typography variant="h6" color="primary">{t('messages.previewAccessory')}</Typography>
                        <div style={{ padding: "10px 0" }}>
                            <Typography variant="body1" className="grouping-header">{t('messages.previewAccessoryDescription')}</Typography>
                            <Typography style={{float: "right"}} variant="h7" color="primary">{`${entity != null ? Object.keys(entity).length : 0} selected`}</Typography>
                        </div>
                        <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                        <div>
                            <TableContainer style={{ maxHeight: 300, margin: "20px"}}>
                                <Table>
                                    <TableBody>
                                        <TableRow className={customStyleClass.headers}>
                                            <TableCell className={customStyleClass.sticky}><b>SKU</b></TableCell>
                                            <TableCell><b>Region</b></TableCell>
                                            <TableCell><b>Partner</b></TableCell>
                                            <TableCell><b>Accessory 1</b></TableCell>
                                            <TableCell><b>Accessory 2</b></TableCell>
                                            <TableCell><b>Accessory 3</b></TableCell>
                                            <TableCell><b>Accessory 4</b></TableCell>
                                        </TableRow>
                                        {
                                            entity != null ? (
                                                entity.map((accesoryMapping, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell className={customStyleClass.sticky}> {`SKU ${accesoryMapping.sku}`}</TableCell>
                                                        <TableCell> {accesoryMapping.countryCode}</TableCell>
                                                        <TableCell> {accesoryMapping?.carrierCode}</TableCell>
                                                        <TableCell> {accesoryMapping?.accessory["1"]}</TableCell>
                                                        <TableCell> {accesoryMapping?.accessory["2"]}</TableCell>
                                                        <TableCell> {accesoryMapping?.accessory["3"]}</TableCell>
                                                        <TableCell> {accesoryMapping?.accessory["4"]}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (<TableRow></TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                    </Grid>
                </Grid>
                <div style={{
                    display: "flex",
                    width: "100%",
                    gap: "10px",
                    justifyContent: "flex-end"
                }}><Button onClick={handleClose} variant="outlined" style={{
                    borderColor: "black"
                }}>
                        {"Cancel"}
                    </Button>
                    <Button onClick={handleAccept} size="small" variant="contained" color="secondary" style={{
                        background: "#E3D900",
                        padding: "16px"
                    }} autoFocus>
                        {"Upload"}
                    </Button>
                </div>
            </div>
        </div >
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default AccessoryMappingUploadDialog;