import React from 'react';
import Papa from "papaparse";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import GetAppIcon from '@material-ui/icons/GetApp';
import { trimData } from "../utils/utils";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

const customStyles = makeStyles({
    fileUpload: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10
    }
})

const FileUpload = ({ defaultFields, templateFile, onFileChosen, onUpload, changeHeader, addAdditionAttributes, sliceCsv }) => {
    const [file, setFile] = React.useState(undefined);
    const { t } = useTranslation();
    const classes = customStyles();

    const handleChange = event => {
        setFile(event.target.files[0])
    };

    const importCSV = () => {
        Papa.parse(file, {
            complete: updateData,
            header: true,
            transformHeader:function(header) {
                if(changeHeader != null) {
                    Object.entries(changeHeader).map(([key, value]) => {
                        if(key === header){
                            header = value;
                        } 
                    })
            }
                return header;
            }
        });
    };

    const updateData = (result) => {
        const data = sliceCsv != null ? result.data.slice(sliceCsv) : result.data;
        trimData(data);
        // const updatedData = data.map(obj => ({ ...obj, ...defaultFields }));
        var convertedToDimensional = data.map(obj => convertToDimensional(obj));
        if(addAdditionAttributes != null){
            convertedToDimensional = addAdditionAttributes(convertedToDimensional);
        }
        setFile(undefined)
        document.getElementById('fileUpload').value = '';
        onUpload(convertedToDimensional);
    }

    const convertToDimensional = (data) => {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (key.indexOf(".") !== -1) {
                    parseDotNotation(key, data[key], data);
                }
            }
        }
        return data;
    }

    const parseDotNotation = (originalKey, val, obj) => {
        var currentObj = obj,
            keys = originalKey.split("."),
            i, l = Math.max(1, keys.length - 1), key;

        for (i = 0; i < l; ++i) {
            key = keys[i];
            currentObj[key] = currentObj[key] || {};
            currentObj = currentObj[key];
        }

        currentObj[keys[i]] = val;
        delete obj[originalKey];
    }

    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return (
        <span>
            <Button for="fileUpload" size="small" variant="contained" style={{ background: "white", padding: "16px" }} onClick={handleClick} className="margin-left-10">
                {t('messages.upload')}
                <FormatListBulletedIcon style={{ "margin-left": "8px" }} />
            </Button>
            <input
                id="fileUpload"
                type="file"
                name="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                seleniumselector="fileUpload"
                accept=".csv"
                style={{ display: "none"}}
            />
            <a href={`${process.env.PUBLIC_URL}/assets/resources/${templateFile}`} target='_blank' rel="noreferrer" download title={t('messages.downloadTemplateFile')} className="float-right"><GetAppIcon /></a>
            <Button size="small" disabled={!file} onClick={importCSV} variant="contained" color="primary" seleniumselector="fileSubmit">{t('messages.upload')}</Button>
        </span>
    )
}

export default FileUpload;