import {
    ARTICLE_EXCLUSION_FILTER,
    ARTICLE_EXCLUSION_GET_LIST,
    ARTICLE_EXCLUSION_CREATE, ARTICLE_EXCLUSION_CLEAR_FILTER, ARTICLE_EXCLUSION_SORT, ARTICLE_EXCLUSION_GET_OPTIONS
} from "../actions/types";

const INITIAL_STATE = {
    articleExclusions: [],
    articleExclusionOptions: [],
    articleExclusion: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    articleExclusionFilters: {
        email: '',
        name: '',
        businessEntityCode: '',
        locked: [],
        active: [],
        countryCode: [],
        authorities: [],
        createdAt: {
            from: null,
            to: null
        }
    },
}

const articleExclusionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ARTICLE_EXCLUSION_GET_LIST:
            return {
                ...state,
                articleExclusions: action.payload.data
            }
        case ARTICLE_EXCLUSION_GET_OPTIONS:
            return {
                ...state,
                articleExclusionOptions: action.payload.data
            }
        case ARTICLE_EXCLUSION_FILTER:
            return {
                ...state,
                articleExclusionFilters: {
                    ...state.articleExclusionFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case ARTICLE_EXCLUSION_CLEAR_FILTER:
            return {
                ...state,
                articleExclusionFilters: {
                    email: '',
                    name: '',
                    businessEntityCode: '',
                    locked: [],
                    active: [],
                    countryCode: [],
                    authorities: [],
                    createdAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case ARTICLE_EXCLUSION_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case ARTICLE_EXCLUSION_CREATE:
            return {
                ...state
            }
        default:
            return state;
    }
}

export default articleExclusionReducer;