import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {TableContainer, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { LOCALISED_ARTICLES_DIALOG_TABLE_HEADERS, LOCALISED_ARTICLES_DIALOG_TABLE_VALUES } from '../../utils/CsvObjectModifier/CsvObjectFormat';
import _ from 'lodash';
import { shortDate } from '../../utils/utils';
import { render } from '@testing-library/react';

const customStyles = makeStyles({
    fileUpload: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
    },
    headers: {
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: 2
    }
})

const ArticleLocalizeUploadPreviewTable = ({entity}) => {
    const { t } = useTranslation();
    const customStyleClass = customStyles();
    console.log("entity" + JSON.stringify(entity))

    const mergeCountryCodes = (newSkuUpdatedByCountryCode) => {
        var skuCountryCodes = [];
        newSkuUpdatedByCountryCode?.map(newSkuCountryCode => {
            skuCountryCodes = skuCountryCodes.concat(newSkuCountryCode.countryCodes)
        })
        skuCountryCodes  = Array.from(new Set(skuCountryCodes));
        return skuCountryCodes;
    }
    const combinedCountryCodes = mergeCountryCodes(entity?.newSkuUpdatedByCountryCode);

    const renderCell = (record, fieldKey, t) => {
        switch (fieldKey.type) {
            case 'shortDate':
                return shortDate(_.get(record, fieldKey.key), null);
            default:
                return record;
        }
    }
      
    return (
        <TableContainer style={{ maxHeight: 300}}>
            <Table aria-label="a dense table">
                <TableBody>
                    <TableRow className={customStyleClass.headers}>
                        <TableCell className={customStyleClass.sticky}><b>{`article.sku`}</b></TableCell>
                        <TableCell><b>{`article.description`}</b></TableCell>
                        {
                            combinedCountryCodes.map((countryCode, skuCountryIndex) => {
                            return (
                                LOCALISED_ARTICLES_DIALOG_TABLE_HEADERS[countryCode]?.map((header, headerIndex) =>{
                                    return(
                                        <TableCell key={`${skuCountryIndex}-${headerIndex}-${header}`}><b>{header}</b></TableCell>
                                    )
                                    
                                })
                            )
                            
                        })
                        }
                    </TableRow>
                        {
                            <>
                                    {
                                    entity?.newUpdatedArticles.map((record, index) => (
                                    <TableRow>
                                        <TableCell key={`${index}-sku`} className={customStyleClass.sticky}> { _.get(record, "article.sku")?.toString()}</TableCell>
                                        <TableCell key={`${index}-description`}> { _.get(record, "article.description")?.toString()}</TableCell>
                                            {
                                                combinedCountryCodes.map((countryCode, countryIndex) => {
                                                    return(
                                                        LOCALISED_ARTICLES_DIALOG_TABLE_VALUES[countryCode]?.map((fieldKey, headerIndex) =>{
                                                            return(
                                                                <TableCell key={`${headerIndex}`}> { typeof fieldKey === 'object' ? renderCell(record, fieldKey, t) : _.get(record, fieldKey)?.toString()}</TableCell>
                                                            )
                                                            
                                                        })
                                                    )
                                                })
                                            }
                                    </TableRow>
                                    ))
                                }
                            </>
                        }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ArticleLocalizeUploadPreviewTable;