import React, {Component} from "react";
import { connect } from 'react-redux';
import { clearArticleExclusionsFilters, getArticleExclusionList, sortArticleExclusions, updateFiltersForArticleExclusionsList } from "../../actions/articleExclusionAction";
import DataTable from "../../components/DataTable";
import PageTemplate from "../../components/PageTemplate";
import { parseObjectToString } from "../../utils/utils";
import {showError} from "../../actions/commonActions";

class ArticleExclusionList extends Component {
    componentDidMount() {
        this.props.getArticleExclusionList('orderBy=createdAt&sort=desc');
        this.props.clearArticleExclusionsFilters();
        this.props.sortArticleExclusions('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.articleExclusions.articleExclusionFilters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFiltersForArticleExclusionsList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearArticleExclusionsFilters();
        this.props.getArticleExclusionList('orderBy=createdAt&sort=desc');
    }

    handleSorting(orderBy, sort) {
        this.props.sortArticleExclusions(orderBy, sort)
        this.props.getArticleExclusionList(parseObjectToString(this.props.articleExclusions.articleExclusionFilters, {orderBy, sort}))
    }

    renderArticleExclusions(articleExclusions, showContentLoader) {
        const fields = [
            {
                name: 'tagForMapping',
            },
            {
                name: 'countryCodes',
                type: 'stringArray',
            },
            {
                name: 'carrierCodes',
                type: 'stringArray',
            },
            {
                name: 'creator.name',
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterAuthorityList = ['ADMIN','SUPPLY_CHAIN_MANAGER'];

        const filterKeys = [
            {
                name: 'tagForMapping',
                type: 'textField'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ];

        return(
            <DataTable fields={fields}
                       items={articleExclusions}
                       entity="articleExclusions"
                       filterKeys={filterKeys}
                       filterValues={this.props.articleExclusions.articleExclusionFilters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getArticleExclusionList(parseObjectToString(this.props.articleExclusions.articleExclusionFilters))}
                       sorting={this.props.articleExclusions.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
                       enableFilter={true}
                       disableCell={true}
            />
        )
    }

    render() {

        return(
            <PageTemplate title="messages.exclusionList" createPath="articles/exclusionCreate" uploadAction={"upload"} removePath="articles/exclusionRemove">
                {this.renderArticleExclusions(this.props.articleExclusions.articleExclusions, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    articleExclusions: state.articleExclusions,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleExclusionList: getArticleExclusionList,
    updateFiltersForArticleExclusionsList: updateFiltersForArticleExclusionsList,
    clearArticleExclusionsFilters: clearArticleExclusionsFilters,
    sortArticleExclusions: sortArticleExclusions,
    showError
})(ArticleExclusionList);