import axios from "./interceptor";
import { BASE_URL } from "../constant";
import { ARTICLE_AUDIT_GET_LIST, ARTICLE_AUDIT_CLEAR_FILTER, ARTICLE_AUDIT_SORT, ARTICLE_AUDIT_GET_DETAILS, ARTICLE_AUDIT_RESET_DETAILS, ARTICLE_AUDIT_RESET_LIST, ARTICLE_AUDIT_FILTER
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getArticleAuditList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/auditTrails/?${path}`)
        .then(response => {
            console.log("response", response);
            dispatch({
                type: ARTICLE_AUDIT_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const getArticleAuditDetails = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/auditTrails/${path}`)
        .then(response => {
            console.log("response", response);
            dispatch({
                type: ARTICLE_AUDIT_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const updateFiltersForAuditsList = (filterKey, filterValue) => {
    return({
        type: ARTICLE_AUDIT_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearArticleAuditsFilters = () => {
    return ({
        type: ARTICLE_AUDIT_CLEAR_FILTER
    })
}

export const sortArticleAudits = (orderBy, sort) => {
    return ({
        type: ARTICLE_AUDIT_SORT,
        payload: {orderBy, sort}
    })
}

export const clearArticleAuditLists = () => {
    return ({
        type: ARTICLE_AUDIT_RESET_LIST
    })
}

export const clearArticleAuditDetails = () => {
    return ({
        type: ARTICLE_AUDIT_RESET_DETAILS
    })
}