import React, { Component } from "react";
import { connect } from 'react-redux';
import { batchUploadBrandImages } from '../../actions/articleAction';
import _ from 'lodash';
import BrandImageUploadForm from "../../components/Forms/Brand/BrandImageUploadForm";

class BrandImageUpload extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openModel: false,
            requireMultiLocaliseDialog: false,
            templateType: '',
            skuCountryCodes: [],
        }
    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            window.setTimeout(() => {window.location.reload(true);}, 2000 )
        }
    }
    uploadBrandImages = (files) => {
        this.props.batchUploadBrandImages({"files": files});
    }

    render() {

        return (
            <BrandImageUploadForm batchUploadBrandImages={(files) => this.uploadBrandImages(files)}></BrandImageUploadForm>
        )
    }

}

const mapStateToProps = state => ({
    articles: state.articles,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    batchUploadBrandImages: batchUploadBrandImages,
})(BrandImageUpload);