import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  fullDateWithIndicator
} from "../../utils/utils";

const ArticleAccesoryTable = (props) => {
  const {
    articleAccesoryMapping, userCountryCode
  } = props;
  const customStyles = makeStyles({
    accesoryDivStyle: {
      display: `block`,
      position: `relative`,
      height: `300px`,
      overflow: `auto`,
    },
    backgroundPorcelain: {
      backgroundColor: `#eceff1`,
      fontWeight: `bold`,
    },
  });
  const customStyleClass = customStyles();

  return (
    <>
      <div className="form-group">
        <div className={customStyleClass.accesoryDivStyle}>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Type</th>
                <th>Manufacturer Code</th>
                <th>Description</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {articleAccesoryMapping?.accessoryMapping?.accessory != null ? (
                Object.entries(
                  articleAccesoryMapping.accessoryMapping.accessory
                )?.map(([countryCode, countryValue]) => {
                  if (countryCode != null && countryValue != null) {
                    if (countryValue?.type === "ArrayListAttribute") {
                      return (
                        <>
                          <tr>
                            <td
                              colSpan="5"
                              className={customStyleClass.backgroundPorcelain}
                            >
                              {countryCode}
                            </td>
                          </tr>

                          {countryValue?.value?.map((accesoryMappingValue) => {
                            var accessoryArticle =
                              articleAccesoryMapping?.articles.filter(function (
                                articleValue
                              ) {
                                return (
                                  articleValue?.sku === accesoryMappingValue
                                );
                              })[0];
                            return (
                              <tr>
                                <td>
                                  {" "}
                                  <a
                                    href={
                                      accessoryArticle
                                        ? `/articles/${accessoryArticle.id}`
                                        : "#"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className={!accessoryArticle?.sku ? 'link-disabled' : ''}
                                  >
                                    {accesoryMappingValue}
                                  </a>
                                </td>
                                <td>
                                  {" "}
                                  <a
                                    href={
                                      accessoryArticle
                                        ? `/articles/${accessoryArticle.id}`
                                        : "#"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >{`${
                                    accessoryArticle != null
                                      ? accessoryArticle.type?.code
                                      : ""
                                  }`}</a>
                                </td>
                                <td>
                                  {" "}
                                  <a
                                    href={
                                      accessoryArticle
                                        ? `/articles/${accessoryArticle.id}`
                                        : "#"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >{`${
                                    accessoryArticle != null
                                      ? accessoryArticle.manufacturer?.code
                                      : ""
                                  }`}</a>
                                </td>
                                <td>
                                  {" "}
                                  <a
                                    href={
                                      accessoryArticle
                                        ? `/articles/${accessoryArticle.id}`
                                        : "#"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >{`${
                                    accessoryArticle != null
                                      ? accessoryArticle?.description
                                      : ""
                                  }`}</a>
                                </td>
                                <td>
                                  {" "}
                                  <a
                                    href={
                                      accessoryArticle
                                        ? `/articles/${accessoryArticle.id}`
                                        : "#"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >{`${
                                    accessoryArticle != null
                                      ? fullDateWithIndicator(
                                          accessoryArticle.createdAt, userCountryCode
                                        )
                                      : ""
                                  }`}</a>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    }

                    if (countryValue?.type === "HashMapArrayListAttribute") {
                      return (
                        <>
                          <tr>
                            <td
                              colSpan="5"
                              className={customStyleClass.backgroundPorcelain}
                            >
                              {countryCode}
                            </td>
                          </tr>
                          {Object.entries(countryValue?.value)?.map(
                            ([carrierCode, carrierValue]) => {
                              return (
                                <>
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className={
                                        customStyleClass.backgroundPorcelain
                                      }
                                    >
                                      {carrierCode}
                                    </td>
                                  </tr>
                                  {carrierValue.map((accesoryMappingValue) => {
                                    var accessoryArticle =
                                      articleAccesoryMapping?.articles.filter(
                                        function (articleValue) {
                                          return (
                                            articleValue?.sku ===
                                            accesoryMappingValue
                                          );
                                        }
                                      )[0];

                                    return (
                                      <tr>
                                        <td>
                                          {" "}
                                          <a
                                            href={
                                              accessoryArticle
                                                ? `/articles/${accessoryArticle.id}`
                                                : "#"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className={!accessoryArticle?.sku ? 'link-disabled' : ''}
                                          >
                                            {accesoryMappingValue}
                                          </a>
                                        </td>
                                        <td>
                                          {" "}
                                          <a
                                            href={
                                              accessoryArticle
                                                ? `/articles/${accessoryArticle.id}`
                                                : "#"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >{`${
                                            accessoryArticle != null
                                              ? accessoryArticle.type?.code
                                              : ""
                                          }`}</a>
                                        </td>
                                        <td>
                                          {" "}
                                          <a
                                            href={
                                              accessoryArticle
                                                ? `/articles/${accessoryArticle.id}`
                                                : "#"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >{`${
                                            accessoryArticle != null
                                              ? accessoryArticle.manufacturer
                                                  ?.code
                                              : ""
                                          }`}</a>
                                        </td>
                                        <td>
                                          {" "}
                                          <a
                                            href={
                                              accessoryArticle
                                                ? `/articles/${accessoryArticle.id}`
                                                : "#"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >{`${
                                            accessoryArticle != null
                                              ? accessoryArticle?.description
                                              : ""
                                          }`}</a>
                                        </td>
                                        <td>
                                          {" "}
                                          <a
                                            href={
                                              accessoryArticle
                                                ? `/articles/${accessoryArticle.id}`
                                                : "#"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >{`${
                                            accessoryArticle != null
                                              ? fullDateWithIndicator(
                                                  accessoryArticle.createdAt, userCountryCode
                                                )
                                              : ""
                                          }`}</a>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              );
                            }
                          )}
                        </>
                      );
                    }
                  }
                  return null;
                }
                )
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ArticleAccesoryTable;
