import React from "react";
import { useTranslation } from 'react-i18next';
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FileUpload from "../components/FileUpload";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Chip, FormControlLabel, FormLabel, Popover, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { Delete } from "@material-ui/icons";

const styles = {
    title: {
        padding: 10,
        fontSize: 22,
        fontWeight: 500,
        marginBottom: 0,
    },
    description: {
        padding: 10,
        paddingTop: 0,
        fontSize: 15,
        fontWeight: 500,
        marginBottom: 0,
    },
    main: {
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30
    },
    mainPaper: {
        padding: 0,
    },
    mainHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    mainWrapper: {
        paddingTop: 0
    },
    actionButton: {
        background: "white",
        padding: "16px"
    },
    createButton: {
        background: "#E3D900",
        padding: "16px"
    },
    applyButton: {
        float: "right",
        padding: "16px",
        background: "transparent",
        margin: "16px 0 0 0"
    },
    resetButton: {
        border: "none",
        float: "right",
        padding: "16px",
        background: "transparent",
        margin: "16px 0 0 0"
    },
    iconMargin: {
        marginLeft: "8px"
    }
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        width: "256px",
        background: "white",
        marginBottom: "none",
        marginTop: "16px"
    },
    multipleSelect: {
        width: "480px",
        background: "white",
        marginBottom: "none",
        marginTop: "16px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    flexDisplaySpaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
}));

const PageTemplate = ({ title, pageDescription, children, createPath, removePath, backButton, downloadAction, uploadPath, filters, columnCustomisation }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [criteria, setCriteria] = React.useState({});
    const [searchFilter, setSearchFilter] = React.useState([]);
    const [currentKey, setCurrentKey] = React.useState(null);
    const [value, setValue] = React.useState('female');

    const classes = useStyles();

    const handleChange = (event) => {
        criteria[event.target.name] = event.target.value;
        console.log(criteria);
        setCriteria(criteria);
    };

    const handleFilterChange = (key, event) => {
        console.log("name", event.target.name);
        console.log("value", event.target.value);
        if (!criteria[event.target.name]) {
        } else {
        }

        setCurrentKey(event.target.value);
        setSearchFilter(event.target.value);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={styles.main}>
            <Box style={styles.mainHeader}>
                <h3 style={styles.title}>{t(title)}</h3>
                <Box>
                    {backButton !== false &&
                        <Button size="small" variant="outlined" color="primary" onClick={() => history.goBack()} style={styles.actionButton}>
                            {t('messages.back')}
                            <ArrowBackIcon style={styles.iconMargin} />
                        </Button>
                    }
                    {downloadAction &&
                        <Button size="small" variant="contained" onClick={() => downloadAction()} style={styles.actionButton} className="margin-left-10">
                            {t('messages.export')}
                            <GetAppIcon style={styles.iconMargin} />
                        </Button>
                    }
                    {uploadPath &&
                        <Link to={`/${uploadPath}`} seleniumselector={uploadPath}>
                            <Button size="small" variant="contained" color="secondary" style={styles.actionButton} className="margin-left-10">
                                {t('messages.upload')}
                                <FormatListBulletedIcon style={styles.iconMargin} />
                            </Button>
                        </Link>
                    }
                    {createPath &&
                        <Link to={`/${createPath}`} seleniumselector={createPath}>
                            <Button size="small" variant="contained" color="secondary" style={styles.createButton} className="margin-left-10">
                                {t('messages.create')}
                                <InsertDriveFileIcon style={styles.iconMargin} />
                            </Button>
                        </Link>
                    }
                     {removePath &&
                        <Link to={`/${removePath}`} seleniumselector={removePath}>
                            <Button size="small" variant="contained" color="secondary" style={styles.createButton} className="margin-left-10">
                                {t('messages.remove')}
                                <Delete style={styles.iconMargin} />
                            </Button>
                        </Link>
                    }
                </Box>
            </Box>

            <Box>
                <p style={styles.description}>{t(pageDescription).replace("{colon}", ":")}</p>
            </Box>
            <Divider />
            {filters &&
                <Box>
                    {filters.map((filter, index) => {
                        console.log("filter", filter)
                        switch (filter.type) {
                            case "select":
                                return <FormControl className={classes.formControl}>
                                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                        {filter.key}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-placeholder-label-label"
                                        id="demo-simple-select-placeholder-label"
                                        value={criteria[filter.key]}
                                        name={filter.key}
                                        onChange={handleChange}
                                        displayEmpty
                                        variant="outlined"
                                        className={classes.select}
                                    >
                                        {filter.values.map((value) => (
                                            <MenuItem value={value}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                break;
                            case "multi-select":
                                return <FormControl className={classes.formControl}>
                                    <InputLabel shrink id="demo-mutiple-chip-label">{filter.key}</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-chip-label"
                                        id="demo-mutiple-chip"
                                        value={criteria[filter.key] ? criteria[filter.key] : []}
                                        variant="outlined"
                                        className={classes.multipleSelect}
                                        onClick={handleClick}
                                        onChange={(event) => handleFilterChange(filter.value, event)}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} className={classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                    >
                                        {filter.values.map((value) => (
                                            <MenuItem key={value} value={value}>
                                                <Box>
                                                    {value}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Paper>
                                            <FormControl component="fieldset" className={classes.formControl}>
                                                <Typography variant="h5" component="legend">{currentKey}</Typography>
                                                <Divider />
                                                <RadioGroup value={value} onChange={handleChange}>
                                                    <Box style={styles.mainHeader}>
                                                        <Box>
                                                            <FormControlLabel value="notContain" control={<Radio />} label="Does not contain" />
                                                        </Box>
                                                        <Box>
                                                            <FormControlLabel value="contain" control={<Radio />} label="Contain" />
                                                        </Box>
                                                    </Box>
                                                </RadioGroup>
                                                <Divider />
                                                <TextField variant="outlined" id="standard-basic" style={{ marginTop: "8px", marginBottom: "8px" }} />
                                                <Divider />
                                                <Box style={styles.mainHeader}>
                                                    <Box>
                                                        <Button size="small" variant="contained" color="secondary" style={styles.actionButton}>
                                                            {t('messages.reset')}
                                                        </Button>
                                                    </Box>
                                                    <Box>
                                                        <Button size="small" variant="contained" color="secondary" style={styles.actionButton} className="margin-left-10">
                                                            {t('messages.apply')}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </FormControl>
                                        </Paper>
                                    </Popover>
                                </FormControl>
                                break;
                        }
                    })
                    }
                </Box>
            }
            <Divider />
            <Box className={classes.flexDisplaySpaceBetween}>
                {columnCustomisation ?
                    <Box>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-mutiple-chip-label">Column Customization</InputLabel>
                            <Select
                                id="demo-mutiple-chip"
                                multiple
                                value={[]}
                                onChange={handleChange}
                                className={classes.select}
                                variant="outlined"
                            >
                                {columnCustomisation.map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    : <Box></Box>
                }
                {(filters || columnCustomisation) &&
                    <Box>
                        <Button size="small" variant="contained" style={styles.applyButton} className="margin-left-10">
                            {t('messages.apply')}
                        </Button>
                        <Button size="small" style={styles.resetButton} className="margin-left-10">
                            {t('messages.reset')}
                        </Button>
                    </Box>
                }
            </Box>
            <Divider />
            <Paper style={styles.mainPaper}>
                <Box style={styles.mainWrapper}>
                    {children}
                </Box>
            </Paper>
        </div>
    )
}

export default PageTemplate;