import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Divider } from "@material-ui/core";
import FileUpload from '../../FileUpload';
import NoImageAvailable from '../../../assets/images/no-image-available.png';

const drawerWidth = 240;

const customStyles = makeStyles((theme) => ({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    cardDanger: {
        borderColor: '#f44336'
    },

    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    flexDisplay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 10
    },
    flexDisplaySpaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    flexDisplayLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        paddingBottom: 10
    },
    hide: {
        display: 'none',
    },
    show: {
        display: "block",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    }
}));

const ArticleImageUploadForm = ({ }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    const [checked, setChecked] = React.useState([0]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const container = React.useRef(null);

    return (
        <div>
            <div className="padding-10">
                <Grid container item md={12} spacing={5} className={classes.flexDisplay}>
                    <Grid item md={6}>
                        <Typography variant="h6" color="primary">Edit Images</Typography>
                        <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                        <Typography variant="body1" className="grouping-header">Add front and back images for Article 200109</Typography>
                        <Grid item md={12} className={classes.flexDisplay} style={{ marginTop: "16px" }}>
                            <Grid item md={6}>
                                <Typography variant="body1" color="primary" style={{ marginBottom: "16px" }}>Upload Front image</Typography>
                                <Typography variant="body1" className="grouping-header">Max file size is 10mb at 400x560 resolution, and supported file type is .png</Typography>

                                <div style={{ marginTop: "16px" }}>
                                    <FileUpload defaultFields={{}} templateFile={`import-template-merchantUser.csv`} onUpload={(data) => this.onUpload(data)} />
                                </div>

                                <Typography variant="body1" color="primary" style={{ marginTop: "16px", marginBottom: "16px" }}>Front Image Preview</Typography>

                                <img src={NoImageAvailable} alt="Image Not Available" style={{ width: "300px", height: "400px", border: "1px dotted grey" }} />

                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="body1" color="primary" style={{ marginBottom: "16px" }}>Upload Back image</Typography>
                                <Typography variant="body1" className="grouping-header">Max file size is 10mb at 400x560 resolution, and supported file type is .png</Typography>

                                <div style={{ marginTop: "16px" }}>
                                    <FileUpload defaultFields={{}} templateFile={`import-template-merchantUser.csv`} onUpload={(data) => this.onUpload(data)} />
                                </div>

                                <Typography variant="body1" color="primary" style={{ marginTop: "16px", marginBottom: "16px" }}>Back Image Preview</Typography>

                                <img src={NoImageAvailable} alt="Image Not Available" style={{ width: "300px", height: "400px", border: "1px dotted grey" }} />

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div >
    )
}

export default ArticleImageUploadForm;