import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../../components/PageTemplate";
import * as yup from "yup";
import { getArticleAuditList, sortArticleAudits, clearArticleAuditLists, updateFiltersForAuditsList, clearArticleAuditsFilters } from "../../actions/articleAuditAction";
import { parseObjectToString } from "../../utils/utils";
import DataTableWithSimpleForm from "../../components/DataTableWIthSimpleForm";
import DataTable from "../../components/DataTable";

class ArticleAuditList extends Component {
    componentDidMount() {
        this.props.clearArticleAuditLists();
        this.props.clearArticleAuditsFilters();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
       
    }

    handleSorting(orderBy, sort) {
        this.props.sortArticleAudits(orderBy, sort)
        this.props.getArticleAuditList(parseObjectToString(this.props.articleAudits.articleAuditFilters, { orderBy, sort }))
    }

    searchArticleAudit = (values) => {
        Object.keys(values).filter(key => {
            this.props.updateFiltersForAuditsList(key, values[key])
        });

        this.props.getArticleAuditList(parseObjectToString(this.props.articleAudits.articleAuditFilters))
    }

    renderForm = () => {
        const editableFields = {
            entityId: '',
            entityName: 'Article'

        }

        const validationRules = yup.object({
            entityId: yup.string().required('messages.fieldRequired'),
            entityName: yup.string().required('messages.fieldRequired'),

        });

            return (
            <DataTableWithSimpleForm 
                entityNameSelection={["Article", "Grouping", "TradeInPrice", "SystemConfiguration", "SuggestedRetailPrice", "AccessoryMapping"]}
                editableFields={editableFields}
                validationRules={validationRules}
                onFormSubmit={values => this.searchArticleAudit(values)
                }
            />
        )
    }

    renderAudits(articleAudits, showContentLoader) {
        const fields = [
            {
                name: 'entityId',
                label: 'sku',
            },
            {
                name: 'performerName',
                label: "updatedBy"
            },
            {
                name: 'updatedAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
        ];

        return (
            <DataTable fields={fields}
                items={articleAudits}
                entity="audits/articles"
                filterKeys={[]}
                filterValues={{}}
                onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                sorting={this.props.articleAudits.sorting}
                onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                sortingAvailable={this.props.articleAudits.articleAudits.length > 0}
                onCancel={() => this.handleFilterReset()}
                showContentLoader={showContentLoader}
                enableFilter={false}
                disableCell={false}
            />
        )
    }


    render() {
        return(
            <PageTemplate title={ "messages.auditTrailList"}>
                {this.renderForm()}
                {this.renderAudits(this.props.articleAudits.articleAudits, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    articleAudits: state.articleAudits,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleAuditList,
    sortArticleAudits,
    updateFiltersForAuditsList,
    clearArticleAuditLists,
    clearArticleAuditsFilters
})(ArticleAuditList);