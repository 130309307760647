import React, {Component} from "react";
import { connect } from 'react-redux';
import { getLikeForUpdateRequestList, sortLikeForUpdateRequestUpdate,
    updateFiltersForLikeForUpdateRequestList, clearLikeForUpdateRequestFilters
 } from "../../actions/articleLikeForRequestAction";
import DataTable from "../../components/DataTable";
import PageTemplate from "../../components/PageTemplate";
import {
    parseObjectToString,
} from "../../utils/utils";
import {showError} from "../../actions/commonActions";

class ArticleLikeForUpdateRequest extends Component {
    componentDidMount() {
        this.props.getLikeForUpdateRequestList('orderBy=createdAt&sort=desc');
        this.props.clearLikeForUpdateRequestFilters();
        this.props.sortLikeForUpdateRequestUpdate('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {}

    handleFilterReset() {}

    handleSorting(orderBy, sort) {
        this.props.sortLikeForUpdateRequestUpdate(orderBy, sort)
        this.props.getLikeForUpdateRequestList(parseObjectToString(this.props.articleLikeForUpdateRequests.requestListFilter, {orderBy, sort}))
    }

    renderRequestList(requestList, showContentLoader) {
        const fields = [
            {
                name: 'tagForMapping',
            },
            {
                name: 'countryCode',
                type: 'translatedString'
            },
            {
                name: 'carrierCode',
            },
            {
                name: 'syncStatus',
                type: 'translatedString'
            },
            {
                name: 'updateOptionDescription.optionOne',
            },
            {
                name: 'updateOptionDescription.optionTwo',
            },
            {
                name: 'updateOptionDescription.optionThree',
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }
        ];

        const filterKeys = [];

        return(
            <DataTable fields={fields}
                       items={requestList}
                       redirectId={null}
                       entity="requestList"
                       filterKeys={filterKeys}
                       filterValues={this.props.articleLikeForUpdateRequests.requestListFilter}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getLikeForUpdateRequestList(parseObjectToString(this.props.articleLikeForUpdateRequests.requestListFilter))}
                       sorting={this.props.articleLikeForUpdateRequests.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
                       disableCell={true}
                       enableFilter={false}
            />
        )
    }

    render() {

        return(
            <PageTemplate title="messages.l4sUpdateRequest">
                {this.renderRequestList(this.props.articleLikeForUpdateRequests.requestList, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    articleLikeForUpdateRequests: state.articleLikeForUpdateRequests,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getLikeForUpdateRequestList: getLikeForUpdateRequestList,
    updateFiltersForLikeForUpdateRequestList: updateFiltersForLikeForUpdateRequestList,
    clearLikeForUpdateRequestFilters: clearLikeForUpdateRequestFilters,
    sortLikeForUpdateRequestUpdate: sortLikeForUpdateRequestUpdate,
    showError
})(ArticleLikeForUpdateRequest);