import _ from 'lodash';
import {
    TIME_FORMAT_LONG,
    TIME_FORMAT_SHORT,
    TIME_FORMAT_LONG_WITH_INDICATOR,
    TIME_ZONES
} from "../constant";
import format from 'date-fns/format';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import isValid from 'date-fns/isValid';
import {parseISO} from "date-fns";
import {LOG_OUT, SHOW_BACKDROP_LOADER, SHOW_CONTENT_LOADER, SHOW_ERROR} from "../actions/types";

export const parseObjectToString = (objectsToBeParsed, sorting) => {
    let filterFields = '';
    let filterValues = '';
    let parsedSorting = parseSortingToString(sorting);
    _.forEach(objectsToBeParsed, (value, key) => {
        if (value.length > 0) {
            if (Array.isArray(value)) {
                value.forEach(valueElement => {
                    filterFields = filterFields + `filterFields[]=${key}&`
                    filterValues = filterValues + `filterValues[]=${valueElement}&`

                })
            } else {
                filterFields = filterFields + `filterFields[]=${key}&`
                filterValues = filterValues + `filterValues[]=${encodeURIComponent(value)}&`
            }
        }

        if (key === 'createdAt' || key === 'pickUpDate') {
            if (value['from'] !== null) {
                filterFields = filterFields + `filterFields[]=${key}.from&`
                filterValues = filterValues + `filterValues[]=${encodeURIComponent(value['from'])}&`
            }
            if (value['to'] !== null) {
                filterFields = filterFields + `filterFields[]=${key}.to&`
                filterValues = filterValues + `filterValues[]=${encodeURIComponent(value['to'])}&`
            }
        }

    })
    return (filterFields + filterValues + parsedSorting);
}

export const parseSortingToString = (sorting) => {
    let parsedSorting = '';
    if (sorting && sorting.orderBy && sorting.sort) {
        parsedSorting = `orderBy=${sorting.orderBy}&sort=${sorting.sort}`;
    }

    return parsedSorting;
}

export const showContentLoader = (showLoading, dispatch) => {
    dispatch({
        type: SHOW_CONTENT_LOADER,
        payload: showLoading
    })
}

export const showBackDropLoader = (showLoading, dispatch) => {
    dispatch({
        type: SHOW_BACKDROP_LOADER,
        payload: showLoading
    })
}

export const handleError = (error, dispatch) => {
    if (error.response.data.errorCode === 401 || error.toString().includes('401')) {
        dispatch({
            type: LOG_OUT,
            payload: error.response
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: error.response
        })
    }
}

export const handleMultipleError = (error, dispatch) => {
    dispatch({
        type: SHOW_ERROR,
        payload:  { data: { translationKey: error } } 
    })

}

export const fallbackValue = {
    pickUpDate: "selectPickUpDate"
}

export const parseDate = (dateValue, timeZone) => {
    const valueToDate = new Date(dateValue);
    return format(valueToDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxx");
}

export const parseDateToYearMonth = (dateValue, timeZone) => {
    const valueToDate = new Date(dateValue);
    return format(valueToDate, "yyyy-MM");
}

export const fullDate = (date, countryCode) => {
    return isValid(parseISO(date)) ? format(utcToZonedTime(date, TIME_ZONES[countryCode]), TIME_FORMAT_LONG) : '';
}

export const fullDateWithIndicator = (date, countryCode) => {
    return isValid(parseISO(date)) ? format(utcToZonedTime(date, TIME_ZONES[countryCode]), TIME_FORMAT_LONG_WITH_INDICATOR) : '';
}

export const shortDate = (date, countryCode) => {
    return isValid(parseISO(date)) ? format(utcToZonedTime(date, TIME_ZONES[countryCode]), TIME_FORMAT_SHORT) : '';
}

export const validateAuthority = (permittedAuthorities, userAuthorities) => {
    return userAuthorities.some(userAuthority=> permittedAuthorities.includes(userAuthority));
}

export const validateDisableAuthority = (permittedAuthorities, userAuthorities) => {
    return !userAuthorities.some(userAuthority=> permittedAuthorities.includes(userAuthority));
}

export const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase())
}

export const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\+[0-9]+$/;
    return phoneNumberRegex.test(String(phoneNumber));
}

export const validateAcceptedFields = (validFields, dataFields) => {
    return dataFields.some(dataField => validFields.includes(dataField));
}

export const validateRequiredFields = (requiredFields, data) => {
    let result = true;
    requiredFields.forEach(requiredField => {
        result = result && (_.has(data, requiredField)) && (_.get(data, requiredField).length > 0) ;
    })
    return result;
}

export const trimData = (data) => {
    console.log('trimming...', data);
    return data.forEach(datum => {
        Object.keys(datum).forEach(fieldName => datum[fieldName] = datum[fieldName]?.trim());
    })
}

export const languageCodeWithCountryCode = (loggedInUser) => {
    let languageCodeCountryCode = loggedInUser.languageCode + '_' + loggedInUser.countryCode;
    return languageCodeCountryCode;
}

export const checkValueMatchedUserCountryCode = (key, userCountryCode) => {
    let result = false;
    if(key && key.slice(-2) === userCountryCode){
        result = true;
    }
    return result;
}

export const validateValueBasedOnAuthorityAndCountryCode = (key, userCountryCode,permittedAuthorities, userAuthorities ) => {
    let result = true;
    if(!validateAuthority(permittedAuthorities, userAuthorities)) {
        result = false;
        if(checkValueMatchedUserCountryCode(key, userCountryCode)){
            result = true;
        }
    }
    return result;
}

export const checkAllObjectKeyValue = (object, valueToVerify) => {
    console.log(JSON.stringify(object))
    for (let key in object) {
        if (typeof object[key] === "object") {
          if (!checkAllObjectKeyValue(object[key], valueToVerify)) {
            return false;
          }
        } else if (object[key] !== valueToVerify) {
          return false;
        }
    }
      return true;
}

export const convertToDimensional = (data) => {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            if (key.indexOf(".") !== -1) {
                parseDotNotation(key, data[key], data);
            }
        }
    }
    return data;
}

export const parseDotNotation = (originalKey, val, obj) => {
    var currentObj = obj,
        keys = originalKey.split("."),
        i, l = Math.max(1, keys.length - 1), key;

    for (i = 0; i < l; ++i) {
        key = keys[i];
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
    }

    currentObj[keys[i]] = val;
    delete obj[originalKey];
}