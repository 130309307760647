import React from 'react';
import ContentLoader from 'react-content-loader';

const DataTableLoader = props => {
    return (
        <ContentLoader
            width={1400}
            height={400}
            viewBox="0 0 1400 400"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
            {...props}
        >
            <rect x="27" y="3" rx="4" ry="4" width="20" height="20"/>
            <rect x="67" y="4" rx="10" ry="10" width="85" height="19"/>
            <rect x="188" y="5" rx="10" ry="10" width="169" height="19"/>
            <rect x="402" y="4" rx="10" ry="10" width="85" height="19"/>
            <rect x="523" y="5" rx="10" ry="10" width="169" height="19"/>
            <rect x="731" y="3" rx="10" ry="10" width="85" height="19"/>
            <rect x="852" y="2" rx="10" ry="10" width="85" height="19"/>
            <rect x="1424" y="1" rx="10" ry="10" width="68" height="19"/>
            <rect x="26" y="60" rx="4" ry="4" width="20" height="20"/>
            <rect x="66" y="61" rx="10" ry="10" width="85" height="19"/>
            <rect x="187" y="62" rx="10" ry="10" width="169" height="19"/>
            <rect x="401" y="61" rx="10" ry="10" width="85" height="19"/>
            <rect x="522" y="62" rx="10" ry="10" width="169" height="19"/>
            <rect x="730" y="60" rx="10" ry="10" width="85" height="19"/>
            <rect x="851" y="59" rx="10" ry="10" width="85" height="19"/>
            <circle cx="1456" cy="67" r="12"/>
            <rect x="26" y="122" rx="4" ry="4" width="20" height="20"/>
            <rect x="66" y="123" rx="10" ry="10" width="85" height="19"/>
            <rect x="187" y="124" rx="10" ry="10" width="169" height="19"/>
            <rect x="401" y="123" rx="10" ry="10" width="85" height="19"/>
            <rect x="522" y="124" rx="10" ry="10" width="169" height="19"/>
            <rect x="730" y="122" rx="10" ry="10" width="85" height="19"/>
            <rect x="851" y="121" rx="10" ry="10" width="85" height="19"/>
            <circle cx="1456" cy="129" r="12"/>
            <rect x="26" y="180" rx="4" ry="4" width="20" height="20"/>
            <rect x="66" y="181" rx="10" ry="10" width="85" height="19"/>
            <rect x="187" y="181" rx="10" ry="10" width="169" height="19"/>
            <rect x="401" y="181" rx="10" ry="10" width="85" height="19"/>
            <rect x="522" y="182" rx="10" ry="10" width="169" height="19"/>
            <rect x="730" y="180" rx="10" ry="10" width="85" height="19"/>
            <rect x="851" y="179" rx="10" ry="10" width="85" height="19"/>
            <circle cx="1456" cy="187" r="12"/>
            <rect x="26" y="243" rx="4" ry="4" width="20" height="20"/>
            <rect x="66" y="244" rx="10" ry="10" width="85" height="19"/>
            <rect x="187" y="245" rx="10" ry="10" width="169" height="19"/>
            <rect x="401" y="244" rx="10" ry="10" width="85" height="19"/>
            <rect x="522" y="245" rx="10" ry="10" width="169" height="19"/>
            <rect x="730" y="243" rx="10" ry="10" width="85" height="19"/>
            <rect x="851" y="242" rx="10" ry="10" width="85" height="19"/>
            <circle cx="1456" cy="250" r="12"/>
            <rect x="978" y="2" rx="10" ry="10" width="169" height="19"/>
            <rect x="977" y="59" rx="10" ry="10" width="169" height="19"/>
            <rect x="977" y="121" rx="10" ry="10" width="169" height="19"/>
            <rect x="977" y="179" rx="10" ry="10" width="169" height="19"/>
            <rect x="977" y="242" rx="10" ry="10" width="169" height="19"/>
            <rect x="1183" y="3" rx="10" ry="10" width="85" height="19"/>
            <rect x="1182" y="60" rx="10" ry="10" width="85" height="19"/>
            <rect x="1182" y="122" rx="10" ry="10" width="85" height="19"/>
            <rect x="1182" y="180" rx="10" ry="10" width="85" height="19"/>
            <rect x="1182" y="243" rx="10" ry="10" width="85" height="19"/>
            <rect x="1305" y="1" rx="10" ry="10" width="85" height="19"/>
            <rect x="1304" y="58" rx="10" ry="10" width="85" height="19"/>
            <rect x="1304" y="120" rx="10" ry="10" width="85" height="19"/>
            <rect x="1304" y="178" rx="10" ry="10" width="85" height="19"/>
            <rect x="1304" y="241" rx="10" ry="10" width="85" height="19"/>
        </ContentLoader>

    )
}

export default DataTableLoader;