import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { AUTHORITY_ADMIN } from "../../constant";
import {
  fullDateWithIndicator,
} from "../../utils/utils";

const ArticleSuggestedRetailPriceTable = (props) => {
  const { articleSuggestedRetailPrice, userCountryCode } =
    props;
  const { t } = useTranslation();
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });
  const customStyleClass = customStyles();

  return (
    <>
      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.suggestedRetailPrice.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.carrierCode.keyword`)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articleSuggestedRetailPrice?.price &&
                Object.entries(articleSuggestedRetailPrice.price)?.map(
                  ([countryCode, countryValue]) => {
                    if (
                      countryCode != null &&
                      countryValue != null 
                    ) {
                      if (countryValue?.type === "MoneyObjectAttribute") {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td></td>
                            <td>
                              <strong>{`${countryValue?.value?.amount} (${countryValue?.value?.currencyCode})`}</strong>
                            </td>
                          </tr>
                        );
                      }

                      if (
                        countryValue?.type === "HashMapMoneyObjectAttribute"
                      ) {
                        return (
                          <tr>
                            <td>{countryCode}</td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>{carrierCodeKey}</strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                            <td>
                              {countryValue?.value &&
                                Object.entries(countryValue.value).map(
                                  ([carrierCodeKey, carrierCodeValue]) => {
                                    return (
                                      <div>
                                        <strong>{`${carrierCodeValue?.amount} (${carrierCodeValue?.currencyCode})`}</strong>
                                      </div>
                                    );
                                  }
                                )}
                            </td>
                          </tr>
                        );
                      }
                    }
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-md-4">
        <div className="form-group">
          <label>{t(`keys.article.suggestedRetailPriceHistory.keyword`)}</label>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{t(`keys.article.countryCode.keyword`)}</th>
                <th>{t(`keys.article.amount.keyword`)}</th>
                <th>{t(`keys.article.createdAt.keyword`)}</th>
              </tr>
            </thead>
            <tbody>
              {articleSuggestedRetailPrice?.history &&
                Object.entries(articleSuggestedRetailPrice.history)?.map(
                  ([countryCode, countryValue]) => {
                    return countryValue.map((historyValue) => {
                      if (
                        countryCode &&
                        historyValue
                      ) {
                        if (historyValue?.type === "MoneyObjectAttribute") {
                          return (
                            <>
                              <tr>
                                <td
                                  className="background-porcelain font-weight-bold"
                                  colSpan="5"
                                >
                                  {countryCode}
                                </td>
                              </tr>
                              <tr>
                                <td>{historyValue?.value?.currencyCode}</td>
                                <td>{historyValue?.value?.amount}</td>
                                <td>
                                  {fullDateWithIndicator(
                                    historyValue?.createdAt, userCountryCode
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        }

                        if (
                          historyValue?.type === "HashMapMoneyObjectAttribute"
                        ) {
                          return (
                            <>
                              <tr>
                                <td
                                  className="background-porcelain font-weight-bold"
                                  colSpan="5"
                                >
                                  {countryCode}
                                </td>
                              </tr>
                              {Object.entries(historyValue?.value)?.map(
                                ([
                                  historyValueCarrierKey,
                                  historyValueCarrierValue,
                                ]) => {
                                  return (
                                    <>
                                      <tr
                                        className="background-porcelain font-weight-bold"
                                        colSpan="5"
                                      >
                                        <td>{historyValueCarrierKey}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {
                                            historyValueCarrierValue?.currencyCode
                                          }
                                        </td>
                                        <td>
                                          {historyValueCarrierValue?.amount}
                                        </td>
                                        <td>
                                          {fullDateWithIndicator(
                                            historyValue?.createdAt, userCountryCode
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        }
                      }
                    });
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ArticleSuggestedRetailPriceTable;
