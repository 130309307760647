import {
    ARTICLE_AUDIT_SORT,
    ARTICLE_AUDIT_GET_LIST,
    ARTICLE_AUDIT_FILTER,
    ARTICLE_AUDIT_CLEAR_FILTER,
    ARTICLE_AUDIT_RESET_DETAILS,
    ARTICLE_AUDIT_RESET_LIST,
    ARTICLE_AUDIT_GET_DETAILS
} from "../actions/types";

const INITIAL_STATE = {
    articleAudits: [],
    articleAudit: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    articleAuditFilters: {
        entityName: '',
        performerName: '',
        updatedAt: {
            from: null,
            to: null
        }
    },
}

const articleAuditReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ARTICLE_AUDIT_GET_LIST:
            return {
                ...state,
                articleAudits: action.payload.data
            }
        case ARTICLE_AUDIT_FILTER:
            return {
                ...state,
                articleAuditFilters: {
                    ...state.articleAuditFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case ARTICLE_AUDIT_CLEAR_FILTER:
            return {
                ...state,
                articleAuditFilters: {
                    entityName: '',
                    performerName: '',
                    updatedAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case ARTICLE_AUDIT_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case ARTICLE_AUDIT_GET_DETAILS:
            return {
                ...state,
                articleAudit: action.payload.data,
            }
        case ARTICLE_AUDIT_RESET_LIST:
        return {
            ...state,
            articleAudits: [],
        }
        case ARTICLE_AUDIT_RESET_DETAILS:
            return {
                ...state,
                articleAudit: {},
            }
        default:
            return state;
    }
}

export default articleAuditReducer;