import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  fullDateWithIndicator,
  validateValueBasedOnAuthorityAndCountryCode
} from "../../utils/utils";
import { AUTHORITY_ADMIN } from "../../constant";
import defaultDeviceView from "../../assets/images/phone-placeholder.png";

const ArticleBasicInfo = (props) => {
  const { mapping, countryCode } = props;
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });

  const elementRender = (element,index) => {
    const customStyleClass = customStyles();

    switch (element.type) {
      case "tacArray":
        return (
          <div className="form-group" key={`${element.type}-${element.label}`}>
            <label>{element.label}</label>
            <br />
            <ul className="list-group">
              {element?.value != null &&
                element?.value.map((value) => (
                  <li className="list-unstyled mb-1">
                    <strong>{value}</strong>
                  </li>
                ))}
            </ul>
          </div>
        );
      case "manufacturerNameArray":
        return (
          <div className="form-group" key={`${element.type}-${element.label}`}>
            <label>{element.label}</label>
            <ul className="list-group">
              {
                <li className="list-unstyled mb-1">
                  <code className={customStyleClass.customBackground}>
                    Default
                  </code>
                  <strong>{` ${element.value?.code}`}</strong>
                </li>
              }
              {element.value?.name &&
                Object.entries(element.value?.name)?.map(([key, value],elementIndex) => {
                  if (
                    key &&
                    value
                  ) {
                    return (
                      <li className="list-unstyled mb-1" key={`$key}-${elementIndex}`}>
                        <code className={customStyleClass.customBackground}>
                          {key}
                        </code>
                        <strong>{` ${value}`}</strong>
                      </li>
                    );
                  }
                })}
            </ul>
          </div>
        );

      case "dateString":
        return (
          <div className="form-group" key={`${element.type}-${element.label}`}>
            <label>{element.label}</label>
            <br />
            <label className="font-weight-bold">
              {fullDateWithIndicator(element.value, countryCode)}
            </label>
            <br />
          </div>
        );

      case "image":
        return (
          <div className="col-md-2" key={`${element.type}-${element.value}`}>
            <img
              onError={(e) => {
                e.currentTarget.src = defaultDeviceView;
              }}
              className="img-fluid form-group"
              src={element.value}
            />
          </div>
        );

      default:
        return (
          <div className="form-group" key={`${element.type}-${element.label}`}>
            <label>{element.label}</label>
            <br />
            <label className="font-weight-bold">{element.value}</label>
            <br />
          </div>
        );
    }
  };
  return (
    <>
      {mapping.map((element,index) => {
        return elementRender(element,index);
      })}
    </>
  );
};

export default ArticleBasicInfo;
