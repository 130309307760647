import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    SHOW_SUCCESS,SHOW_SEVERITY_UPLOAD_DIALOG,
    HIDE_SEVERITY_UPLOAD_DIALOG
} from "./types";
import { handleError } from "../utils/utils";

export const batchCreateSeverity = (severityMappings) => async dispatch => {
    console.log("Posting "+ JSON.stringify(severityMappings) );
    axios.post(`${BASE_URL}/current/articleSeverity/upload`, severityMappings)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.fileUploaded',
                    dialogType: 'info',
                    refresh: true
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}

export const showSeverityUploadDialog = (entity) => {
    return({
        type: SHOW_SEVERITY_UPLOAD_DIALOG,
        payload: {
            entity: entity
        }
    })
}

export const hideSeverityUploadDialog = () => {
    return ({
        type: HIDE_SEVERITY_UPLOAD_DIALOG
    })
}