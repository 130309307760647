import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../../components/PageTemplate";
import { getArticleAuditDetails, clearArticleAuditDetails } from "../../actions/articleAuditAction";
import DataTableLoader from "../../components/DataTableLoader";
import AuditDetails from "../../components/Audits/AuditDetails";
import {withTranslation} from "react-i18next";

class ArticleAuditDetails extends Component {
    componentDidMount() {
        this.props.clearArticleAuditDetails();
        this.props.getArticleAuditDetails(this.props.match.params.id).then(() => {
        })
    }

    renderDetails = (articleAudits) => {

        return (
            <AuditDetails articleAudits={articleAudits} userCountryCode={this.props.authentication.loggedInUser.countryCode}>

            </AuditDetails>
        );
    }
    
    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.auditDetails">
                {this.props.common.showContentLoader ? <DataTableLoader /> :
                    <React.Fragment>
                        {this.renderDetails(this.props.articleAudits.articleAudit)}
                    </React.Fragment>
                    }
            </PageTemplate>

        )
    }
}

const mapStateToProps = state => ({
    articleAudits: state.articleAudits,
    state: state,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleAuditDetails: getArticleAuditDetails,
    clearArticleAuditDetails: clearArticleAuditDetails
})(withTranslation() (ArticleAuditDetails));