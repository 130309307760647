import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import { showAccessoryMappingUploadDialog, hideAccessoryMappingUploadDialog, batchCreateAccessoryMapping } from "../../actions/accessoryMappingAction";
import {
    validateAcceptedFields,
    validateRequiredFields,
    validateAuthority
} from "../../utils/utils";
import _ from "lodash";
import { showError } from "../../actions/commonActions";
import AccessoryMappingUploadDialog from "../../components/dialogs/AccessoryMapping/AccessoryMappingUploadDialog";
import { Box } from "@material-ui/core";
import FilesUpload from "../../components/FilesUpload";
import { fileUploadAcceptedFields, fileUploadRequiredFields } from "../../utils/fields";
import { convertToArticleAccessoryMappingObject, formatAccessoryMappingRecord } from "../../utils/CsvObjectModifier/AccessoryMapping/AccessoryMappingMapper";
import { ACCESSORY_MAPPING_CSV_OBJECT } from "../../utils/CsvObjectModifier/CsvObjectFormat";
import { AUTHORITY_ADMIN } from "../../constant";
class ArticleAccesoryMapping extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onUpload = (data) => {
        let validatedRecords = {
            valid: true,
            lineNumberWithErrors: [],
            recordLimitExceeded: false,
            redundantAccessory: [],
            accessoryWithCountrySpecificAndCarrierSpecific: [],
            emptyRecord: false
        };
        let skuWithCarrierCode =[...new Set( data.filter((obj) => obj.carrierCode).map(obj => obj.sku+"-"+ obj.countryCode))];

        data.reduce((count, accessoryObject) => {
            let key = `${accessoryObject.sku}_${accessoryObject.countryCode.slice(0,2).toLowerCase()}_${accessoryObject.carrierCode.toLowerCase()}`;
            if (!count[key]) {
              count[key] = [accessoryObject];
            } else {
              validatedRecords.redundantAccessory.push(accessoryObject.sku);
              count[key].push(accessoryObject);
            }
            return count;
        }, {});

        data.forEach((datum, index) => {
            validatedRecords.valid = validateAcceptedFields(fileUploadAcceptedFields['articleAccessory'], Object.keys(datum)) &&
                validateRequiredFields(fileUploadRequiredFields['articleAccessory'], datum);
            if (validatedRecords.valid == false) {
                validatedRecords.lineNumberWithErrors.push(index + 3)
            }

            if(datum.carrierCode === '' && skuWithCarrierCode.includes(datum.sku+"-"+datum.countryCode)){
                validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.push(datum.sku);
            }
        });

        if(data.length <= 0 ) {
            validatedRecords.emptyRecord = true;
        }
        if (data.length > 5000) {
            validatedRecords.recordLimitExceeded = true;
        }

        if (validatedRecords.lineNumberWithErrors.length === 0 && validatedRecords.recordLimitExceeded == false && validatedRecords.redundantAccessory.length === 0 && validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.length === 0 && validatedRecords.emptyRecord == false) {
            this.props.showAccessoryMappingUploadDialog(data)
        } else {

            if (validatedRecords.emptyRecord == true) {
                this.props.showError({ response: { data: { translationKey: `0 record uploaded. Please fix and try again.` } } });
            } 

            if (validatedRecords.recordLimitExceeded == true) {
                this.props.showError({ response: { data: { translationKey: `Exceeded 5000 limit of records with number of ${data.length} uploaded records. Please fix and try again.` } } });
            } 
            
            if (validatedRecords.lineNumberWithErrors.length > 0){
                this.props.showError({ response: { data: { translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.` } } });
            }

            if(validatedRecords.redundantAccessory.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found record with duplicated sku, countryCode and carrierCode for sku ${validatedRecords.redundantAccessory}. Please fix and try again` } } });
            }

            if(validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific.length > 0) {
                this.props.showError({ response: { data: { translationKey: `Found sku with both country specific and carrier specific record for sku ${[... new Set(validatedRecords.accessoryWithCountrySpecificAndCarrierSpecific)]}. Please fix and try again` } } });
            }
        }
    }

    uploadAccessoryMappings = (data) => {
        this.props.hideAccessoryMappingUploadDialog();
        data = convertToArticleAccessoryMappingObject(data);
        this.props.batchCreateAccessoryMapping(data);
    }

    handleServerSideArticleCSVTemplateDownload = (data) => {
        var isAdmin  = validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities);
        
        const link = document.createElement('a');
        if(!isAdmin) {
            var countryCode = this.props.authentication.loggedInUser.countryCode
            link.href = `${process.env.PUBLIC_URL}/assets/resources/template_accessory_mapping_${countryCode}.csv`;
            link.download = 'template_accessory_mapping.csv';
            
            link.click();
        } else {
            data.map((countryCode) => {
                link.href = `${process.env.PUBLIC_URL}/assets/resources/template_accessory_mapping_${countryCode}.csv`;
                link.download = `template_accessory_mapping_${countryCode}.csv`;
                
                link.click();
            })
        }
    }

    render() {
        const defaultFields = {
            countryCode: this.props.authentication.loggedInUser.countryCode
        }
        const customeSliceCsvRecord = 1;
        const { t } = this.props;

        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ paddingLeft: 30, paddingTop: 30 }}>{"Accessories Mapping"}</h3>
                </Box>
                <Box mb={2} mt={2} style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <FilesUpload defaultFields={defaultFields}
                        templateFile={`template_accessory_mapping.csv`}
                        onUpload={(data) => this.onUpload(data)}
                        changeHeader={ACCESSORY_MAPPING_CSV_OBJECT}
                        addAdditionAttributes={(data) => formatAccessoryMappingRecord(data)}
                        sliceCsv={customeSliceCsvRecord}
                        isAdmin={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities)}
                        handleServerSideArticleCSVTemplateDownload={(data) => this.handleServerSideArticleCSVTemplateDownload(data)}
                        titleName={'messages.createAccessoryMapping'}
                    />

                </Box>

                <AccessoryMappingUploadDialog entity={this.props.accessoryMappings.accessoryMappingUploadDialog.entity}
                        open={this.props.accessoryMappings.accessoryMappingUploadDialog.open}
                        onReject={() => this.props.hideAccessoryMappingUploadDialog()}
                        onAccept={(data) => this.uploadAccessoryMappings(data)}
                    />
            </>

        )
    }

}

const mapStateToProps = state => ({
    accessoryMappings: state.accessoryMappings,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    batchCreateAccessoryMapping: batchCreateAccessoryMapping,
    showAccessoryMappingUploadDialog: showAccessoryMappingUploadDialog,
    hideAccessoryMappingUploadDialog: hideAccessoryMappingUploadDialog,
    showError
})(ArticleAccesoryMapping);