import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FormControl, Card, FormControlLabel, Checkbox, FormHelperText, Radio, RadioGroup, Box } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FilterListIcon from '@material-ui/icons/FilterList';

const customStyles = makeStyles({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    fieldSet: {
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.23)'
    },
    legend: {
        fontSize: '13px',
        padding: '0 8px',
        color: 'rgba(0, 0, 0, 0.53)'
    },
    selectField: {
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    cardDanger: {
        borderColor: '#f44336'
    },
    customStyleForSelect: {
        color: '#f44336',
        fontSize: '0.75rem',
        marginTop: '3px',
        marginLeft: '14px',
        marginRight: '14px'
    },
    textMargin: {
        marginLeft: '10px'
    }
})

const DataTableWithSimpleForm = ({ editableFields, validationRules, onFormSubmit, entityNameSelection }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: editableFields,
        validationSchema: validationRules,
        onSubmit: (values, { setSubmitting }) => {
            onFormSubmit(values)
            setTimeout(function(){
                setSubmitting(false)
            }, 1000);
        }
    })
    return (
        <form className="padding-bottom-10" onSubmit={formik.handleSubmit}>
            <div className="grouping-content">
                <TextField
                    variant="outlined"
                    label={t('keys.sku')}
                    name="entityId"
                    autoComplete='off'
                    className={classes.inputField}
                    onChange={formik.handleChange}
                    value={formik.values['entityId']}
                    error={formik.touched['entityId'] && Boolean(formik.errors['entityId'])}
                    helperText={formik.touched['entityId'] && t(formik.errors['entityId'], {field: t('keys.sku')})}
                />
            
            </div>

            <div className={`grouping-content`}>
                <FormControl className={classes.inputField} error={formik.touched['entityName'] && Boolean(formik.errors['entityName'])}>
                    <fieldset className={`${classes.fieldSet} ${classes.inputField} ${ formik.touched['entityName'] && Boolean(formik.errors['entityName']) ? classes.cardDanger : ''}`}>
                    <legend className={classes.legend}>{`Select`}</legend>

                        <RadioGroup name="entityName" value={formik.values['entityName']}>
                            {entityNameSelection.map((value, index) => {
                                    return (
                                    
                                        <FormControlLabel
                                            value={value}
                                            control={<Radio />}
                                            key= {value}
                                            checked={ formik.values['entityName'] === value}
                                            label={t(`values.entityName.${value}`)}
                                            onChange={formik.handleChange}
                                        />
                                    )
                                })}
                        </RadioGroup>

                    </fieldset>
                    {
                        <FormHelperText>{t(formik.errors['entityName'], {field: t('keys.audits.entityName')})}</FormHelperText>
                    }
                </FormControl>

            </div>
        <div className="grouping-content">
            <Box display="flex" m={1}>
                    <Box mr={2}>
                        <Button
                                type="reset"
                                onClick={ e => formik.resetForm()}
                                variant="outlined"
                                startIcon={<RotateLeftIcon />}
                        >
                            {t('messages.reset')}
                        </Button>
                    </Box>
                    <Button 
                            variant="contained"
                            color="primary"
                            startIcon={<FilterListIcon />}
                            type="submit"
                    >
                        {t('messages.ok')}
                    </Button>
            </Box>
        </div>
        
        </form>

    )
}

export default DataTableWithSimpleForm;