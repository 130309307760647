import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";

const ArticleLikeForTable = (props) => {
  const { articleLikeForMapping } = props;
  delete articleLikeForMapping["sku"]; 

  const { t } = useTranslation();
  const customStyles = makeStyles({
    accesoryDivStyle: {
      display: `block`,
      position: `relative`,
      height: `300px`,
      overflow: `auto`,
    },
    backgroundPorcelain: {
      backgroundColor: `#eceff1`,
      fontWeight: `bold`,
    },
  });
  const customStyleClass = customStyles();

  return (
    <>
      {articleLikeForMapping &&
        Object.entries(articleLikeForMapping).map(
          ([groupMappingKey, groupMappingValue], index) => {
            if(typeof(groupMappingValue) === 'object') {
              return (
                <div className="col-md-6" key={index}>
                  <div className="form-group">
                    <strong>
                      {t(`keys.article.${groupMappingKey}.keyword`)}
                    </strong>
                    <br></br>
                    <br></br>
                    <div className={customStyleClass.accesoryDivStyle}>
                      <table className="table table-sm table-striped">
                        <thead>
                          <tr>
                            <th>{t(`keys.articleDescription`)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupMappingValue && Object.keys(groupMappingValue).length !== 0 &&
                            Object.entries(groupMappingValue).map(
                              ([countryCode, countryValue]) => {
                                return (
                                  <>
                                    <tr>
                                      <td
                                        colSpan="5"
                                        className={
                                          customStyleClass.backgroundPorcelain
                                        }
                                      >
                                        {countryCode}
                                      </td>
                                    </tr>
  
                                    {countryValue &&
                                      Object.entries(countryValue).map(
                                        ([carrierCode, carrierCodeValue]) => {
                                          return (
                                            <>
                                              <tr>
                                                <td
                                                  colSpan="5"
                                                  className={
                                                    customStyleClass.backgroundPorcelain
                                                  }
                                                >
                                                  {carrierCode}
                                                </td>
                                              </tr>
                                              {carrierCodeValue != null &&
                                                carrierCodeValue?.map((value) => (
                                                  <tr>
                                                    <td>
                                                      <label>{value}</label>
                                                    </td>
                                                  </tr>
                                                ))}
                                            </>
                                          );
                                        }
                                      )}
                                  </>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );

            }
            
          }
        )}
    </>
  );
};

export default ArticleLikeForTable;
