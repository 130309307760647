 import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import { Image, Laptop, NoteAdd } from '@material-ui/icons';
import { Headset } from '@material-ui/icons';
import { Smartphone } from '@material-ui/icons';
import { SystemUpdate } from '@material-ui/icons';
import { Backspace } from '@material-ui/icons';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MonetizationOn } from '@material-ui/icons';
import { EditLocation } from '@material-ui/icons';
import { Star } from '@material-ui/icons';
import { Update } from '@material-ui/icons';

import {
  AUTHORITY_ADMIN,
  AUTHORITY_SITE_ADMIN,
  AUTHORITY_SUPPLY_CHAIN_MANAGER,
  SUPPORTED_LANGUAGES
} from "../constant";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { validateAuthority } from "../utils/utils";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    background: "#170F4F",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    cursor: "pointer",
  },
  hide: {
    display: 'none',
  },
  show: {
    display: "block",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }
}));

const NavigationDrawer = ({ userAuthorities, onLogoutClick, onLanguageChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [displayMobileMenu, setDisplayMobileMenu] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onIconButtonClick = () => {
    setDisplayMobileMenu(!displayMobileMenu);
  }

  const handleClose = (languageCode) => {
    if (typeof languageCode === 'string') {
      onLanguageChange(languageCode)
    }
    setAnchorEl(null);
  };

  const MenuItem = ({component}) => {
    return (
      <ListItem component={NavLink} to={component.path}>
        <ListItemIcon style={{ color: "white" }}>{component.icon}</ListItemIcon>
        <ListItemText primary={t(component.translation)} style={{ color: "white" }} primaryTypographyProps={{ style: { whiteSpace: open && component.requireBreakWord ?  "normal" : "" } }} />
      </ListItem>
    )
  };

  const ExpandableMenuItem = ({component}) => {
    const { subMenu: children } = component;
    const [subOpen, setSubOpen] = React.useState(true);

    const handleClick = () => {
      setSubOpen((prev) => !prev);
      setOpen(true);
    };

    return (
      <React.Fragment>
        <ListItem button onClick={handleClick}>
          <ListItemIcon style={{ color: "white" }}>{component.icon}</ListItemIcon>
          <ListItemText primary={t(component.translation)} style={{ color: "white" }} primaryTypographyProps={{ style: { whiteSpace: open && component.requireBreakWord ?  "normal" : "" } }} />
          {subOpen ? <ExpandLessIcon style={{ color: "white" }}/> : <ExpandMoreIcon style={{ color: "white" }}/>}
        </ListItem>
        <Collapse in={open && subOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              (child.authority != null ? validateAuthority(child.authority, userAuthorities) : true) &&
              <ListItem key={key} component={NavLink} to={child.path} style={ {paddingLeft: 32} }>
                <ListItemIcon style={{ color: "white" }}>{child.icon}</ListItemIcon>
                <ListItemText primary={t(child.translation)} style={{color: "white" }} primaryTypographyProps={{ style: { whiteSpace: open && component.requireBreakWord ?  "normal" : "" } }} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    )
  };

  return (
    <div className={classes.toolbar}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <List style={{ background: "#170F4F", height: "100%" }}>
          <ListItem onClick={handleDrawerOpen} className={clsx(classes.menuButton, { [classes.hide]: open, [classes.show]: !open })}>
            <ListItemIcon style={{ color: "white" }}><MenuIcon /></ListItemIcon>
          </ListItem>
          <ListItem onClick={handleDrawerClose} className={clsx(classes.menuButton, { [classes.hide]: !open, [classes.show]: open })}>
            <ListItemIcon style={{ color: "white" }}>
              {/* <ChevronLeftIcon style={{margin: "auto", "font-size": "200%"}} /> */}
              <h1>Bolttech</h1>
            </ListItemIcon>
          </ListItem>
          {[
            {
              icon: <HomeIcon />,
              authority: null,
              path: "/dashboard",
              translation: "menuItems.home",
              requireBreakWord: false
            },
            {
              icon: <PeopleAltIcon />,
              authority: [AUTHORITY_SITE_ADMIN],
              path: "/users",
              translation: "menuItems.users",
              requireBreakWord: false
            },
            {
              icon: <DevicesOtherIcon />,
              authority:  [AUTHORITY_ADMIN, AUTHORITY_SUPPLY_CHAIN_MANAGER],
              path: "/articles/global",
              translation: "menuItems.articles",
              requireBreakWord: false
            },
            {
              icon: <Headset />,
              authority: [AUTHORITY_ADMIN],
              path: "/articles/accessoryMapping",
              translation: "menuItems.accessoryMapping",
              requireBreakWord: false
            },
            {
              icon: <MonetizationOn />,
              authority:  [AUTHORITY_ADMIN, AUTHORITY_SUPPLY_CHAIN_MANAGER],
              path: "/articles/severity",
              translation: "menuItems.severity"
            },
            {
              icon: <EditLocation />,
              authority: [AUTHORITY_ADMIN],
              path: "/articles/likeForMapping",
              translation: "menuItems.likeForMapping",
              requireBreakWord: false
            },
            {
              icon: <Star />,
              authority:  [AUTHORITY_ADMIN, AUTHORITY_SUPPLY_CHAIN_MANAGER],
              translation: "menuItems.likeForRecommendation",
              requireBreakWord: true,
              subMenu: [
                {
                  icon: <Update />,
                  authority: [AUTHORITY_ADMIN],
                  path: "/articles/likeForRecommendationUpdates",
                  translation: "menuItems.likeForRecommendationUpdates",
                  requireBreakWord: true
                },
                {
                  icon: <SystemUpdate />,
                  authority: [AUTHORITY_ADMIN],
                  path: "/articles/likeForUpdateRequest",
                  translation: "menuItems.likeForUpdateRequest",
                  requireBreakWord: false
                },
                {
                  icon: <Backspace />,
                  authority:  [AUTHORITY_ADMIN, AUTHORITY_SUPPLY_CHAIN_MANAGER],
                  path: "/articles/exclusion",
                  translation: "menuItems.likeForExclusion",
                  requireBreakWord: false
                }
              ]
            },
            {
              icon: <Image />,
              authority: [AUTHORITY_ADMIN, AUTHORITY_SUPPLY_CHAIN_MANAGER],
              path: "/brand/brandImageUpload",
              translation: "menuItems.brandImages",
              requireBreakWord: false
            },
            {
              icon: <NoteAdd />,
              authority:  [AUTHORITY_ADMIN],
              translation: "menuItems.audits",
              requireBreakWord: true,
              subMenu: [
                {
                  icon: <Update />,
                  authority: [AUTHORITY_ADMIN],
                  path: "/audits/articles",
                  translation: "menuItems.skuChanges",
                  requireBreakWord: true
                }
              ]
            },
          ].map((component, index) => (
            (component.authority != null ? validateAuthority(component.authority, userAuthorities) : true) &&
            (component.subMenu != null ? <ExpandableMenuItem key={index} component={component}/> : <MenuItem key={index} component={component}/>)
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default NavigationDrawer;