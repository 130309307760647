import React, { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ClearIcon from '@material-ui/icons/Clear';
import {showError} from "../../../actions/commonActions"
import { useDispatch } from 'react-redux';

const ArticleImagesUploadForm = (props) => {
    const dispatch = useDispatch();
    const ImageRef = useRef();
    const [imageList, setImageList] = useState([]);
    const [files, setFiles] = useState([]);
    function handleAddFiles() {
        ImageRef.current.click()
    }
    function handleDeleteAttachment(val) {
        const indexOfImage = imageList.findIndex(object => {
            return object.name === val;
        });
        const indexOfFiles = files.findIndex(object => {
            return object.name === val;
        });
        let imageData = imageList;
        let fileData = files;
        imageData.splice(indexOfImage, 1)
        fileData.splice(indexOfFiles,1)
        
        setImageList([...imageData])
        setFiles([...fileData])
    }

    function validateFileType(targetData) {
        var fileList = [];
        var file = targetData.files[0];

        Object.keys(targetData.files).map((fileKey, fileIndex) =>{
            let img = new Image();
            img.src = window.URL.createObjectURL(targetData.files[fileKey])
            

            img.onload = () => {

                if (!files.some(e => e.name === targetData.files[fileKey].name)) {
                    if (validateImageProperTies(targetData.files[fileKey].size, img, file.name)) {
                        fileList.push(targetData.files[fileKey])
                        setImageList([...imageList, ...fileList])
                        var indexOfObject = fileList.findIndex(object => {
                            return object === targetData.files[fileKey];
                        });
                        if(fileList[indexOfObject] != null && fileList[indexOfObject].type.match('image.*')){
                            var reader = new FileReader();
                            reader.readAsDataURL([...fileList][indexOfObject]);
                            reader.onload = () => {
                            setFiles(getBase64call(reader.result?.split(',')[1], [...fileList][indexOfObject]))
                            } 
                        }
                    }
                }
            }
        })
       
    }

    const validateImageProperTies = (fileSize,image, fileName) => {
        if(fileSize > 500000) {
            dispatch(showError({response: {data: {translationKey: `${fileName}: Image size should be less than 500KB`}}}));
            return false;
        }
        if(image.width != 400 || image.height != 560) {
            dispatch(showError({response: {data: {translationKey: `${fileName}: Images pixel is incorrect. It should be 400x560`}}}))
            return false;
        }
        return true;
    }

    const handleClose = () => {
        props.onCloseModel()

    }
    const handleImageUpload = () => {
        if(files.length) {
        props.onUploadImage({files}) 
        props.onCloseModel() 
    }
        else
        dispatch(showError({response: {data: {translationKey: "Please add atleast one image to upload"}}}))
}
    function getBase64call(data, val){
        files.push({
            "name":val.name,
            "type":"image/png",
            "data": data
        })
        return files;
    }
    return (
        <div>
            <div className="padding-10">
                <Grid container item md={12} spacing={5} >
                    <Grid item md={12}>
                        <Typography variant="h6" color="primary">{"Upload Images"}</Typography>
                        <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                        <div style={{ display: "flex", padding: "10px 0" }}><Typography variant="body1" className="grouping-header">{".png files are allowed and file name [sku]-front.png or [sku]-back.png."}</Typography>
                            <Typography style={{ whiteSpace: "nowrap" }} variant="h7" color="primary">{`${imageList.length} Selected`}</Typography></div>
                        <input
                            type="file"
                            accept="image/*.png"
                            id="select-image-front"
                            ref={ImageRef}
                            multiple
                            style={{ display: 'none' }}
                            onChange={e => validateFileType(e.target)
                            }
                        />
                        <Button variant="outlined" color="primary" onClick={handleAddFiles}>{"Add file(s)"} </Button>

                        <div
                        style={{
                            display: "block",
                            minHeight : "200px",
                            maxHeight: "200px",
                            overflowY : "auto",
                            padding: "10px",
                            maxWidth: "50%"
                        }}
                        >{imageList.map((val, index) =>
                            <div key={index}><Typography variant="h7" color="primary">{val.name} <span style={{
                                position: "relative",
                                top: "7px",
                                cursor: "pointer"
                            }}><ClearIcon onClick={e => handleDeleteAttachment(val.name)} /></span></Typography></div>
                        )}</div>
                    </Grid>

                </Grid>
                <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end"
                }}><Button onClick={handleClose} color="primary">
                        {"Cancel"}
                    </Button>
                    <Button onClick={handleImageUpload} size="small" variant="contained" color="secondary" style={{
                        background: "#E3D900",
                        padding: "16px"
                    }} autoFocus>
                        {"Upload"}
                    </Button>
                </div>
            </div>
        </div >
    )
}

export default ArticleImagesUploadForm;